import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Text, Button, Center } from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import queryString from 'query-string';
import Navbar from '../components/Navbar.js';
import FooterSmall from '../components/FooterSmall.js';
import AuthService from '../services/auth-service';
import { Flex } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';

import { Mixpanel } from '../utils/Mixpanel';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin } from '@react-oauth/google';

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [googleAuthLoading, setGoogleAuthLoading] = useState(false);

  Mixpanel.track('Viewed Sign Up Page');
  const navigate = useNavigate();
  const toast = useToast();
  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Required'),
    username: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(8, 'Too short!').required('Required'),
  });

  const doAfterSuccessfullGoogleAuth = async () => {
    const user = await AuthService.fetchCurrentUser();
    Mixpanel.identify(user.id);
    Mixpanel.track('Authenticated with Google');
    splitbee.user.set({
      id: user.id,
      email: user.email,
      name: user.firstname,
    });
    splitbee.track('Authenticated with Google');
    const { redirectTo } = queryString.parse(location.search);
    const defaultHomeUrl = user.is_mentor ? '/m/home' : '/home';
    navigate(redirectTo === null ? defaultHomeUrl : redirectTo);
    window.location.reload();
  };

  const handleGoogleLogin = async ({ code }) => {
    try {
      setGoogleAuthLoading(true);
      await AuthService.authWithGoogle(code);
      await doAfterSuccessfullGoogleAuth();
      setGoogleAuthLoading(false);
    } catch (error) {
      Mixpanel.track('Failed Google Login');
      toast({
        title: 'Something went wrong',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setGoogleAuthLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: error => {
      Mixpanel.track('Failed Google Login');
      toast({
        title: 'Something went wrong',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    flow: 'auth-code',
  });

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} />
        <Flex
          minHeight="calc(100vh - 60px)"
          w="auto"
          justifyContent="center"
          alignContent="center"
        >
          <Flex
            m="30px"
            mt="100px"
            rounded="5px"
            w={['100%', '80%', '60%', '40%']}
            alignItems="center"
            justifyContent="center"
          >
            <Flex w={['100%', '80%', '60%', '80%']} flexDirection="column">
              <div className="w-full container mx-auto">
                <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-white border-1">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-700 text-sm uppercase font-bold">
                        Create Account
                      </h6>
                    </div>
                  </div>
                  <div className=" flex-auto px-4 lg:px-10 mb-6 pt-0">
                    <div className="flex items-center justify-center mb-8 w-full">
                      <Button
                        w={'full'}
                        variant={'outline'}
                        leftIcon={<FcGoogle />}
                        onClick={() => googleLogin()}
                        spinner={<BeatLoader size={8} color="gray" />}
                        isLoading={googleAuthLoading}
                        loadingText="Authenticating"
                      >
                        <Center>
                          <Text>Continue with Google</Text>
                        </Center>
                      </Button>
                    </div>
                    <div className="text-gray-500 text-center mb-3 font-bold">
                      <small>Or sign in with credentials</small>
                    </div>
                    <Formik
                      initialValues={{
                        email: '',
                        password: '',
                        firstName: '',
                        lastName: '',
                        username: '',
                      }}
                      validationSchema={SignUpSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors }
                      ) => {
                        setSubmitting(true);
                        try {
                          const newUser = {
                            user: {
                              email: values.email,
                              password: values.password,
                              firstname: values.firstName,
                              lastname: values.lastName,
                              username: values.username,
                              career_goal: '',
                            },
                          };
                          await AuthService.register(newUser);
                          const currentUser =
                            await AuthService.fetchCurrentUser();
                          toast({
                            title: 'Welcome to Techstarta! 🎉',
                            description: "We've created your account for you.",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                          });
                          Mixpanel.identify(currentUser.id);
                          Mixpanel.track('Account Created', {
                            email: values.email,
                            firstName: values.firstName,
                          });
                          navigate('/verify_account');
                        } catch (error) {
                          Mixpanel.track('Failed registration');
                          if (error.status === 400 || 403) {
                            setErrors(error.data.errors);
                          }
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="relative w-full mb-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="firstName"
                            >
                              First Name
                            </label>
                            <input
                              id="firstName"
                              type="text"
                              name="firstName"
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                              placeholder="First name"
                              value={values.firstName}
                              style={{ transition: 'all .15s ease' }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <Text
                              borderRadius="md"
                              fontSize="sm"
                              mt="1"
                              textColor="red.500"
                            >
                              {errors.firstName && touched.firstName
                                ? errors.firstName
                                : null}
                            </Text>
                          </div>
                          <div className="relative w-full mb-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="lastName"
                            >
                              Last Name
                            </label>
                            <input
                              id="lastName"
                              type="text"
                              value={values.lastName}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                              placeholder="Last name"
                              style={{ transition: 'all .15s ease' }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <Text
                              borderRadius="md"
                              fontSize="sm"
                              mt="1"
                              textColor="red.500"
                            >
                              {errors.lastName && touched.lastName
                                ? errors.lastName
                                : null}
                            </Text>
                          </div>

                          <div className="relative w-full mb-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="email"
                            >
                              Your Email
                            </label>
                            <input
                              id="email"
                              type="email"
                              value={values.email}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                              placeholder="Enter your email"
                              style={{ transition: 'all .15s ease' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Text
                              borderRadius="md"
                              fontSize="sm"
                              mt="1"
                              textColor="red.500"
                            >
                              {errors.email && touched.email
                                ? errors.email
                                : null}
                            </Text>
                          </div>

                          <div className="relative w-full mb-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="email"
                            >
                              Username
                            </label>
                            <input
                              id="username"
                              type="text"
                              value={values.username}
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                              placeholder="Choose a unique username"
                              style={{ transition: 'all .15s ease' }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Text
                              borderRadius="md"
                              fontSize="sm"
                              mt="1"
                              textColor="red.500"
                            >
                              {errors.username && touched.username
                                ? errors.username
                                : null}
                            </Text>
                          </div>

                          <div className="mb-3">
                            <div className="relative w-full mb-3">
                              <label
                                id="password"
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                Password
                              </label>
                              <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                                placeholder="Choose your password (min 8 chars)"
                                style={{ transition: 'all .15s ease' }}
                                minLength={8}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span
                                className="cursor-pointer absolute"
                                style={{ bottom: '15px', right: '5px' }}
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </span>
                            </div>
                            <Text
                              borderRadius="md"
                              fontSize="sm"
                              mt="1"
                              textColor="red.500"
                            >
                              {errors.password && touched.password
                                ? errors.password
                                : null}
                            </Text>
                          </div>

                          <div className="text-center mt-6">
                            <Button
                              width={'100%'}
                              rounded={'full'}
                              type="submit"
                              bgColor={'brand.darkBlue'}
                              textColor={'white'}
                              _hover={{ shadow: 'lg' }}
                              fontSize={'sm'}
                              textTransform={'uppercase'}
                              isLoading={isSubmitting}
                              spinner={<BeatLoader size={8} color="white" />}
                              disabled={isSubmitting || !(isValid || dirty)}
                            >
                              Sign Up
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                    <div className="flex flex-wrap mt-6">
                      <div className="w-full text-sm">
                        <span> Already have an account? </span>
                        <a
                          href="/login"
                          className="text-red-600 hover:underline font-semibold"
                        >
                          Login
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Flex>
          </Flex>
        </Flex>
        <FooterSmall />
      </Flex>
    </>
  );
}
