import React from 'react';
import { Box, Text, FormControl, FormHelperText } from '@chakra-ui/react';

const TextInput = ({
  title,
  name,
  element,
  type,
  value,
  onChangeFunction,
  onBlurFunction,
  placeholder,
  minValue,
  isreadonly,
  addMarginTop,
  showError,
  errorMessage,
  maxlength,
  isRequired,
  helperText,
}) => {
  return (
    <Box w={'100%'} mt={addMarginTop ? ['20px', '20px', '20px', '0px'] : null}>
      <FormControl className="text-gray-700" isRequired={isRequired}>
        {title ? (
          <label htmlFor={name} className="block text-sm font-medium pb-1">
            {title}
            {isRequired ? (
              <span className="ml-1 text-gray-700">*</span>
            ) : (
              ' (optional)'
            )}
          </label>
        ) : null}
        {element === 'textArea' ? (
          <textarea
            name={name}
            onChange={onChangeFunction}
            placeholder={placeholder}
            maxLength="1000"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 h-40"
            value={value}
            readOnly={isreadonly}
            onBlur={onBlurFunction}
            required={isRequired}
          />
        ) : (
          <input
            name={name}
            border={'0'}
            onChange={onChangeFunction}
            type={type}
            min={minValue}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            value={value}
            readOnly={isreadonly}
            maxLength={maxlength}
            onBlur={onBlurFunction}
            required={isRequired}
          />
        )}
        {helperText ? (
          <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
            {helperText}
          </FormHelperText>
        ) : (
          ''
        )}
        {showError && (
          <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
            {errorMessage}
          </Text>
        )}
      </FormControl>
    </Box>
  );
};

export default TextInput;
