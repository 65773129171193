import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useToast,
  Button,
  Skeleton,
  Text,
  HStack,
  Flex,
  Link,
  Avatar,
  Image,
  Accordion,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  AvatarGroup,
  VStack,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import TrackService from '../services/track-service';
import AuthService from '../services/auth-service';
import ProfileService from '../services/profile-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import {
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  BookmarkSquareIcon,
  ClockIcon,
  PuzzlePieceIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';

import MarkdownToHtml from '../components/ReactMarkdownItem';
import Resources from '../components/Track/Resources';
import submissionsService from '../services/submissions-service';
import { MapNavigation } from '@vectopus/atlas-icons-react';
import authService from '../services/auth-service';
import profileService from '../services/profile-service';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar';
import { ChallengeCard } from '../components/ChallengeCard.js';
import { UserPlusIcon } from '@heroicons/react/24/solid';

const SkillDetail = () => {
  splitbee.track('Skill Detail Page');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { trackId: slug } = useParams();
  const [currentTrack, setCurrentTrack] = useState('');
  const [hasJoinedTrack, setHasJoinedTrack] = useState(null);
  const [title, setTitle] = useState(
    'Learn in-demand tech skills | Techstarta'
  );
  const [profile, setProfile] = useState('');
  const user = JSON.parse(AuthService.getCurrentUser());
  const username = user ? user.username : null;
  const profileSlug = profile && profile.tracks[0]?.slug;

  const [projects, setProjects] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [trackLeaderboard, setTrackLeaderboard] = useState([]);
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    async function getCurrentTrack() {
      try {
        const response = await TrackService.getTrack(slug);
        setCurrentTrack(response.track);
        setHasJoinedTrack(response.track.hasJoined);
        setTitle(`Master ${response.track.title} | Techstarta`);
        Mixpanel.track('Viewed track', { track: response.track.title });
      } catch (err) {
        Mixpanel.track('Failed View track');
        toast({
          title: 'Oops!',
          description: 'Unable to fetch track. Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getCurrentTrack();
  }, [slug, toast]);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await ProfileService.getProfile(username);
        setProfile(response.data.profile);
      }
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    async function fetchProjects() {
      try {
        setIsLoading(true);
        const response = await TrackService.fetchAllChallenges(slug);
        setProjects(response.challenges);
      } catch (err) {
        toast({
          title: 'Something went wrong!',
          description: 'Unable to show projects, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchProjects();
  }, [toast]);

  useEffect(() => {
    async function fetchSubmissions() {
      if (username) {
        const response = await submissionsService.listSubmissions(username);
        if (response.status === 200) {
          setSubmissions(
            response.submissions.filter(
              submission => submission.challenge.track_slug === slug
            )
          );
        }
      }
    }
    fetchSubmissions();
  }, [username]);

  useEffect(() => {
    async function fetchBadges() {
      if (username) {
        const response = await profileService.getProfileBadges(username);
        if (response.status === 200) {
          setBadges(response.badges);
        }
      }
    }
    fetchBadges();
  }, [username]);

  const joinTrack = async event => {
    event.preventDefault();
    try {
      const username = user.username;
      const response = await TrackService.joinTrack(slug, username);
      if (response === 200) {
        toast({
          title: 'Bravo!',
          description: "You're on a journey to success!",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(true);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}`);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast({
          title: 'Session expired!',
          description: 'You need login again to view this page',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      } else {
        toast({
          title: 'Login required',
          description: 'You need to login to save your progress',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      }
    }
  };

  const leaveTrack = async event => {
    event.preventDefault();
    setIsAlertDialogOpen(false);
    try {
      const user = JSON.parse(AuthService.getCurrentUser());
      const username = user.username;
      const response = await TrackService.deleteTrack(profileSlug, username);
      if (response.status === 200) {
        toast({
          title: 'Done.',
          description: 'You joined this track',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(false);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}`);
      }
    } catch (err) {
      if (err.status === 403) {
        toast({
          title: 'Login required',
          description: 'Please login and then try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong.',
          description:
            'Unable to remove you from this track, please try again later ',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleTabsChange = async index => {
    setTabIndex(index);
    if (index === 4 && trackLeaderboard.length === 0) {
      await handleFetchLeaderboard();
    }
  };

  const handleFetchLeaderboard = async () => {
    try {
      const response = await TrackService.getTrackLeaderboard(slug);
      setTrackLeaderboard(response.ranking.results);
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Unable to fetch leaderboard, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <LayoutWithSidebar title={title} pageTitle={'Roadmap'}>
        <div className="mx-auto w-full">
          <div className="flex flex-wrap px-2">
            <div className="relative w-full">
              <Button
                size={'sm'}
                className="mt-4 md:mt-0"
                rounded={'lg'}
                variant="outline"
                colorScheme={'gray'}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    style={{ marginRight: '4px' }}
                  />
                }
                marginBottom="12px"
                cursor={'pointer'}
                _active={{ outlineColor: 'gray.500' }}
                _focus={{ outlineColor: 'gray.500' }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div>
                <Tabs
                  isLazy
                  pt={4}
                  variant={'line'}
                  colorScheme="orange"
                  index={tabIndex}
                  onChange={handleTabsChange}
                >
                  <Skeleton isLoaded={currentTrack ? true : false}>
                    <Flex
                      flexDirection={['column', 'column', 'column', 'row']}
                      justifyContent="space-between"
                    >
                      <Flex flexDirection={'column'} width={'100%'}>
                        <Image
                          h={'16'}
                          w={'16'}
                          className="hidden md:block"
                          src={currentTrack.image}
                          alt={currentTrack.title}
                        />
                        <Flex>
                          <h1 className="capitalize text-2xl text-gray-800 font-bold mt-2 pb-2">
                            Master {currentTrack.title}
                          </h1>
                        </Flex>
                        <h2 className="text-md text-gray-500 font-medium">
                          {currentTrack.description}
                        </h2>

                        {currentTrack?.mentors?.length > 0 && (
                          <div className="pt-4 inline-flex items-center gap-4">
                            <AvatarGroup size={'sm'} max={5}>
                              {currentTrack.mentors
                                .slice(0, 5)
                                .map((mentor, index) => (
                                  <Avatar
                                    key={index}
                                    name={
                                      mentor.firstname + ' ' + mentor.lastname
                                    }
                                    src={authService.buildImageUrl(
                                      mentor.image
                                    )}
                                    textColor={'white'}
                                    bg="brand.darkBlue"
                                    borderColor="white"
                                    shadow="md"
                                    _hover={{ shadow: 'lg' }}
                                  />
                                ))}
                            </AvatarGroup>
                            <p className="text-gray-500 text-sm font-medium">
                              {currentTrack.mentors.length} Expert Mentors{' '}
                              <span className="px-2">|</span>{' '}
                              {currentTrack.learners_count} Learners
                            </p>
                          </div>
                        )}

                        {hasJoinedTrack ? (
                          <div className="flex w-full mt-5">
                            <p className="text-gray-500 text-md font-medium">
                              You are already a member of this track.
                            </p>
                          </div>
                        ) : (
                          <div className="flex w-full mt-5">
                            <Button
                              shadow="md"
                              _hover={{ shadow: 'lg' }}
                              _focus={{ outline: 'none' }}
                              bg="brand.darkBlue"
                              rounded={'full'}
                              textColor={'white'}
                              onClick={event => joinTrack(event)}
                              leftIcon={
                                <UserPlusIcon className="w-4 h-4 font-bold" />
                              }
                            >
                              Join {currentTrack.title}
                            </Button>
                          </div>
                        )}
                      </Flex>
                      <Flex
                        className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 rounded-lg p-6 text-gray-800"
                        width={['100%', '100%', '100%', '40%']}
                      >
                        {currentTrack && (
                          <VStack alignItems={'flex-start'}>
                            <Text
                              fontSize={'md'}
                              pb={4}
                              fontWeight={'semibold'}
                            >
                              What's included?
                            </Text>
                            <HStack>
                              <PuzzlePieceIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                                Real-world projects
                              </p>
                            </HStack>
                            <HStack>
                              <Square3Stack3DIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Handpicked resources
                              </p>
                            </HStack>
                            <HStack>
                              <ClockIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Learn at your own pace
                              </p>
                            </HStack>
                            <HStack>
                              <CheckBadgeIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Earn badges & recognition
                              </p>
                            </HStack>
                            <HStack>
                              <ChatBubbleLeftRightIcon className="w-5 h-5 mr-1 text-orange-600" />
                              <p className="text-md text-gray-700 flex items-center mt-2 md:mt-0">
                                Get mentored by experts
                              </p>
                            </HStack>
                          </VStack>
                        )}
                      </Flex>
                    </Flex>
                    <Flex overflow={'scroll'} className="w-full">
                      <TabList pt={4} width={'100%'}>
                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                        >
                          <HStack>
                            <PuzzlePieceIcon className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">Projects</Text>
                          </HStack>
                        </Tab>

                        <Tab
                          _focus={{ outline: 'none' }}
                          _selected={{
                            fontWeight: 'semibold',
                            borderBottomColor: 'orange.500',
                          }}
                        >
                          <HStack>
                            <MapNavigation className="h-4 w-4 text-orange-500" />
                            <Text className="text-[0.95rem]">
                              Skills Roadmap
                            </Text>
                          </HStack>
                        </Tab>
                      </TabList>
                    </Flex>
                  </Skeleton>

                  <TabPanels>
                    {/* Overview tab */}
                    <TabPanel px="-4">
                      <div className="flex flex-wrap">
                        {projects && (
                          <SimpleGrid columns={3} spacing={4}>
                            {projects.map((project, index) => (
                              <ChallengeCard
                                challengeDetails={project}
                                key={index}
                              />
                            ))}
                          </SimpleGrid>
                        )}

                        {/* {currentTrack.about && (
                          <div className="w-full md:w-8/12 md:pr-6 mt-6">
                            <Flex flexDirection={'column'}>
                              <Text
                                fontSize={'2xl'}
                                className="text-md text-gray-800 font-bold"
                                mb="16px"
                              >
                                About {currentTrack.title}
                              </Text>
                              <ExpandableText limit={10}>
                                {currentTrack.about}
                              </ExpandableText>
                            </Flex>
                          </div>
                        )} */}
                      </div>
                      {/* {currentTrack && currentTrack.mentors.length > 0 && (
                        <Flex
                          flexDirection={'column'}
                          rounded={'lg'}
                          width="100%"
                          mt={8}
                        >
                          <h2 className="text-2xl text-gray-700 font-bold">
                            Get hands-on mentorship on {currentTrack.title}
                          </h2>
                          <p className="text-lg text-gray-600">
                            Learn from vetted experts who have worked at top
                            tech companies.
                          </p>
                          {currentTrack?.mentors?.length > 0 && (
                            <MentorGrid
                              isLoading={isLoading}
                              columns={3}
                              mentorsList={currentTrack.mentors}
                            />
                          )}
                        </Flex>
                      )} */}
                    </TabPanel>

                    {/* Learning Guides Tab */}
                    <TabPanel px="-4">
                      <div className="rounded-lg border border-gray-100 mt-6">
                        <Box p={4}>
                          <HStack>
                            <BookmarkSquareIcon className="w-6 h-6 text-gray-600" />
                            <Text
                              textColor="gray.700"
                              fontWeight="semibold"
                              fontSize="xl"
                            >
                              Top Courses & Resources for {currentTrack.title}
                            </Text>
                          </HStack>
                          <Text fontSize={'md'} textColor={'gray.700'}>
                            Explore these curated collections of high-quality
                            learning resources curated by{' '}
                            <a
                              className="text-orange-600 hover:underline"
                              href="/mentors"
                            >
                              our expert mentors
                            </a>
                            .
                          </Text>
                        </Box>
                        <Skeleton
                          isLoaded={currentTrack ? true : false}
                          rounded={'lg'}
                        >
                          <Box border={1} borderColor="gray.100">
                            <Accordion
                              border={1}
                              borderColor="gray.100"
                              mt="10px"
                              _focus={{ outline: 'none' }}
                              rounded="lg"
                              defaultIndex={[0]}
                              allowToggle
                            >
                              {currentTrack &&
                                currentTrack.lessons.map((lesson, index) => (
                                  <AccordionItem key={index}>
                                    <h2>
                                      <AccordionButton
                                        height={'12'}
                                        w="100%"
                                        _expanded={{
                                          background: 'brand.darkBlue',
                                          textColor: 'white',
                                        }}
                                        textColor={'gray.700'}
                                        justifyItems={'space-between'}
                                        justifyContent={'space-between'}
                                        _focus={{ outline: 'none' }}
                                      >
                                        <Flex
                                          alignItems={'flex-start'}
                                          flexDirection={'column'}
                                          width="75%"
                                        >
                                          <h3 className="font-semibold">
                                            {lesson.title}
                                          </h3>
                                        </Flex>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                      <MarkdownToHtml
                                        input={lesson.explainer}
                                        classname="text-sm text-gray-700 pt-6"
                                      />
                                      {lesson.resources.length > 0 && (
                                        <Flex direction={'column'} py={8}>
                                          <HStack pb={4}>
                                            <Square3Stack3DIcon className="h-5 w-5 text-gray-600" />
                                            <Text
                                              textColor={'gray.600'}
                                              fontWeight="semibold"
                                            >
                                              Recommended resources
                                            </Text>
                                          </HStack>
                                          <Resources
                                            resources={lesson.resources}
                                          />
                                        </Flex>
                                      )}
                                    </AccordionPanel>
                                  </AccordionItem>
                                ))}
                            </Accordion>
                          </Box>
                        </Skeleton>
                      </div>
                      <Flex
                        mt={8}
                        p={4}
                        flexDirection={'column'}
                        className="rounded-lg border border-gray-100"
                      >
                        <HStack>
                          <Text
                            textColor="gray.700"
                            fontWeight="semibold"
                            fontSize="xl"
                          >
                            Feedback
                          </Text>
                        </HStack>
                        <Box>
                          <Text fontSize={'sm'} textColor={'gray.700'}>
                            To report an issue or suggest a resource that should
                            be added to this page or you want to request a new
                            learning path, please{' '}
                            <Link
                              href={'https://forms.gle/rsf39W8gE26z4jmT9'}
                              textColor="brand.darkBlue"
                              fontWeight="semibold"
                            >
                              fill this form
                            </Link>
                          </Text>
                        </Box>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(SkillDetail);
