import React from 'react';
import { Button, Flex, HStack, Text, useClipboard } from '@chakra-ui/react';

import { FaFacebook, FaLink, FaTwitter } from 'react-icons/fa';

export const SocialShareBox = ({
  contentTitle,
  boxTitle,
  contentType,
  showTitle = true,
}) => {
  const { hasCopied, onCopy } = useClipboard(window.location.href);

  const tweetThis = event => {
    event.preventDefault();
    const tweetedLink = window.location.href;
    window.open(
      'http://twitter.com/intent/tweet?url=' +
        tweetedLink +
        '&text=Check out this ' +
        contentType +
        ': ' +
        contentTitle +
        '. &via=techstarta&',
      'twitterwindow',
      'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
      '_blank'
    );
  };

  const shareThis = event => {
    event.preventDefault();
    const sharedLink = window.location.href;
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + sharedLink,
      '_blank'
    );
  };

  return (
    <>
      <Flex
        p="4"
        rounded="lg"
        width="100%"
        direction="column"
        shadow={'sm'}
        className="border border-gray-100"
      >
        {showTitle ? (
          <Text pb={4} fontSize="sm" color="gray.600" fontWeight="semibold">
            {boxTitle}
          </Text>
        ) : null}
        <HStack>
          <Button
            _focus={{ shadow: 'md', outline: 'none' }}
            onClick={shareThis}
            leftIcon={<FaFacebook />}
            rounded="md"
            size="xs"
            variant="outline"
            colorScheme="facebook"
          >
            Share
          </Button>
          <Button
            _focus={{ shadow: 'md', outline: 'none' }}
            onClick={tweetThis}
            leftIcon={<FaTwitter />}
            rounded="md"
            size="xs"
            variant="outline"
            colorScheme="twitter"
          >
            Tweet
          </Button>
          <Button
            _focus={{ shadow: 'md', outline: 'none', bg: 'gray.200' }}
            onClick={onCopy}
            leftIcon={<FaLink />}
            rounded="md"
            size="xs"
            variant="outline"
            colorScheme="black"
          >
            {hasCopied ? 'Copied' : 'Copy'}
          </Button>
        </HStack>
      </Flex>
    </>
  );
};
