import React from 'react';
import { Tag } from '@chakra-ui/react';

const DifficultyLabel = ({ difficulty }) => {
  switch (difficulty?.toLowerCase()) {
    case 'easy':
      return (
        <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
          Beginner
        </span>
      );
    case 'intermediate' || 'medium' || 'moderate':
      return (
        <span class="bg-yellow-100  text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
          Intermediate
        </span>
      );
    case 'hard':
      return (
        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
          Expert
        </span>
      );
    default:
      return (
        <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
          Beginner
        </span>
      );
  }
};

export default DifficultyLabel;
