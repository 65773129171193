import React from 'react';
import {
  Box,
  Flex,
  Text,
  Skeleton,
  LinkBox,
  LinkOverlay,
  Link,
  SkeletonText,
  Image,
  HStack,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import TrackLabel from './Track/TrackLabel';
import DifficultyLabel from './ChallengeDetail/DifficultyLabel';
import { FireIcon, UsersIcon } from '@heroicons/react/24/solid';

export const ChallengeCard = ({ challengeDetails }) => {
  const {
    slug,
    title,
    difficulty_level,
    track,
    description,
    image,
    point,
    participant_count,
  } = challengeDetails;

  return (
    <>
      <LinkBox className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300 rounded-lg">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          rounded="lg"
          cursor="pointer"
          overflow="hidden"
        >
          <Image
            height={'44'}
            width={'auto'}
            loading="lazy"
            objectFit={'cover'}
            roundedTop={'lg'}
            src={image}
            alt={title}
          />
          <Flex color="#2d3748" w={'100%'} flexDirection="column">
            <Flex p={['10px', '15px']} flexDirection="column">
              <HStack spacing={2} alignItems="flex-start">
                <TrackLabel track={track} />
                <DifficultyLabel difficulty={difficulty_level} />
                <p className="text-xs text-gray-600 self-center inline-flex">
                  <FireIcon className="w-5 h-5 pr-1" />{' '}
                  <span className="self-center"> +{point} XP</span>
                </p>
              </HStack>
              <Flex
                w={['100%', '100%', '100%', '100%']}
                pt="10px"
                justifyContent={['center', 'center', 'center', 'center']}
                flexDirection="column"
              >
                <LinkOverlay href={`/projects/${slug}`}>
                  <Text
                    fontWeight={'semibold'}
                    cursor="pointer"
                    textColor={'gray.700'}
                    noOfLines={2}
                  >
                    {title}
                  </Text>
                </LinkOverlay>
              </Flex>
              <Box py={2}>
                <Text
                  overflow="hidden"
                  maxHeight="40px"
                  minHeight="40px"
                  color="gray.700"
                  fontSize="sm"
                  className="line-clamp"
                >
                  {description}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            pt={4}
            pb={4}
            px={4}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Link href={`/projects/${slug}`}>
              <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                Go to project
                <ArrowRightIcon className="w-4 h-4 ml-2 inline-flex" />
              </button>
            </Link>
            {participant_count > 0 ? (
              <HStack spacing={2}>
                <UsersIcon className="w-4 h-4 text-gray-600" />
                <Text fontSize={'sm'} textColor={'gray.600'}>
                  {participant_count} started
                </Text>
              </HStack>
            ) : (
              <Text fontSize={'sm'} textColor={'gray.600'}>
                Try this challenge
              </Text>
            )}
          </Flex>
        </Flex>
      </LinkBox>
    </>
  );
};

export const ChallengeCardSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      boxShadow="sm"
      justifyContent="space-between"
      minHeight="100px"
      rounded="lg"
    >
      <SkeletonText noOfLines={6} spacing="4" />
      <Flex
        pt={4}
        pb={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Skeleton height="10px" w="100%" />
      </Flex>
    </Flex>
  );
};
