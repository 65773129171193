import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  SimpleGrid,
} from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import SubmissionService from '../services/submissions-service';
import {
  SubmissionItem,
  SubmissionItemSkeleton,
} from '../components/SubmissionItem.js';
import { useParams, useNavigate } from 'react-router-dom';
import { FeedbackFish } from '@feedback-fish/react';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import authService from '../services/auth-service.js';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';

const Submissions = () => {
  const { activeTab } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const user = JSON.parse(authService.getCurrentUser());

  useEffect(() => {
    if (activeTab === 'mentor') {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [activeTab]);

  const handleTabChange = index => {
    navigate(
      index === 0 ? '/showcase/submissions' : '/showcase/submissions/mentor'
    );
  };

  const { isPending: isOpenSubmissionsLoading, data: openSubmissions } =
    useQuery({
      queryKey: ['openSubmissions'],
      queryFn: () => SubmissionService.listSubmissions(null, 'open_for_review'),
    });

  const { isPending: isAllSubmissionsLoading, data: allSubmissions } = useQuery(
    {
      queryKey: ['allSubmissions'],
      queryFn: () => SubmissionService.listSubmissions(null, 'graded'),
    }
  );

  return (
    <>
      <LayoutWithSidebar
        title={'Project Showcase'}
        pageTitle={'Showcase'}
        url={'https://techstarta.com/showcase/submissions'}
      >
        <Flex
          className="rounded-lg overflow-hidden"
          w="auto"
          mt={['50px', '50px', '50px', '0px']}
          justifyContent={['center', 'center', 'center', 'space-between']}
          flexDirection={['column', 'column', 'column', 'row']}
          mb="10px"
          rounded="lg"
        >
          <Flex
            justifyContent="center"
            alignContent="center"
            color="gray.700"
            flexDirection="column"
          >
            <h1 className="text-3xl font-semibold">Showcase</h1>

            <h2 className="text-lg pt-2 font-medium text-gray-500">
              Browse projects from our community, share your expertise, and help
              others grow.
            </h2>
          </Flex>
        </Flex>
        <Box mt={8} display="flex" flexDirection="column">
          <Tabs
            rounded={'lg'}
            variant={'line'}
            defaultIndex={0}
            onChange={handleTabChange}
          >
            <TabList>
              <Tab
                name="all"
                className="font-semibold"
                _focus={{ outline: 'none', textColor: 'orange.500' }}
                _active={{ textColor: 'orange.500' }}
                _selected={{
                  textColor: 'orange.500',
                  bg: 'white',
                  borderBottomColor: 'white',
                }}
              >
                All submissions
              </Tab>
              <Tab
                className="font-semibold"
                _focus={{ outline: 'none', textColor: 'orange.500' }}
                _active={{ textColor: 'orange.500' }}
                _selected={{
                  textColor: 'orange.500',
                  bg: 'white',
                  borderBottomColor: 'white',
                }}
              >
                Mentor others
              </Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="orange.500"
              borderRadius="1px"
            />
            <TabPanels>
              <TabPanel mx="-4">
                <SimpleGrid spacing={6} columns={[1, 2, 2, 3]}>
                  {isOpenSubmissionsLoading ? (
                    <>
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                    </>
                  ) : (
                    openSubmissions?.submissions.map((submission, index) => {
                      return (
                        <SubmissionItem
                          key={index}
                          submission={submission}
                          index={index}
                        />
                      );
                    })
                  )}
                </SimpleGrid>
              </TabPanel>
              <TabPanel mx="-4">
                <SimpleGrid spacing={6} columns={[1, 2, 2, 3]}>
                  {isAllSubmissionsLoading ? (
                    <>
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                      <SubmissionItemSkeleton />
                    </>
                  ) : (
                    allSubmissions?.submissions.map((submission, index) => {
                      return (
                        <SubmissionItem
                          key={index}
                          submission={submission}
                          index={index}
                        />
                      );
                    })
                  )}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(Submissions);
