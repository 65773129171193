import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';

export const RequestCallModal = ({
  modalTitle,
  isOpen,
  onClose,
  onSendRequest,
}) => {
  const [message, setMessage] = useState('');
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'lg'}>
      <ModalOverlay />{' '}
      <ModalContent>
        <ModalHeader className="text-gray-700">{modalTitle}</ModalHeader>
        <ModalCloseButton _focus={{ outline: 'none' }} />
        <ModalBody>
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <textarea
                rows="3"
                name="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                className="w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-300 p-2 focus:ring-2 focus:ring-orange-500"
                maxLength={1300}
                placeholder="Add a note"
              ></textarea>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            disabled={!message}
            onClick={onSendRequest}
            className="inline-flex px-3 py-2 border border-transparent shadow-sm ring-1 ring-gray-100 focus:ring-offset-2  items-center text-sm leading-4 font-medium rounded-md hover:shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-orange-500"
          >
            Send Request
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
