import React from 'react';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

import BuildAndShip from '../images/pexels-cottonbro-studio-5083409.dc10b90ee4c3f796e53c.webp';
import SkillAreas from '../images/pexels-tima-miroshnichenko-5198250.c773fb1927e70274e608.webp';
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Feature from '../components/Feature.js';
import {
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  LightBulbIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import {
  Concierge,
  Plugin,
  SearchAccountEmployee,
} from '@vectopus/atlas-icons-react';

const Employers = () => {
  const MotionHeading = motion(Heading);

  return (
    <>
      <Navbar currentUser={null} showMenu={true} />
      <Container
        px={['2', '20', '20', '20']}
        pt={['10', '20', '20', '20']}
        pb={['10', '20', '20', '20']}
        maxW={'95%'}
      >
        <Flex
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 20 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex
            textAlign={['center', 'center', 'center', 'start']}
            flexDirection={'column'}
            flex={1}
          >
            <MotionHeading
              as="h1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 2 }}
              lineHeight={1.1}
              fontWeight={'bold'}
              fontSize={{ base: '6xl', sm: '6xl', lg: '70' }}
              pb={6}
            >
              <Text
                as={'span'}
                className="text-slate-800"
                position={'relative'}
              >
                Hire the top talents for your entry level positions.
              </Text>
            </MotionHeading>
            <Text pb={6} className="text-slate-800" as={'h3'} fontSize={'xl'}>
              Take advantage of our talent network, proven methods and
              technology tools to hire the most qualified candidates for
              entry-level positions —— internships, full-time, part-time, and
              more.
            </Text>
          </Flex>
          <Flex
            flex={1}
            justify={'center'}
            alignItems={'center'}
            position={'relative'}
          >
            <Box
              position={'relative'}
              rounded={'2xl'}
              width={'full'}
              overflow={'hidden'}
            >
              <Image
                alt={'Hero Image'}
                objectFit={'cover'}
                w={'100%'}
                h={'100%'}
                src={BuildAndShip}
              />
            </Box>
          </Flex>
        </Flex>

        <Box width="full" px={8} py="20" rounded={'xl'}>
          <Center pb={12} flexDirection="column">
            <Heading as="h2" className="text-slate-700">
              How hiring works on Techstarta
            </Heading>
            <Text fontSize={'md'} textAlign={'center'}>
              We help you find the best candidates for your entry-level
              positions.
            </Text>
          </Center>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} pb={12}>
            <Feature
              icon={
                <SearchAccountEmployee className="text-orange-500 h-8 w-8" />
              }
              title="Discover top early-career talents"
              text="We help you find the best candidates for your entry-level positions."
            />

            <Feature
              icon={<Plugin className="text-orange-500 h-8 w-8" />}
              title={'Micro-internships'}
              text="Engage with students and recent graduates on short-term projects to assess their skills and fit for your organization."
            />
            <Feature
              icon={<Concierge className="text-orange-500 h-8 w-8" />}
              title={'Journey with us'}
              text="Partner with us to build a diverse talent pipeline for your organization."
            />
          </SimpleGrid>
          <Center pt={4}>
            <a
              type="button"
              href="mailto:hello@techstarta.com"
              className="bg-gray-700 rounded-full hover:shadow-md text-gray-50 font-semibold hover:text-gray-50 py-2 px-4 border border-gray-500 "
            >
              Get in touch
            </a>
          </Center>
        </Box>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: '80px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="Skill areas"
                  className="max-w-full rounded-lg shadow-lg"
                  src={SkillAreas}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-orange-600 p-3  text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <RocketLaunchIcon className="w-6 h-6" />
                  </div>
                  <h3 className="text-3xl font-semibold text-gray-700">
                    Inspire. Engage. Hire.
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    We have early-talents across the following skill areas:
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-gray-200 mr-3">
                            <i className="fas fa-code"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Software Engineering
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-gray-200 mr-3">
                            <i className="fab fa-product-hunt"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Management</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-gray-200 mr-3">
                            <i className="fa fa-images"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Design</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-gray-200 mr-3">
                            <i className="fas fa-desktop"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Content Marketing</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-gray-200 mr-3">
                            <i className="fas fa-chart-simple"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Data Analysis</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-6">
                    <a
                      type="button"
                      href="mailto:hello@techstarta.com"
                      className="bg-transparent rounded-full hover:bg-gray-700 text-gray-700 font-semibold hover:text-gray-50 py-2 px-4 border border-gray-500 hover:border-transparent"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-gray-700">
                  How Techstarta works
                </h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  Hiring an intern can be a hit-and-miss task, but we have 3
                  pillars that ensures the acquisition of top-quality talents.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6 text-center">
                  <div className="text-stone-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <BriefcaseIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl text-gray-700 font-bold">
                    Project-based Learning
                  </h5>
                  <p className="text-gray-600">
                    Our talents are trained to work on real-world projects, so
                    they are ready to hit the ground running when they join your
                    team.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6 text-center">
                  <div className="text-cyan-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <ChatBubbleLeftRightIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold text-gray-700">
                    Mentorship for Job-readiness
                  </h5>
                  <p className="text-gray-600">
                    Skilled tech mentors provide expertise, feedback, and foster
                    growth for our talents, empowering them to be more
                    employable.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6 text-center">
                  <div className="text-orange-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <LightBulbIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold text-gray-700">
                    Transparent Vetting
                  </h5>
                  <p className="text-gray-600">
                    We provide tools to help you discover and hire the most
                    qualified early-career talents who will drive value and
                    success for your organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative block bg-slate-800 rounded-xl">
          <div className="container mx-auto px-4 lg:py-24 py-10">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-5xl font-semibold text-white">
                  Let's find your next hire.
                </h2>
                <p className="mt-2 mb-4 text-gray-50">
                  Secure the future of your workforce by hiring pre-skilled,
                  high-intent candidates who are prepared to do your work.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 mb-12 justify-center">
              <div className="mt-6 w-full lg:w-3/12 px-4 text-center">
                <a
                  type="button"
                  href="mailto:hello@techstarta.com"
                  className="bg-transparent rounded-full text-white font-semibold hover:text-gray-100 py-2 px-4 border border-gray-50 hover:bg-cyan-700 hover:border-transparent"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default Employers;
