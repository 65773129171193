import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Heading,
  Text,
  FormLabel,
  Textarea,
  Input,
  useToast,
  Stack,
  FormControl,
  FormHelperText,
  Box,
} from '@chakra-ui/react';
import {
  FaceSmileIcon,
  BriefcaseIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Form, Formik } from 'formik';
import { Select } from 'chakra-react-select';
import * as Yup from 'yup';
import SEO from '../components/Common/SEO.js';
import Navbar from '../components/Navbar';
import mentorService from '../services/mentor-service.js';
import { Mixpanel } from '../utils/Mixpanel';
import authService from '../services/auth-service.js';
import withRouter from '../utils/withRouter.js';
import { Brain, DollarBill, LeafPeach } from '@vectopus/atlas-icons-react';
import { TagsInput } from 'react-tag-input-component';
import { isSupported, setup } from '@loomhq/record-sdk';
import splitbee from '@splitbee/web';
import profileService from '../services/profile-service.js';

const MentorOnboarding = () => {
  splitbee.track('Mentor onboarding');
  const toast = useToast();
  const [data, setData] = useState({
    professionalPitch: '',
    funFact: '',
    tracks: [],
    currentRole: '',
    company: '',
    personalWebsite: '',
    linkedIn: '',
    twitter: '',
    calendarLink: '',
    github: '',
    location: '',
    skills: [],
    topics: [],
    motivation: '',
    greatestAchievement: '',
    introVideoLink: '',
  });
  const currentUser = JSON.parse(authService.getCurrentUser());
  const LOOM_PUBLIC_APP_ID = '7b1de6ea-3cf3-45ed-a8a8-f1fb62c334b4';
  const RECORD_LOOM_BUTTON_ID = 'record-loom-button';
  const { username } = currentUser;
  useEffect(() => {
    async function getProfile() {
      try {
        const {
          data: { profile },
        } = await profileService.getProfile(username);
        const { mentor } = profile;
        if (mentor) {
          setData({
            professionalPitch: mentor.pitch,
            funFact: mentor.fun_fact,
            currentRole: mentor.current_role,
            company: mentor.company,
            personalWebsite: mentor.website,
            linkedIn: mentor.linkedin,
            twitter: mentor.twitter,
            calendarLink: mentor.calendly_booking_link,
            github: mentor.github,
            location: mentor.location,
            skills: mentor.skills.map(item => item.name),
            topics: mentor.topics,
          });
        }
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch profile, please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getProfile();
  }, [username, toast]);

  const expertises = [
    {
      label: 'Android Development',
      value: 'android-development',
    },
    {
      label: 'Backend Development',
      value: 'backend-development',
    },
    {
      label: 'Frontend Development',
      value: 'frontend-development',
    },
    {
      label: 'Product Design',
      value: 'ui-ux-design',
    },
    {
      label: 'Product Management',
      value: 'product-management',
    },
    {
      label: 'Content Marketing',
      value: 'content-marketing',
    },
    {
      label: 'Data Analytics',
      value: 'data-analytics',
    },
    {
      label: 'Project Management',
      value: 'project-management',
    },
    {
      label: 'Cyber Security',
      value: 'cyber-security',
    },
  ];

  const handleNext = async (newData, isFinalStep = false) => {
    setData(prev => ({ ...prev, ...newData }));
    if (isFinalStep) {
      const payload = {
        pitch: newData.professionalPitch,
        current_role: newData.currentRole,
        company: newData.company,
        linkedin: newData.linkedIn,
        github: newData.github,
        calendly_booking_link: newData.calendarLink,
        location: newData.location,
        tracks: newData.tracks.map(item => item.value),
        skill_names: newData.skills,
        topics: newData.topics,
        motivation: newData.motivation,
        greatest_achievement: newData.greatestAchievement,
        intro_video_link: newData.introVideoLink,
      };
      try {
        await mentorService.submitMentorApplication(payload);
        Mixpanel.track('Submitted mentor application', {
          tracks: newData.tracks,
        });
        toast({
          title: 'Your application has been submitted for review.',
          description: 'We would get back to you shortly. Thank you.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        authService.fetchCurrentUser();
        nextStep();
      } catch (err) {
        toast({
          title: 'Oops!',
          description: err.data?.errors?.detail,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      nextStep();
    }
  };

  const handlePrev = newData => {
    setData(prev => ({ ...prev, ...newData }));
    prevStep();
  };

  const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const introValidationSchema = Yup.object({
    professionalPitch: Yup.string()
      .required('Please add your bio to introduce yourself.')
      .label('Professional pitch')
      .max(1000),
    location: Yup.string().required().label('Location'),
    currentRole: Yup.string()
      .required('Please provide your current role')
      .label('Current role'),
    company: Yup.string()
      .required('Please provide the where you currently work')
      .label('Company'),
  });

  const profileValidationSchema = Yup.object({
    tracks: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .min(1, 'Please select at least one area of expertise')
      .required('Please select at least one area of expertise'),

    topics: Yup.array()
      .of(Yup.string())
      .min(1, 'Please add at least one topic')
      .max(10, 'You can add up to 10 topics')
      .required("Topics can't be empty"),
    linkedIn: Yup.string().url().required('Please enter a valid LinkedIn URL'),
    calendarLink: Yup.string()
      .label('Calendar link')
      .required('Please add a calendar link'),
  });

  const motivationStepValidationStep = Yup.object({
    skills: Yup.array()
      .of(Yup.string())
      .min(1, 'Please ad at least one skill')
      .max(10, 'You can add up to 10 skills')
      .required(),

    motivation: Yup.string()
      .required('Please add your motivation')
      .label('Motivation'),
    greatestAchievement: Yup.string()
      .required('Please share your greatest achievement so far.')
      .label('Greatest achievement'),
    introVideoLink: Yup.string().label('Intro video link'),
  });

  const IntroStep = props => {
    const handleSubmit = values => {
      splitbee.track('Completed intro step');
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={introValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form className="w-full flex flex-col justify-center">
            {/* Work experience */}
            <Flex justify={'space-between'} mt={'30px'}>
              <FormControl
                fontSize={'sm'}
                className="xl:mr-4"
                w={['100%', '100%']}
              >
                <FormLabel fontSize={'sm'}>
                  What's your current role?
                  <span className="ml-1 text-gray-500">*</span>
                </FormLabel>
                <Input
                  fontSize={'sm'}
                  name="currentRole"
                  value={values.currentRole}
                  onChange={handleChange}
                  bgColor={'white'}
                  onBlur={handleBlur}
                  placeholder="e.g. Senior Product Manager"
                ></Input>
                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.currentRole && touched.currentRole
                    ? errors.currentRole
                    : null}
                </Text>
              </FormControl>
              <FormControl w={['100%', '100%']}>
                <FormLabel fontSize={'sm'}>
                  Where do you currently work?
                  <span className="ml-1 text-gray-500">*</span>
                </FormLabel>
                <Input
                  fontSize={'sm'}
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  bgColor={'white'}
                  onBlur={handleBlur}
                  placeholder="E.g. Google"
                ></Input>
                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.company && touched.company ? errors.company : null}
                </Text>
              </FormControl>
            </Flex>
            {/* Professional Pitch */}
            <Flex mt="30px" w={['100%', '100%']}>
              <FormControl>
                <FormLabel fontSize={'sm'}>
                  Your introduction as a mentor
                  <span className="ml-1 text-gray-500">*</span>
                </FormLabel>
                <Textarea
                  fontSize={'sm'}
                  name="professionalPitch"
                  value={values.professionalPitch}
                  onChange={handleChange}
                  bgColor={'white'}
                  onBlur={handleBlur}
                  placeholder="Tell us briefly about your professional experience..."
                ></Textarea>
                <Text
                  fontSize={'sm'}
                  borderRadius="md"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.professionalPitch && touched.professionalPitch
                    ? errors.professionalPitch
                    : null}
                </Text>
              </FormControl>
            </Flex>
            <FormControl mt="30px" w={['100%', '100%']}>
              <FormLabel fontSize={'sm'}>
                Where are you based?
                <span className="ml-1 text-gray-500">*</span>
              </FormLabel>
              <Input
                fontSize={'sm'}
                name="location"
                value={values.location}
                onChange={handleChange}
                bgColor={'white'}
                onBlur={handleBlur}
                placeholder="E.g. Berlin, Germany"
              ></Input>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.location && touched.location ? errors.location : null}
              </Text>
            </FormControl>

            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                type="button"
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button size="sm" type="submit">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const ProfileStep = props => {
    const handleSubmit = values => {
      splitbee.track('Completed profile step');
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={profileValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="w-full flex flex-col justify-center">
            {/* Expertise */}
            <FormControl mt="4" mb="4" w={['100%', '100%']}>
              <FormLabel htmlFor="tracks" fontSize={'sm'}>
                Your areas of expertise
                <span className="ml-1 text-gray-500">*</span>
              </FormLabel>
              <Select
                placeholder="Select one or more"
                name="tracks"
                value={values.tracks}
                focusBorderColor="orange.500"
                onBlur={handleBlur}
                isMulti
                tagVariant="solid"
                handleBlur={handleBlur}
                errorBorderColor="red.500"
                variant="outline"
                rounded="lg"
                colorScheme="orange"
                size="md"
                options={expertises}
                onChange={newValue => setFieldValue('tracks', newValue)}
              />
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.tracks ? errors.tracks : null}
              </Text>
            </FormControl>

            <Box w={['95%', '97%', '97%', 'auto']} mb={'4'}>
              <FormControl isRequired>
                <label
                  htmlFor={'topics'}
                  className="block text-sm font-medium pb-1"
                >
                  Topics
                  <span className="ml-1 text-gray-500">*</span>
                </label>
                <TagsInput
                  value={values.topics}
                  name="topics"
                  placeHolder="Add a topic"
                  classNames={{
                    tag: 'rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2',
                    input:
                      'block w-full px-3.5 py-2 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6',
                  }}
                  separators={[',', 'Enter']}
                  onBlur={handleBlur}
                  onChange={newValue => {
                    setFieldValue('topics', newValue);
                  }}
                />

                <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                  Add up to 10 topics that you like to talk about (e.g. Getting
                  started in tech, Growing your career, etc.)
                </FormHelperText>
                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.topics && touched.topics ? errors.topics : null}
                </Text>
              </FormControl>
            </Box>

            <Flex justify={'space-between'}>
              <FormControl w={['100%', '100%']} mt="20px" className="xl:mr-4">
                <FormLabel fontSize={'sm'}>
                  LinkedIn URL<span className="ml-1 text-gray-500">*</span>
                </FormLabel>
                <Input
                  name="linkedIn"
                  value={values.linkedIn}
                  bgColor={'white'}
                  fontSize={'sm'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="e.g. https://www.linkedin.com/in/yourname"
                />
                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.linkedIn && touched.linkedIn ? errors.linkedIn : null}
                </Text>
              </FormControl>
              <FormControl mt="20px" w={'100%'} className="xl:mr-4">
                <FormLabel fontSize={'sm'}>
                  Bookable calendar link
                  <span className="ml-1 text-gray-500">*</span>
                </FormLabel>
                <Input
                  name="calendarLink"
                  value={values.calendarLink}
                  bgColor={'white'}
                  onChange={handleChange}
                  fontSize={'sm'}
                  onBlur={handleBlur}
                  placeholder="e.g. https://calendly.com/yourname/techstarta-mentoring"
                />

                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.calendarLink && touched.calendarLink
                    ? errors.calendarLink
                    : null}
                </Text>
                <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                  Mentees will use to book a session with you. You can use
                  Calendly, Google Calendar or any other scheduling tool.
                </FormHelperText>
              </FormControl>
            </Flex>

            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button size="sm" type="submit">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const MotivationStep = props => {
    const handleSubmit = values => {
      splitbee.track('Completed skills and motivation step');
      props.next(values, true);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={motivationStepValidationStep}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          useEffect(() => {
            async function setupLoom() {
              const { supported, error } = await isSupported();

              if (!supported) {
                console.warn(`Error setting up Loom: ${error}`);
                return;
              }

              const button = document.getElementById(RECORD_LOOM_BUTTON_ID);

              if (!button) {
                return;
              }

              const { configureButton } = await setup({
                publicAppId: LOOM_PUBLIC_APP_ID,
                config: {
                  defaultRecordingType: 'cam',
                },
              });

              const sdkButton = configureButton({ element: button });

              sdkButton.on('insert-click', async video => {
                setFieldValue('introVideoLink', video.sharedUrl);
              });
            }

            setupLoom();
          }, []);
          return (
            <Form className="w-full flex flex-col justify-center">
              <Flex direction="column">
                <Box w={['95%', '97%', '97%', 'auto']} mb={'8'}>
                  <FormControl isRequired>
                    <label
                      htmlFor={'skills'}
                      className="block text-sm font-medium pb-1"
                    >
                      Skills
                      <span className="ml-1 text-gray-700">*</span>
                    </label>
                    <TagsInput
                      value={values.skills}
                      name="skills"
                      fontSize={'sm'}
                      placeHolder="Add a skill"
                      separators={[',', 'Enter']}
                      classNames={{
                        tag: 'rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2',
                        input:
                          'block w-full px-3.5 py-2 text-gray-900 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6',
                      }}
                      onBlur={handleBlur}
                      onChange={newValue => {
                        setFieldValue('skills', newValue);
                      }}
                    />

                    <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                      Add up to 10 skills that you are proficient in (e.g.
                      JavaScript, Frontend Development, Communication, etc.)
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Flex justify={'space-between'}>
                  <FormControl className="xl:mr-4" w={'100%'}>
                    <label
                      htmlFor={'introVideoLink'}
                      className="block text-sm font-medium pb-1"
                    >
                      Intro video link
                    </label>
                    <Input
                      name="introVideoLink"
                      value={values.introVideoLink}
                      onChange={handleChange}
                      bgColor={'white'}
                      fontSize={'sm'}
                      onBlur={handleBlur}
                      placeholder="e.g. https://www.youtube.com/watch?v=yourvideo"
                    ></Input>
                    <Text
                      borderRadius="md"
                      fontSize="sm"
                      mt="1"
                      textColor="red.500"
                    >
                      {errors.introVideoLink && touched.introVideoLink
                        ? errors.introVideoLink
                        : null}
                    </Text>
                    <FormHelperText fontSize={'sm'} textColor={'gray.600'}>
                      <a
                        id={RECORD_LOOM_BUTTON_ID}
                        className="text-orange-600 hover:underline cursor-pointer"
                      >
                        Record a loom
                      </a>{' '}
                      introducing yourself to your mentees.
                    </FormHelperText>
                  </FormControl>
                </Flex>

                {/* Motivation */}
                <Box w={['95%', '97%', '97%', 'auto']} mt={4}>
                  <FormControl isRequired>
                    <label
                      htmlFor={'motivation'}
                      className="block text-sm font-medium pb-1"
                    >
                      What motivates you to be a mentor? (Not publicly visible)
                      <span className="ml-1 text-gray-500">*</span>
                    </label>
                    <Textarea
                      name="motivation"
                      value={values.motivation}
                      fontSize={'sm'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      bgColor={'white'}
                      placeholder="Tell us briefly about your motivation to be a mentor..."
                    ></Textarea>
                    <Text
                      borderRadius="md"
                      fontSize="sm"
                      mt="1"
                      textColor="red.500"
                    >
                      {errors.motivation && touched.motivation
                        ? errors.motivation
                        : null}
                    </Text>
                  </FormControl>
                </Box>

                {/* Greatest achievment */}
                <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
                  <FormControl isRequired>
                    <label
                      htmlFor={'greatestAchievement'}
                      className="block text-sm font-medium pb-1"
                    >
                      What in your career are you most proud of? (Not publicly
                      visible)
                      <span className="ml-1 text-gray-500">*</span>
                    </label>
                    <Textarea
                      name="greatestAchievement"
                      value={values.greatestAchievement}
                      bgColor={'white'}
                      onChange={handleChange}
                      fontSize={'sm'}
                      onBlur={handleBlur}
                      placeholder="Tell us briefly about your greatest achievement yet..."
                    ></Textarea>
                    <Text
                      borderRadius="md"
                      fontSize="sm"
                      mt="1"
                      textColor="red.500"
                    >
                      {errors.greatestAchievement && touched.greatestAchievement
                        ? errors.greatestAchievement
                        : null}
                    </Text>
                  </FormControl>
                </Box>
              </Flex>

              <Flex width="100%" justify="flex-end" pt={4}>
                <Button
                  type="button"
                  isDisabled={activeStep === 0}
                  mr={4}
                  onClick={() => props.prev(values)}
                  size="sm"
                  variant="ghost"
                >
                  Prev
                </Button>
                <Button size="sm" type="submit">
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    {
      label: 'Intro',
      component: <IntroStep data={data} next={handleNext} />,
      icon: <FaceSmileIcon className="w-4 h-4" />,
    },
    {
      label: 'Profile',
      component: (
        <ProfileStep data={data} next={handleNext} prev={handlePrev} />
      ),
      icon: <BriefcaseIcon className="w-4 h-4" />,
    },
    {
      label: 'Skills & Motivation',
      component: (
        <MotivationStep data={data} next={handleNext} prev={handlePrev} />
      ),
      icon: <LightBulbIcon className="w-4 h-4" />,
    },
  ];

  return (
    <>
      <SEO
        title="Mentor Onboarding | Techstarta"
        url="https://techstarta.com/onboarding/mentor"
        description={'Welcome to Techstarta. Setup your account'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        backgroundColor="white"
      >
        <Navbar
          transparent={false}
          showMenu={false}
          currentUser={currentUser}
        />

        <div className="flex items-center h-screen justify-between setup w-full">
          <div className="flex w-full md:w-2/3 items-center justify-center setup-buttons onboarding-glass-bg">
            <Flex flexDirection={'column'} className="width-80">
              <Steps
                colorScheme={'gray'}
                activeStep={activeStep}
                pt={12}
                pb={4}
              >
                {steps.map(({ label, component, icon }, index) => (
                  <Step key={index} label={label} icon={() => icon}>
                    <Flex p={2}>{component}</Flex>
                  </Step>
                ))}
              </Steps>
              {activeStep === steps.length ? (
                <Flex px={4} py={4} width="100%" flexDirection="column">
                  <Heading fontSize="xl" textAlign="center">
                    Woohoo! You're all set!
                  </Heading>

                  <Box className="self-center">
                    <a
                      href="/m/home"
                      type="button"
                      className="inline-flex items-center px-3 py-2 mt-6 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      Continue to your Dashboard
                    </a>
                  </Box>
                </Flex>
              ) : null}
            </Flex>
          </div>
          <div className="hidden sm:block w-full md:w-1/3">
            <Flex
              height="100%"
              flexDirection="column"
              justifyContent={'center'}
            >
              <Flex px={12}>
                <Text className="text-left text-lg font-semibold pt-20 pb-4">
                  Some benefits of being a mentor
                </Text>
              </Flex>

              <Stack align={'center'} spacing={'4'} pb={'12'} px={12}>
                <Stack
                  bg={'white'}
                  key={1}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <LightBulbIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Solidify your knowledge</Text>
                  <Text color={'gray.700'} className="text-sm">
                    Sharing your knowledge with others is a great way to
                    solidify your own understanding and learn new things.
                  </Text>
                </Stack>
                <Stack
                  key={2}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <LeafPeach className="w-6 h-6" />

                  <Text fontWeight={700}>Help others grow</Text>
                  <Text color={'gray.700'} className="text-sm">
                    Your advice and guidance can help others achieve their
                    career goals. This can be very fulfilling.
                  </Text>
                </Stack>
                <Stack
                  key={3}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <Brain className="w-6 h-6" />

                  <Text fontWeight={600}>Grow your soft skills</Text>
                  <Text color={'gray.700'} className="text-sm">
                    Mentoring is a great way to develop your soft skills, such
                    as communication, leadership, and empathy.
                  </Text>
                </Stack>
                <Stack
                  key={4}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <DollarBill className="w-6 h-6" />

                  <Text fontWeight={600}>
                    Monetize your knowledge and experience
                  </Text>
                  <Text color={'gray.700'} className="text-sm">
                    You can earn extra income by sharing your knowledge and
                    experience with others.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default withRouter(MentorOnboarding);
