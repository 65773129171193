import React from 'react';
import DashboardFooter from '../DashboardFooter';
import DashboardNavbar from '../DashboardNavbar';
import Sidebar from '../Sidebar';
import SEO from '../Common/SEO';
import { FeedbackFish } from '@feedback-fish/react';
import authService from '../../services/auth-service';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/solid';

const LayoutWithSidebar = ({
  title,
  children,
  pageTitle,
  url,
  description = '',
}) => {
  const user = JSON.parse(authService.getCurrentUser());
  return (
    <>
      <SEO
        title={`${title} | Techstarta`}
        url={url}
        description={
          description
            ? description
            : `Get skilled in product management, product design, software engineering, AI, and Business.`
        }
      />
      <Sidebar />

      <div className="md:ml-64 bg-white">
        <DashboardNavbar
          title={pageTitle ? pageTitle : title}
          hideBecomeAMentorButton={true}
        />
        <FeedbackFish projectId="31db9b73671d16" userId={user?.email}>
          <button className="fixed bottom-4 right-4 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110">
            <ChatBubbleOvalLeftIcon className="w-6 h-6" />
          </button>
        </FeedbackFish>
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap">
            <div className="w-full  mb-12 xl:mb-0">
              <div className="flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-2 border-0">{children}</div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default LayoutWithSidebar;
