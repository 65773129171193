import React from 'react';
import {
  Flex,
  Text,
  Skeleton,
  HStack,
  Avatar,
  Box,
  LinkBox,
  LinkOverlay,
  SkeletonText,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  EyeIcon,
  FireIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/solid';

export const SubmissionItem = ({
  submission,
  index,
  showProjectName = true,
}) => {
  const formatDate = date => {
    const now = moment();
    const updatedMoment = moment(date);
    const daysDiff = now.diff(updatedMoment, 'days');

    if (daysDiff <= 7) {
      return updatedMoment.fromNow();
    } else if (daysDiff > 30) {
      return updatedMoment.format('MMMM D');
    } else {
      return updatedMoment.fromNow();
    }
  };
  return (
    <>
      <LinkBox
        rounded={'lg'}
        className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300"
        width={'100%'}
        p="4"
        flexDirection="column"
      >
        {showProjectName && (
          <Flex
            className="ring-1 ring-gray-300 rounded-lg p-2 mb-4 bg-gradient-to-r from-gray-50 to-orange-100 h-20"
            flexDirection={'column'}
            textColor={'gray.700'}
          >
            <HStack className="text-xs mb-2" alignContent={'center'}>
              <Text>Project</Text>
              <span>&bull;</span>
              <Text>{submission.challenge?.track}</Text>
            </HStack>
            <LinkOverlay href={`/submissions/${submission.id}`}>
              <h4 className="text-base font-semibold line-clamp-2">
                {submission.challenge?.title}
              </h4>
            </LinkOverlay>
          </Flex>
        )}

        <HStack className="w-full pb-4">
          <Avatar
            size="sm"
            src={submission.submitter.image}
            name={`${submission.submitter.fullname}`}
          />
          <div className="flex flex-col">
            <HStack>
              <a
                className="font-medium text-sm text-gray-700 hover:underline"
                href={`/profile/${submission.submitter.username}`}
              >
                {submission.submitter.fullname}
              </a>

              <div className="items-center text-xs inline-flex font-bold leading-sm px-2 py-0 rounded-full bg-white text-gray-700 border">
                <FireIcon className="h-3 w-3 mr-1 text-orange-400" />
                <Text fontSize="xs" className="text-gray-600 font-semibold">
                  {submission.submitter.total_points}
                </Text>
              </div>
            </HStack>
            <div className="text-gray-600 text-xs w-48 truncate">
              {submission.submitter.bio}
            </div>
          </div>
        </HStack>
        <LinkOverlay href={`/submissions/${submission.id}`}>
          <Box pb={4} height={'24'}>
            <Text textColor={'gray.700'} noOfLines={3} fontSize="sm">
              {submission.additional_information}
            </Text>
          </Box>
        </LinkOverlay>
        <Flex
          className="inset-x-0 bottom-0"
          pt={4}
          justifyContent="space-between"
          alignItems="center"
        >
          {
            <HStack spacing={4}>
              <div className="flex items-center gap-1">
                <HandThumbUpIcon className="h-4 w-4 text-gray-500" />
                <Text textColor={'gray.500'} fontSize="sm">
                  {submission.upvotes > 0 ? submission.upvotes : null}
                </Text>
              </div>

              <div className="flex items-center gap-1">
                <EyeIcon className="h-4 w-4 text-gray-500" />
                <Text textColor={'gray.500'} fontSize="sm">
                  {submission.views > 0 ? submission.views : null}
                </Text>
              </div>
              <div className="flex items-center gap-1">
                <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4 text-gray-500" />
                <Text textColor={'gray.500'} fontSize="sm">
                  {submission.comments.length > 0
                    ? submission.comments.length
                    : null}
                </Text>
              </div>
            </HStack>
          }
          <HStack className="w-4/12 justify-end">
            <Text textColor={'gray.500'} fontSize="xs">
              {formatDate(submission.created_at)}
            </Text>
          </HStack>
        </Flex>
      </LinkBox>
    </>
  );
};

export const SubmissionItemSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      boxShadow="sm"
      justifyContent="space-between"
      minHeight="100px"
      rounded="lg"
    >
      <SkeletonText noOfLines={6} spacing="4" />
      <Flex
        pt={4}
        pb={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Skeleton height="10px" w="100%" />
      </Flex>
    </Flex>
  );
};
