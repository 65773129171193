import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

export const ShareModal = ({
  modalTitle,
  title,
  body,
  link,
  isShareModalOpen,
  closeShareModal,
}) => {
  const { hasCopied, onCopy } = useClipboard(link);

  return (
    <Modal isOpen={isShareModalOpen} onClose={closeShareModal} isCentered>
      <ModalOverlay />{' '}
      <ModalContent>
        <ModalHeader className="text-gray-700">{modalTitle}</ModalHeader>
        <ModalCloseButton _focus={{ outline: 'none' }} />
        <ModalBody>
          <HStack spacing={2} pb="4">
            <LinkedinShareButton
              title={title}
              summary={body}
              url={link}
              source={link}
              className="focus:outline-none"
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton
              title={title + ' ' + body}
              url={link}
              via="techstarta"
              hashtags={['techstarta']}
              className="focus:outline-none"
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton
              className="focus:outline-none"
              title={title + ' ' + body}
              url={link}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <EmailShareButton
              className="focus:outline-none"
              subject={title}
              body={link}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </HStack>
          <Text textColor={'gray.600'} fontSize={'sm'} fontWeight="semibold">
            or copy link
          </Text>
          <Box py={4}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                bgColor="gray.50"
                fontSize={'sm'}
                className="text-gray-700"
                readOnly={true}
                value={link}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={onCopy}>
                  {hasCopied ? 'Copied' : 'Copy'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
