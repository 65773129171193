import React from 'react';
import {
  Box,
  Flex,
  Text,
  Skeleton,
  LinkBox,
  LinkOverlay,
  HStack,
  Tag,
  Avatar,
} from '@chakra-ui/react';
import AuthService from '../services/auth-service';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import emojiFlags from 'emoji-flags';
import SkillCloud from './Common/SkillCloud';

export const MentorItem = ({ mentor, showBio = true, showSkills = true }) => {
  return (
    <>
      <LinkBox
        rounded="lg"
        border={'1px'}
        shadow={'sm'}
        bgColor={'white'}
        borderColor={'gray.100'}
        _hover={{ shadow: 'lg' }}
        cursor="pointer"
      >
        <Flex p={4} flexDir={'column'}>
          <Flex pb={4} justify={'space-between'}>
            <Avatar
              size="lg"
              fontSize="45px"
              borderRadius="50%"
              color="white"
              name={`${mentor.firstname} ${mentor.lastname}`}
              src={AuthService.buildImageUrl(mentor.image)}
              shadow={'md'}
            />
            <Box>
              <div className="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 rounded-full bg-white text-gray-700 border">
                <HStack>
                  <CheckBadgeIcon className="w-4 h-4 text-orange-500" />
                  <Text>Verified mentor</Text>
                </HStack>
              </div>
            </Box>
          </Flex>
          <Box p={1}>
            <Box pb={4} d="flex" alignItems="baseline">
              <LinkOverlay href={`/mentor/${mentor.username}`}>
                <Text
                  color="brand.darkBlue"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="lg"
                  textTransform="capitalize"
                >
                  {mentor.firstname} {mentor.lastname}{' '}
                  {mentor?.country_code &&
                    emojiFlags.countryCode(mentor?.country_code)?.emoji}
                </Text>
              </LinkOverlay>
              <HStack>
                <Text
                  fontSize={'sm'}
                  textColor={'gray.600'}
                  fontWeight={'semibold'}
                >
                  {mentor.current_role} {mentor.company ? 'at' : null}{' '}
                  {mentor.company}
                </Text>
              </HStack>
            </Box>

            {showBio && (
              <Box>
                <Text
                  fontSize={'sm'}
                  textColor={'gray.700'}
                  mt="1"
                  fontWeight="normal"
                  alignItems={'center'}
                  justifyItems={'center'}
                  noOfLines={2}
                  as="h4"
                >
                  {mentor.pitch}
                </Text>
              </Box>
            )}
            {mentor?.skills && showSkills && (
              <Flex pt={2}>
                <SkillCloud
                  skills={mentor?.skills.map(skill => skill.name)}
                  max={3}
                  tagSize="xs"
                />
              </Flex>
            )}
          </Box>
        </Flex>
      </LinkBox>
    </>
  );
};

export const MentorItemSkeleton = () => {
  return (
    <>
      <Flex
        p="15px"
        shadow="sm"
        rounded={'lg'}
        border={'1px'}
        borderColor={'gray.100'}
        flexDirection="column"
        justifyContent="space-between"
        minHeight="280px"
      >
        <Box>
          <Flex
            w="100%"
            mb="20px"
            justify="space-between"
            textTransform="uppercase"
          >
            <Skeleton w="3rem" h="3rem" rounded="50%"></Skeleton>
            <Flex direction="column">
              <Flex>
                <Skeleton w="20px" h="20px" rounded="50%"></Skeleton>
                <Skeleton ml="-5px" w="20px" h="20px" rounded="50%"></Skeleton>
              </Flex>
              <Skeleton mt="10px" height="10px"></Skeleton>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="50px"></Skeleton>
          </Flex>
        </Box>

        <Skeleton w={['70%']} ml="auto" mt="10px" height="20px"></Skeleton>
      </Flex>
    </>
  );
};
