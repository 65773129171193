import React from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  MentorProfile,
  MentorProfileSkeleton,
} from '../components/MentorProfile';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import splitbee from '@splitbee/web';
import authService from '../services/auth-service.js';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { submitMentorApplication } from '../services/mentor-service.js';
import { sendMessage } from '../services/discussion-service.js';

const Mentor = () => {
  Mixpanel.track('Mentor Detail Page');
  const toast = useToast();
  const username = useParams().username;
  splitbee.track('Mentor Detail Page', { username: username });
  const { isPending, data } = useQuery({
    queryKey: ['mentorProfile', username],
    queryFn: () => authService.getProfile(username),
  });

  const mentorshipMutation = useMutation({
    mutationFn: mentorshipRequest => submitMentorApplication(mentorshipRequest),
  });

  const messageMutation = useMutation({
    mutationFn: messagePayload => sendMessage(messagePayload),
    onError: data => {
      toast({
        title: 'Message not sent',
        description: "An error occurented and we've been notified",
        status: 'error',
      });
    },
    onSuccess: data => {
      toast({
        title: 'Message sent!',
        description: '',
        status: 'success',
      });
    },
  });

  if (isPending) {
    return (
      <LayoutWithSidebar>
        <MentorProfileSkeleton />
      </LayoutWithSidebar>
    );
  }
  const mentor = data.mentor;
  const profile = data;

  return (
    <>
      <LayoutWithSidebar
        title={`Get mentored by ${
          mentor
            ? mentor.firstname + ' ' + mentor.lastname
            : 'an industry expert'
        } `}
        url={`https://www.techstarta.com/mentor/${username}`}
        pageTitle={mentor ? mentor.firstname + ' ' + mentor.lastname : ''}
      >
        <Flex rounded="lg" direction="column" width={'100%'}>
          <MentorProfile
            mentor={mentor}
            profile={profile}
            onSendQuickMessage={messageMutation}
          />
        </Flex>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(Mentor);
