import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Image,
  Text,
  useToast,
  Icon,
  Flex,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Heading,
  Skeleton,
  Link,
  Avatar,
  Step,
  AvatarGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stepper,
  StepIndicator,
  StepSeparator,
  useSteps,
  StepStatus,
  StepIcon,
  Stack,
  Divider,
  LinkBox,
  LinkOverlay,
  Textarea,
  Center,
  SkeletonText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { LinkIcon } from '@heroicons/react/20/solid';
import TrackService from '../services/track-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { SocialShareBox } from '../components/Common/SocialShareBox.js';
import validator from '../utils/validator';
import MarkdownToHtml from '../components/ReactMarkdownItem.js';
import challengeService from '../services/challenge-service.js';
import Resources from '../components/Track/Resources.js';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';
import authService from '../services/auth-service.js';
import {
  BookOpenIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  LightBulbIcon,
  ListBulletIcon,
  RocketLaunchIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { SubmissionForm } from '../components/SubmitSolutionItem.js';
import submissionsService from '../services/submissions-service.js';
import { FaCheckCircle, FaLinkedinIn } from 'react-icons/fa';
import TrackLabel from '../components/Track/TrackLabel.js';
import DifficultyLabel from '../components/ChallengeDetail/DifficultyLabel.js';
import { ProjectSubmissions } from '../components/Leaderboard/ProjectSubmissions.jsx';
import { CompassPointers } from '@vectopus/atlas-icons-react';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import CommentBox from '../components/Common/CommentBox';
import { ContentAuthorCard } from '../components/Common/ContentAuthorCard';
import { useQuery } from '@tanstack/react-query';
import discussionService from '../services/discussion-service';
import LoginModal from '../components/Common/LoginModal';
import fireworksIllustration from '../images/fireworks party.png';
import { ShareDropdownButton } from '../components/Common/ShareDropdownButton.js';
import { FireIcon } from '@heroicons/react/24/solid';

const Challenge = () => {
  const { challengeId: slug } = useParams();
  const currentUser = JSON.parse(authService.getCurrentUser());

  splitbee.track('Challenge Detail Page', { slug });
  Mixpanel.track('Challenge Detail Page', { slug });

  const navigate = useNavigate();
  const taskRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSubmissionFormOpen,
    onOpen: openSubmissionForm,
    onClose: closeSubmissionForm,
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const onAlertDialogClose = () => setIsAlertDialogOpen(false);
  const [loading, setLoading] = useState(false);

  const [challenge, setChallenge] = useState({});
  const [tasks, setTasks] = useState([]);
  const toast = useToast();

  const [hasJoined, setHasJoined] = useState(challenge.has_joined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [comment, setComment] = useState('');
  const [isPostingComment, setIsPostingComment] = useState(false);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: tasks.length,
  });

  const activeStepText = tasks[activeStep]?.title;

  const makeStartProjectLabel = () =>
    hasJoined ? 'Share your solution' : 'Start project';

  const handleStartChallenge = async () => {
    try {
      setIsSubmitting(true);
      const response = await TrackService.startChallenge(slug);
      Mixpanel.track('Started Challenged', {});
      splitbee.track('Started Challenged', {});

      if (response.status === 201) {
        toast({
          title: 'Success!',
          description: 'You have successfully started this challenge.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setChallenge(response.data.challenge);
        setHasJoined(response.data.challenge.has_joined);
        setIsAlertDialogOpen(true);
      }
    } catch (err) {
      handleError(err, 'Unable to start challenge');
    }
    setIsSubmitting(false);
  };

  const handleError = (err, defaultMessage) => {
    if (err.response && err.response.status === 403) {
      toast({
        title: 'Session expired!',
        description: 'You need login again to view this page',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
    } else {
      toast({
        title: 'Login required',
        description: 'You need to login to save your progress',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
    }
  };

  const {
    isLoading: isCommentsLoading,
    data,
    refetch: refetchComments,
  } = useQuery({
    queryKey: ['comments', challenge?.id],
    queryFn: () => discussionService.getComments('challenge', challenge.id),
    enabled: !!challenge?.id,
  });

  const handleCommentBoxClick = event => {
    event.preventDefault();
    if (!currentUser) {
      onOpen();
    }
  };

  const submitComment = async () => {
    const text = comment.trim();
    if (!validator.isValueValid(text)) {
      setIsCommentValid(false);
      return;
    }
    const payload = {
      text,
      object_id: challenge.id,
      content_type: 'challenge',
    };
    try {
      setIsPostingComment(true);
      const response = await discussionService.submitComment(payload);
      refetchComments();
      if (response === 201) {
        toast({
          title: 'Success!',
          description: 'Comment submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setComment('');
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to submit comment, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsPostingComment(false);
  };

  const upvoteComment = async commentId => {
    try {
      await discussionService.upvoteComment(commentId);
      refetchComments();
    } catch (err) {}
  };

  const submitSolution = async values => {
    try {
      const response = await submissionsService.makeSubmission(values);
      if (response.status === 201) {
        splitbee.track('Shared solution', { challenge_slug: slug });
        toast({
          title: 'Success!',
          description: 'Your solution has been successfully submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeSubmissionForm();
      }
    } catch (err) {
      splitbee.track('Share solution failed', { challenge_slug: slug });
      toast({
        title: 'Oops!',
        description: 'Unable to submit your solution, please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateSubmission = async values => {
    try {
      const response = await submissionsService.updateSubmission(
        values.id,
        values
      );
      if (response.status === 200) {
        toast({
          title: 'Success!',
          description: 'Your solution has been successfully updated.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        closeSubmissionForm();
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to update your solution, please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const getCurrentChallenge = async () => {
      try {
        const response = await TrackService.getChallenge(slug);
        setChallenge(response.challenge);
        setHasJoined(response.challenge.has_joined);
        setTasks(response.challenge.tasks);
        setActiveStep(
          response.challenge.tasks.findIndex(task => !task.is_completed)
        );
        document.title = `Techstarta | ${response.challenge.title} project`;
      } catch (err) {
        toast({
          title: 'An error occurred.',
          description: 'Unable to fetch this project, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    };
    getCurrentChallenge();
  }, [slug, toast]);

  useEffect(() => {
    const getProjectSubmissions = async () => {
      try {
        const response = await challengeService.getProjectLeaderboard(slug);
        if (response.status === 200) {
          setSubmissions(response.data.submissions);
        }
      } catch (err) {
        toast({
          title: 'An error occurred.',
          description: 'Unable to fetch leaderboard, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    getProjectSubmissions();
  }, [slug, toast]);

  const ChallengeStartConfirmationDialog = (
    <AlertDialog
      isOpen={isAlertDialogOpen}
      onClose={onAlertDialogClose}
      size={'lg'}
      color="gray.800"
    >
      <AlertDialogOverlay backdropFilter="blur(10px)" />
      <AlertDialogContent rounded={'lg'}>
        <AlertDialogHeader
          roundedTop={'lg'}
          className="bg-orange-600"
          fontSize="lg"
          textColor={'white'}
          fontWeight="bold"
        >
          Congratulations! 🚀 You've just started a new project.
        </AlertDialogHeader>
        <AlertDialogBody color="gray.700" fontSize="sm">
          <Center pb={4} flexDirection={'column'}>
            <Flex py={4}>
              <Image width={40} src={fireworksIllustration}></Image>
            </Flex>
            <Box pb="10px">
              <p className="pb-4 text-gray-600 text-lg text-center">
                Make this project yours. Plan boldly, build wisely, and bring
                your best.
              </p>
            </Box>
            <button
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              onClick={onAlertDialogClose}
            >
              Continue
            </button>
          </Center>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );

  const getUpdatedTasks = (previousTasks, updatedTask) => {
    const indexOfUpdatedTask = previousTasks.findIndex(
      task => task.id === updatedTask.id
    );
    const updatedTasks = [...previousTasks];
    updatedTasks[indexOfUpdatedTask] = updatedTask;
    return updatedTasks;
  };

  const handleMarkTaskAsComplete = async taskId => {
    try {
      const response = await challengeService.markTaskComplete(taskId);
      if (response.status === 200) {
        toast({
          title: 'Bravo! 👏',
          description: "You're on a journey to success!",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTasks(getUpdatedTasks(tasks, response.data.task));
        setActiveStep(activeStep + 1);
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to mark lesson complete, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUndoTaskCompleted = async taskId => {
    try {
      const response = await challengeService.undoTaskComplete(taskId);
      if (response.status === 200) {
        toast({
          title: 'Task undone!',
          description: "Keep going, you're doing great!",
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setTasks(getUpdatedTasks(tasks, response.data.task));
        setActiveStep(activeStep - 1);
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to undo lesson complete, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTabsChange = index => setTabIndex(index);

  const handleRequestMentorReview = async mentorId => {
    if (!challenge.submission) {
      toast({
        title: "You're almost there!",
        description: 'You need to start the project first.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await submissionsService.requestMentorFeedback({
        submissionId: challenge.submission.id,
        mentorId,
      });
      if (response.status === 200) {
        setChallenge({
          ...challenge,
          submission: {
            ...challenge.submission,
            assigned_mentor: response.data.submission.assigned_mentor,
          },
        });
        toast({
          title: 'Success!',
          description: 'Your request has been sent to the mentor',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to request mentor review, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <LayoutWithSidebar
        title={challenge.title}
        pageTitle={'Project'}
        url={`https://techstarta.com/projects/${slug}`}
      >
        <Modal
          isOpen={isSubmissionFormOpen}
          size={'xl'}
          onClose={closeSubmissionForm}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Share your solution</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SubmissionForm
                slug={challenge.slug}
                submission={challenge.submission}
                submitProjectFn={submitSolution}
                updateProjectFn={updateSubmission}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
        <div className="mx-auto w-full">
          <div className="relative w-full">
            <Link href="/projects">
              <Button
                marginBottom="12px"
                size={'sm'}
                cursor={'pointer'}
                variant="outline"
                fontSize={'sm'}
                w={'20'}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    className="text-gray-500"
                  />
                }
              >
                Back
              </Button>
            </Link>
          </div>

          <div className="relative w-full pb-4">
            <Skeleton rounded={'lg'} isLoaded={!isLoading}>
              <Flex
                rounded="xl"
                className="w-full"
                flexDirection={['column', 'column', 'column', 'row']}
                justifyContent={'space-between'}
              >
                <Flex
                  display={{ md: 'flex' }}
                  color="gray.800"
                  flexDirection={'column'}
                  className="md:pr-4 w-full md:w-8/12 "
                >
                  <Box mt={{ base: 4, md: 4 }} w={'100%'} pb={2}>
                    <Text>
                      <span className="text-orange-600 uppercase text-xs font-semibold">
                        Project
                      </span>
                    </Text>
                    <h1 className="capitalize text-gray-800 font-bold my-2 leading-tight text-3xl md:text-4xl">
                      {challenge.title}
                    </h1>
                    <h2 className="text-gray-700 text-lg font-[450] leading-tight">
                      {challenge.summary}
                    </h2>
                    <Flex
                      mt={3}
                      flexDirection={['column', 'row']}
                      alignItems={['flex-start', 'center']}
                      justifyContent={['flex-start', 'space-between']}
                    >
                      <Flex
                        flexDirection={'column'}
                        justifyItems={'flex-start'}
                      >
                        <HStack spacing={2}>
                          <TrackLabel track={challenge.track} />
                          <DifficultyLabel
                            difficulty={challenge.difficulty_level}
                          />
                          <p className="text-xs text-gray-600 self-center inline-flex">
                            <FireIcon className="w-5 h-5 pr-1" />{' '}
                            <span className="self-center">
                              {' '}
                              +{challenge.point} XP
                            </span>
                          </p>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Box>
                  <Divider my={2} />
                  <Flex justify={'space-between'} py={2}>
                    {!hasJoined && !challenge.submission && (
                      <button
                        onClick={hasJoined ? onOpen : handleStartChallenge}
                        className="inline-flex items-center px-3 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      >
                        {isSubmitting ? (
                          'Starting project...'
                        ) : (
                          <span>{makeStartProjectLabel()}</span>
                        )}
                      </button>
                    )}
                    {hasJoined && (
                      <button
                        onClick={openSubmissionForm}
                        className="inline-flex items-center px-3 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      >
                        Submit your work
                      </button>
                    )}
                    <Box alignSelf={'flex-end'}>
                      <ShareDropdownButton
                        contentTitle={challenge.title}
                        contentType={'challenge'}
                      />
                    </Box>
                  </Flex>
                  <Divider my={2} />
                </Flex>
                <Flex className="sm:pt-4 w-full md:w-4/12 md:pl-4">
                  {hasJoined ? (
                    <Box p={2}>
                      <>
                        <ul
                          aria-label="Colored activity feed"
                          role="feed"
                          className="relative flex flex-col gap-12 py-12 pl-6 before:absolute before:top-0 before:left-6 before:h-full before:-translate-x-1/2 before:border before:border-dashed before:border-slate-200 after:absolute after:top-6 after:left-6 after:bottom-6 after:-translate-x-1/2 after:border after:border-slate-200 "
                        >
                          <li role="article" className="relative pl-6 ">
                            <span
                              className={`absolute left-0 z-10 flex items-center justify-center w-8 h-8 text-white -translate-x-1/2 rounded-full  ring-2 ring-white ${
                                challenge?.submission
                                  ? 'bg-emerald-500'
                                  : 'bg-gray-400'
                              } `}
                            >
                              <RocketLaunchIcon className="w-4 h-4" />
                            </span>
                            <div className="flex flex-col flex-1 gap-0">
                              <h4 className="text-sm font-medium text-slate-700">
                                {' '}
                                Project started{' '}
                              </h4>
                              {challenge?.submission && (
                                <p className="text-xs text-slate-500">
                                  {moment(
                                    challenge?.submission?.created_at
                                  ).format('DD MMM YYYY')}
                                </p>
                              )}
                            </div>
                          </li>
                          <li role="article" className="relative pl-6 ">
                            <span
                              className={`absolute left-0 z-10 flex items-center justify-center w-8 h-8 text-white -translate-x-1/2 rounded-full  ring-2 ring-white ${
                                challenge?.submission?.status ===
                                'open_for_review'
                                  ? 'bg-emerald-500'
                                  : 'bg-gray-400'
                              } `}
                            >
                              <CheckCircleIcon className="w-4 h-4" />
                            </span>
                            <div className="flex flex-col flex-1 gap-0">
                              <h4 className="text-sm font-medium text-slate-700">
                                Project completed
                              </h4>
                            </div>
                          </li>
                          <li role="article" className="relative pl-6 ">
                            <span
                              className={`absolute left-0 z-10 flex items-center justify-center w-8 h-8 text-white -translate-x-1/2 rounded-full ring-2 ring-white ${
                                challenge?.submission?.grade
                                  ? 'bg-purple-500'
                                  : 'bg-gray-400'
                              } `}
                            >
                              <CheckBadgeIcon className="w-4 h-4" />
                            </span>
                            <div className="flex flex-col flex-1 gap-0">
                              <h4 className="text-sm font-medium text-slate-700">
                                Badge awarded
                              </h4>
                              <Box pt={2}>
                                <Link href="#">
                                  <Button
                                    isDisabled={true}
                                    colorScheme="linkedin"
                                    variant="solid"
                                    rounded={'lg'}
                                    _focus={{ outline: 'none' }}
                                    leftIcon={<FaLinkedinIn />}
                                    size={'sm'}
                                  >
                                    Add to LinkedIn
                                  </Button>
                                </Link>
                              </Box>
                            </div>
                          </li>
                        </ul>
                      </>
                    </Box>
                  ) : (
                    <Image
                      className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 rounded-lg text-gray-800"
                      objectFit={'cover'}
                      src={challenge.image}
                      loading="lazy"
                      alt={challenge.title}
                    />
                  )}
                </Flex>
              </Flex>
            </Skeleton>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full xl:w-8/12 xl:mb-12 mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
                <div className="rounded-t mb-0 border-0">
                  <Tabs
                    className="rounded-lg shadow-md shadow-black/5 ring-1 ring-slate-700/10"
                    pt={2}
                    variant={'line'}
                    isLazy={true}
                    lazyBehavior="unmount"
                    index={tabIndex}
                    onChange={handleTabsChange}
                    colorScheme="orange"
                  >
                    <Skeleton isLoaded={!isLoading}>
                      <Flex overflow={'scroll'} className="w-full">
                        <TabList className="sm:text-sm" width={'100%'}>
                          <Tab
                            _focus={{ outline: 'none' }}
                            _selected={{
                              fontWeight: 'semibold',
                              borderBottomColor: 'orange.500',
                            }}
                          >
                            <HStack>
                              <BookOpenIcon className="w-4 h-4 text-orange-500" />
                              <Text className="text-[0.95rem]">Overview</Text>
                            </HStack>
                          </Tab>
                          <Tab
                            _focus={{ outline: 'none' }}
                            _selected={{
                              fontWeight: 'semibold',
                              borderBottomColor: 'orange.500',
                            }}
                          >
                            <HStack>
                              <CompassPointers className="w-4 h-4 text-orange-500" />
                              <Text className="text-[0.95rem]">Guides</Text>
                            </HStack>
                          </Tab>
                          <Tab
                            _focus={{ outline: 'none' }}
                            _selected={{
                              fontWeight: 'semibold',
                              borderBottomColor: 'orange.500',
                            }}
                          >
                            <HStack>
                              <ChatBubbleLeftRightIcon className="w-4 h-4 text-orange-500" />
                              <Text className="text-[0.95rem]">
                                Discussions
                              </Text>
                            </HStack>
                          </Tab>
                          <Tab
                            _focus={{ outline: 'none' }}
                            _selected={{
                              fontWeight: 'semibold',
                              borderBottomColor: 'orange.500',
                            }}
                          >
                            <HStack>
                              <ListBulletIcon className="w-4 h-4 text-orange-500" />
                              <Text className="text-[0.95rem]">
                                Submissions
                              </Text>
                            </HStack>
                          </Tab>
                        </TabList>
                      </Flex>
                    </Skeleton>

                    {isLoading ? (
                      <>
                        <SkeletonText
                          p={4}
                          spacing={'4'}
                          noOfLines={4}
                          skeletonHeight="2"
                        />
                        <SkeletonText
                          p={4}
                          spacing={'4'}
                          noOfLines={7}
                          skeletonHeight="2"
                        />
                      </>
                    ) : (
                      <TabPanels>
                        <TabPanel px="-4">
                          {/* Task progress bar */}
                          {tasks.length > 0 && hasJoined && (
                            <Skeleton isLoaded={!isLoading}>
                              <Stack px={4} py={4}>
                                <Stepper
                                  colorScheme="green"
                                  size="sm"
                                  index={activeStep}
                                  gap="0"
                                >
                                  {tasks.map((step, index) => (
                                    <Step key={index} gap="0">
                                      <StepIndicator>
                                        <StepStatus complete={<StepIcon />} />
                                      </StepIndicator>
                                      <StepSeparator
                                        _horizontal={{ ml: '0' }}
                                      />
                                    </Step>
                                  ))}
                                </Stepper>
                                <Text textColor={'gray.700'} fontSize={'sm'}>
                                  Next task: <b>{activeStepText}</b>
                                </Text>
                              </Stack>
                            </Skeleton>
                          )}
                          <Skeleton isLoaded={!isLoading} rounded="lg">
                            {challenge?.what_you_will_learn && (
                              <Flex rounded={'lg'} p={4} flexDirection="column">
                                <Heading color="gray.700" fontSize="lg" mb={4}>
                                  Skills you will gain
                                </Heading>
                                <Flex w={'100%'} textColor={'gray.700'}>
                                  <MarkdownToHtml
                                    input={challenge?.what_you_will_learn}
                                  />
                                </Flex>
                              </Flex>
                            )}
                          </Skeleton>
                          {/* Brief */}
                          <Flex p={4} rounded="lg" flexDirection="column">
                            <Heading color="gray.700" fontSize="lg" mb={4}>
                              Brief
                            </Heading>
                            <article className="prose prose-lg">
                              <MarkdownToHtml input={challenge?.description} />
                            </article>
                          </Flex>
                          {/* Tasks */}

                          {challenge?.submission_requirements && (
                            <Flex p={4} rounded="lg" flexDirection="column">
                              <Skeleton isLoaded={!isLoading}>
                                <Heading color="gray.700" fontSize="lg" mb={4}>
                                  Requirements
                                </Heading>
                              </Skeleton>
                              <Skeleton isLoaded={!isLoading}>
                                <article className="prose prose-lg">
                                  <MarkdownToHtml
                                    input={challenge?.submission_requirements}
                                  />
                                </article>
                              </Skeleton>
                            </Flex>
                          )}
                        </TabPanel>
                        <TabPanel px={'-4'}>
                          {tasks.length > 0 && (
                            <Box ref={taskRef} p={4} rounded="lg" shadow="sm">
                              <Skeleton isLoaded={!isLoading}>
                                <Heading color="gray.700" mb="4" fontSize="lg">
                                  Tasks
                                </Heading>
                              </Skeleton>
                              <Skeleton isLoaded={!isLoading}>
                                <Box border={1} borderColor="gray.100">
                                  <Accordion
                                    m={-4}
                                    border={1}
                                    borderColor="gray.100"
                                    mt="10px"
                                    _focus={{ outline: 'none' }}
                                    rounded="md"
                                    defaultIndex={[0]}
                                  >
                                    {tasks?.map((task, index) => (
                                      <AccordionItem key={index}>
                                        <AccordionButton
                                          w="100%"
                                          _expanded={{
                                            bg: 'brand.darkBlue',
                                            textColor: 'white',
                                          }}
                                          _focus={{ outline: 'none' }}
                                        >
                                          <Flex
                                            w={'100%'}
                                            flexDirection={'row'}
                                            textAlign="left"
                                            fontWeight="bold"
                                            fontSize="14px"
                                            alignItems={'center'}
                                          >
                                            <Icon
                                              as={FaCheckCircle}
                                              color={
                                                task?.is_completed
                                                  ? 'green.500'
                                                  : 'gray.200'
                                              }
                                              mr="8px"
                                            />
                                            <Text fontSize={'md'}>
                                              Task {task?.index}: {task?.title}
                                            </Text>
                                          </Flex>
                                          <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel
                                          _focus={{ outline: 'none' }}
                                          pb={4}
                                          flexDirection="column"
                                        >
                                          <Box
                                            className="py-4"
                                            textColor="gray.600"
                                          >
                                            <MarkdownToHtml
                                              classname="markdown"
                                              input={task?.description}
                                            />
                                          </Box>
                                          {task?.resources.length > 0 && (
                                            <>
                                              <Text
                                                fontSize={'xs'}
                                                py="4"
                                                textColor="gray.600"
                                                fontWeight="semibold"
                                                textTransform={'uppercase'}
                                              >
                                                Relevant Resources
                                              </Text>
                                              <Resources
                                                resources={task?.resources}
                                              />
                                            </>
                                          )}

                                          <Box
                                            direction={'column'}
                                            className="py-4 flex justify-end"
                                          >
                                            {task?.is_completed ? (
                                              <Button
                                                rounded="full"
                                                size="sm"
                                                isLoading={false}
                                                loadingText="Working..."
                                                fontSize="sm"
                                                onClick={event => {
                                                  event.preventDefault();
                                                  handleUndoTaskCompleted(
                                                    task.id
                                                  );
                                                }}
                                                leftIcon={<FaCheckCircle />}
                                                colorScheme="green"
                                              >
                                                Completed
                                              </Button>
                                            ) : (
                                              <Button
                                                bg="white"
                                                rounded="full"
                                                _hover={{ shadow: 'md' }}
                                                size="sm"
                                                isLoading={false}
                                                fontSize="sm"
                                                loadingText="Working..."
                                                onClick={event => {
                                                  event.preventDefault();
                                                  handleMarkTaskAsComplete(
                                                    task.id
                                                  );
                                                }}
                                                leftIcon={<FaCheckCircle />}
                                                variant="outline"
                                              >
                                                Mark as completed
                                              </Button>
                                            )}
                                          </Box>
                                        </AccordionPanel>
                                      </AccordionItem>
                                    ))}
                                  </Accordion>
                                </Box>
                              </Skeleton>
                            </Box>
                          )}
                          {/* Recommended steps & resources */}
                          {challenge?.instructions && (
                            <Box
                              className="items-center m-4 text-blue-800 border-l-4 border-t border-b border-r border-l-orange-400"
                              rounded="lg"
                            >
                              <div className="bg-gray-50 p-2">
                                <Heading color="gray.700" fontSize="md">
                                  <LightBulbIcon className="inline text-orange-500 w-4 h-4 mr-2" />
                                  Tips & Resources
                                </Heading>
                              </div>
                              <Skeleton
                                className="p-4"
                                isLoaded={!isLoading}
                                p={2}
                                rounded={'lg'}
                              >
                                <MarkdownToHtml
                                  classname="markdown"
                                  input={challenge.instructions}
                                />
                              </Skeleton>
                            </Box>
                          )}
                        </TabPanel>
                        <TabPanel px={'-4'}>
                          <Flex flexDirection="column" width={'full'} p={4}>
                            <Heading color="gray.700" mb={2} fontSize="lg">
                              Discussions
                            </Heading>
                            <Text color="gray.600" mb={4} fontSize="sm">
                              Ask questions, share thoughts and discuss
                              approaches with other learners.
                            </Text>

                            <Box>
                              <Flex flexDirection={['column', 'column']} border>
                                <Flex marginTop="20px">
                                  <Box pr="5px" className="hidden md:block">
                                    <Avatar
                                      src={
                                        currentUser
                                          ? authService.buildImageUrl(
                                              currentUser.image
                                            )
                                          : ''
                                      }
                                      name={
                                        currentUser
                                          ? currentUser.firstname +
                                            ' ' +
                                            currentUser.lastname
                                          : ''
                                      }
                                      size="sm"
                                    />
                                  </Box>
                                  <Box w="100%">
                                    <Textarea
                                      fontSize="sm"
                                      placeholder="Add a comment"
                                      value={comment}
                                      onChange={event => {
                                        event.preventDefault();
                                        setIsCommentValid(
                                          event.target.value.trim() !== ''
                                        );
                                        setComment(event.target.value);
                                      }}
                                      onClick={event =>
                                        handleCommentBoxClick(event)
                                      }
                                      focusBorderColor="orange.400"
                                    />
                                    <Text
                                      style={{
                                        display: isCommentValid
                                          ? 'none'
                                          : 'block',
                                      }}
                                      fontSize="sm"
                                      color="red.500"
                                    >
                                      Comment cannot be empty
                                    </Text>
                                  </Box>
                                </Flex>
                                <Flex
                                  my="10px"
                                  justifyContent={['flex-start', 'flex-end']}
                                >
                                  <button
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-md leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={submitComment}
                                    disabled={isPostingComment}
                                  >
                                    {isPostingComment ? 'Posting...' : 'Post'}
                                  </button>
                                </Flex>
                              </Flex>
                              <LoginModal
                                isOpen={isOpen}
                                onClose={onClose}
                                redirectTo={location.pathname}
                              />
                              <Flex direction="column">
                                <Text
                                  fontWeight="semibold"
                                  fontSize="xl"
                                  letterSpacing="normal"
                                  color="brand.darkBlue"
                                >
                                  Comments
                                </Text>
                              </Flex>
                              {!isCommentsLoading &&
                              data?.results.length > 0 ? (
                                data?.results.map(comment => (
                                  <CommentBox
                                    key={comment.id}
                                    comment={comment}
                                    upvoteFn={upvoteComment}
                                  />
                                ))
                              ) : (
                                <Text fontSize="sm" color="gray.500">
                                  Comments will appear here
                                </Text>
                              )}
                            </Box>
                          </Flex>
                        </TabPanel>
                        <TabPanel px={'-4'}>
                          <Flex flexDirection="column" width={'full'} p={4}>
                            <Heading color="gray.700" mb={2} fontSize="lg">
                              Reference submissions
                            </Heading>
                            <Text color="gray.600" mb={4} fontSize="sm">
                              Reviewing other people's work is a great way to
                              learn. You can ask questions, provide feedback,
                              and learn from others.
                            </Text>
                            <ProjectSubmissions
                              projectSubmissions={submissions}
                            />
                          </Flex>
                        </TabPanel>
                      </TabPanels>
                    )}
                  </Tabs>
                  {/* Submit your project */}
                  <Skeleton isLoaded={!isLoading} pt={8}>
                    <Box
                      border={'1px solid'}
                      shadow={'sm'}
                      borderColor={'gray.200'}
                      rounded={'lg'}
                      className="shadow-xl shadow-black/5 ring-1 ring-slate-700/10 bg-gradient-to-r from-orange-500 to-orange-600"
                      p="6"
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      w="100%"
                    >
                      <h2 className="text-2xl md:text-4xl text-white font-bold leading-tight tracking-tight text-left mb-2">
                        Submit your project to get an expert feedback.
                      </h2>
                      <h3 className="text-lg md:text-xl text-white font-medium leading-tight tracking-tight text-left mb-4">
                        Learn directly from an industry expert. Improve your
                        skills with a detailed feedback.
                      </h3>

                      <AvatarGroup size={'sm'} py={3}>
                        {challenge?.track_detail?.mentors?.map(
                          (mentor, index) => (
                            <Avatar
                              key={index}
                              name={mentor?.firstname + ' ' + mentor?.lastname}
                              src={authService.buildImageUrl(mentor?.image)}
                            />
                          )
                        )}
                      </AvatarGroup>
                      <button
                        onClick={
                          hasJoined
                            ? () => openSubmissionForm()
                            : async () => await handleStartChallenge()
                        }
                        className="inline-flex items-center px-3 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-[#152340] hover:bg-[#19294e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#152340]"
                      >
                        {isSubmitting ? (
                          'Starting...'
                        ) : (
                          <span>{makeStartProjectLabel()}</span>
                        )}
                      </button>
                    </Box>
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 md:pl-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="rounded-t mb-0 md:pl-4 border-0">
                  <div className="flex flex-wrap items-center text-sm text-gray-600">
                    {challenge?.submission ? (
                      <Box
                        className="rounded-lg shadow-md shadow-black/5 ring-1 ring-slate-700/10"
                        p={4}
                        mb={8}
                        width={'full'}
                      >
                        <Heading
                          fontSize="md"
                          color="gray.600"
                          fontWeight="semibold"
                          mb="2"
                        >
                          Your submission
                        </Heading>
                        <Text fontSize={'xs'} textColor="gray.600">
                          <strong>Last updated:</strong>{' '}
                          {moment(challenge?.submission?.update_at).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </Text>
                        <Flex
                          py="4"
                          flexDirection={'column'}
                          textColor="gray.600"
                        >
                          <Text
                            pb={2}
                            fontSize={'sm'}
                            fontWeight="semibold"
                            color="gray.600"
                          >
                            Comment
                          </Text>
                          <Box p={2} bg={'orange.50'} rounded="lg">
                            {challenge.submission.additional_information?.trim()
                              .length > 0 ? (
                              <MarkdownToHtml
                                classname="markdown"
                                input={
                                  challenge.submission.additional_information
                                }
                              />
                            ) : (
                              <Text color="gray.400" fontStyle={'italic'}>
                                No additional information provided
                              </Text>
                            )}
                          </Box>
                          <Flex p={2} alignContent={'center'}>
                            <LinkIcon className="mr-2 w-4 h-4 text-gray-600" />{' '}
                            {challenge.submission?.url ? (
                              <Link
                                href={challenge.submission.work_link}
                                isExternal
                                color={'orange.500'}
                              >
                                Link to your work
                              </Link>
                            ) : (
                              <Text fontStyle={'italic'} color="gray.400">
                                No link provided
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                        <Box pt={4}>
                          <SubmissionStatus
                            status={challenge.submission.status}
                          />
                        </Box>
                      </Box>
                    ) : null}

                    <Skeleton
                      mx={[2, 0]}
                      isLoaded={!isLoading}
                      rounded={'lg'}
                      w={'full'}
                      pb={'16'}
                    >
                      <Flex flexDirection={'column'}>
                        <Heading
                          fontSize="xs"
                          letterSpacing={'wide'}
                          textTransform={'uppercase'}
                          color="gray.700"
                          fontWeight="semibold"
                          mb="2"
                        >
                          Recent attempts
                        </Heading>
                        {submissions?.length > 0 ? (
                          <Box
                            mt={[0, 0, 0, 4]}
                            mb={8}
                            width={'full'}
                            maxHeight={'72'}
                            overflow={'auto'}
                          >
                            <Flex flexDirection={'column'} textColor="gray.600">
                              {submissions?.map(submission => (
                                <LinkBox
                                  key={submission.id}
                                  className="hover:bg-orange-50 rounded-lg px-2 py-2"
                                >
                                  <Flex
                                    alignItems={'center'}
                                    cursor={'pointer'}
                                  >
                                    <Avatar
                                      size="sm"
                                      name={submission.submitter.fullname}
                                      src={
                                        submission.submitter.image ? (
                                          submission.submitter.image
                                        ) : (
                                          <UserIcon className="h-4 w-4" />
                                        )
                                      }
                                    />
                                    <Box ml={2}>
                                      <LinkOverlay
                                        href={`/profile/${submission.submitter.username}`}
                                      >
                                        <Text
                                          fontSize={'sm'}
                                          fontWeight="semibold"
                                          color="gray.600"
                                        >
                                          {submission.submitter.fullname}
                                        </Text>
                                      </LinkOverlay>
                                      <Text fontSize={'xs'} color="gray.600">
                                        {submission.submitter.username}
                                      </Text>
                                    </Box>
                                    <Box
                                      ml={'auto'}
                                      textColor="gray.600"
                                      textAlign={'right'}
                                    >
                                      <SubmissionStatus
                                        status={submission.status}
                                      />
                                    </Box>
                                  </Flex>
                                </LinkBox>
                              ))}
                            </Flex>
                          </Box>
                        ) : (
                          <Text color="gray.600" mb={4} fontSize="sm">
                            Recent attempts will appear here
                          </Text>
                        )}
                      </Flex>

                      {challenge.contributor ? (
                        <Flex flexDirection={'column'} py={6}>
                          <Heading
                            fontSize="xs"
                            letterSpacing={'wide'}
                            textTransform={'uppercase'}
                            color="gray.700"
                            fontWeight="semibold"
                            mb="2"
                          >
                            Contributed by
                          </Heading>
                          <ContentAuthorCard
                            author={challenge.contributor}
                            profileLink={`/mentor/${challenge.contributor.username}`}
                          />
                        </Flex>
                      ) : null}
                      <SocialShareBox
                        boxTitle="Share this project"
                        contentTitle={challenge.title}
                        contentType="project"
                      />
                    </Skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {ChallengeStartConfirmationDialog}
        </div>
      </LayoutWithSidebar>
    </>
  );
};

export default withRouter(Challenge);
