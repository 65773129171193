import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Skeleton,
  Button,
  Icon,
  HStack,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Modal,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Link,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';

import AuthService from '../services/auth-service';
import BookingService from '../services/booking-service';
import { Mixpanel } from '../utils/Mixpanel';
import { FaLink, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import InclusionItem from './Membership/InclusionItem';
import {
  CheckBadgeIcon,
  ShareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import {
  CheckBadgeIcon as CheckBadgeIconSolid,
  MapPinIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/solid';
import { FireIcon } from '@heroicons/react/20/solid';
import splitbee from '@splitbee/web';
import { oembed } from '@loomhq/loom-embed';

import subscriptionService from '../services/subscription-service';
import SkillCloud from './Common/SkillCloud';
import { ShareModal } from './Common/ShareModal';
import { QuickMessageModal } from './Common/QuickMessageModal';
import { RequestCallModal } from './Common/RequestCallModal';
import { PaperAirplane } from '@vectopus/atlas-icons-react';

export const MentorProfile = ({ mentor, profile, onSendQuickMessage }) => {
  const profileLink = `https://www.techstarta.com/mentor/${profile.username}`;
  const mentorshipPlans = [
    {
      name: 'Lite',
      price: '$7',
      price_id: 'price_1P9hF6EyLuaj2xNpFY17kLXq',
      description: 'Get hands-on mentorship from an industry expert',
      features: [
        'Access to guided projects',
        'Unlimited Q&A via chat',
        'Access to community',
        'Access to learning resources',
        '1 free Video call per month',
      ],
    },
    {
      name: 'Pro',
      price: '$15',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1P9hFqEyLuaj2xNpUv4f8KTZ',
      features: [
        'Access to guided projects',
        'Unlimited Q&A via chat',
        'Access to Slack community',
        'Join mentor-guided programs',
        'Participate in hands-on workshops',
        'Resume, LinkedIn & Portfolio review',
        '2 Video calls per month',
        'Mock interviews',
      ],
    },
    {
      name: 'Team',
      price: 'Custom',
      description: 'Get hands-on mentorship from an industry expert',
      price_id: 'price_1OMZp9EyLuaj2xNpqZyBdMiz',
      features: [
        'Private mentorship group',
        'Access to real-world projects',
        'Access to community',
        'Unlimed Q&A via chat',
        'Customized learning path',
        'Customized mentorship sessions',
        'Customized workshops',
        'Unlimited Video calls per month',
      ],
    },
  ];
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [mentorshipPlan, setMentorshipPlan] = useState(mentorshipPlans[1]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isShareModalOpen,
    onOpen: openShareModal,
    onClose: closeShareModal,
  } = useDisclosure();
  const {
    isOpen: isQuickSendModalOpen,
    onOpen: openQuickSendModal,
    onClose: closeQuickSendModal,
  } = useDisclosure();
  const {
    isOpen: isRequestCallModalOpen,
    onOpen: openRequestCallModal,
    onClose: closeRequestCallModal,
  } = useDisclosure();

  const [embedVideo, setEmbedVideo] = useState(null);

  useEffect(() => {
    const currentUser = JSON.parse(AuthService.getCurrentUser());
    setCurrentUser(currentUser);
    if (currentUser !== null) {
      setName(currentUser.firstname + ' ' + currentUser.lastname);
      setEmail(currentUser.email);
    }
  }, []);

  useEffect(() => {
    if (mentor.intro_video && mentor.intro_video.includes('loom')) {
      oembed(mentor.intro_video).then(embed => {
        setEmbedVideo(embed);
      });
    }
  }, [mentor]);

  const handlePlayIntro = () => {
    if (!mentor.intro_video) {
      return;
    }
    if (embedVideo && embedVideo.type === 'video') {
      onOpen();
      return;
    }

    if (mentor.intro_video.includes('youtube')) {
      const videoId = mentor.intro_video.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        const videoId = videoId.substring(0, ampersandPosition);
        setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
        onOpen();
      }
    }
  };

  const goToCheckout = async (priceId, mode) => {
    setCheckoutLoading(true);
    Mixpanel.track('Buy Mentorship Checkout', {
      mentorUsername: mentor.username,
      customerEmail: currentUser?.email,
    });
    splitbee.track('Buy Mentorship Checkout', {
      mentorUsername: mentor.username,
      customerEmail: currentUser?.email,
    });

    const payload = { price_id: priceId, mode: mode };
    if (currentUser) {
      payload.customer_email = currentUser.email;
    }

    try {
      const response = await subscriptionService.getCheckoutUrl(payload);
      if (response?.checkout.url) {
        const checkoutSessionUrl = response.checkout.url;
        window.open(checkoutSessionUrl, '_blank', 'noopener noreferrer');
      }
    } catch (err) {}
    setCheckoutLoading(false);
  };

  return (
    <>
      <div className="relative flex flex-col w-full mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full relative xl:w-8/12">
            <Flex
              flexDirection={'column'}
              rounded={'lg'}
              pr={['2', '4', '4', '4']}
            >
              <Flex
                flexWrap="wrap"
                justify={['center', 'center', 'center', 'space-between']}
                align={['center', 'center', 'center', 'center']}
                pb={4}
              >
                <div className="relative inline-block md:mr-4">
                  <Avatar
                    height={'40'}
                    width={'40'}
                    fontSize="45px"
                    borderRadius="50%"
                    bgColor={'brand.darkBlue'}
                    icon={<UserIcon className="w-28 h-28" />}
                    name={mentor.firstname + ' ' + mentor.lastname}
                    src={AuthService.buildImageUrl(mentor.image)}
                    shadow="md"
                  />
                  {mentor.intro_video ? (
                    <div class="absolute bottom-3 right-3 bg-orange-500 rounded-full p-1 border-2 border-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  ) : null}
                  {mentor.intro_video ? (
                    <button
                      onClick={handlePlayIntro}
                      className="absolute inset-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-50 rounded-full opacity-0 hover:opacity-100 transition duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-12 w-12 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  ) : null}
                </div>

                <Flex
                  fontSize="md"
                  mt={['20px', '10px', '0px']}
                  flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
                  direction={['column']}
                >
                  <Box textAlign={['center', 'start', 'center', 'start']}>
                    <HStack alignItems={'center'}>
                      <h1 className="text-[#2d3748] text-3xl md:text-4xl font-[700] leading-tight tracking-tight text-left">
                        {`${mentor.firstname} ${mentor.lastname}`}
                      </h1>
                      <Tooltip
                        background={'transparent'}
                        textColor={'gray.700'}
                        rounded={'md'}
                        label="Verified expert"
                        fontSize={'sm'}
                        aria-label="A tooltip"
                        placement="top-end"
                      >
                        {
                          <CheckBadgeIconSolid className="h-5 w-5 text-orange-500" />
                        }
                      </Tooltip>
                    </HStack>
                    <Text
                      className="mt-2"
                      fontWeight={'medium'}
                      textColor={'gray.700'}
                    >
                      {mentor.current_role + ' at ' + mentor.company}
                    </Text>
                    <HStack
                      className="mt-2 text-slate-600"
                      justify={['center', 'center', 'flex-start', 'flex-start']}
                    >
                      <MapPinIcon className="w-4 h-4 text-orange-500" />
                      <Text fontWeight={'medium'} className="text-slate-600">
                        {mentor.location}
                      </Text>
                    </HStack>
                    <div className="flex justify-between">
                      <HStack py={2} spacing={2}>
                        <button
                          onClick={openRequestCallModal}
                          className="inline-flex px-3 py-2 border border-transparent shadow-sm ring-1 ring-gray-100 focus:ring-offset-2  items-center text-sm leading-4 font-medium rounded-md hover:shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-orange-500"
                        >
                          <VideoCameraIcon className="h-4 w-4 mr-1" />
                          <span className="font-semibold">Request a call</span>
                        </button>
                        <button
                          onClick={openQuickSendModal}
                          className="inline-flex items-center py-2 px-3.5 text-sm shadow-sm font-medium text-gray-900 focus:outline-none bg-white leading-4 rounded-md border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        >
                          <PaperAirplane className="h-4 w-4 mr-1" />
                          Message
                        </button>
                      </HStack>

                      <HStack alignSelf="right" justify={'flex-end'}>
                        {currentUser &&
                          profile.username === currentUser.username && (
                            <a
                              href="/settings"
                              className="inline-flex items-center py-2 px-3.5 text-sm shadow-sm font-medium text-gray-900 focus:outline-none bg-white leading-4 rounded-md border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                            >
                              Edit
                            </a>
                          )}
                        <button
                          onClick={openShareModal}
                          className="inline-flex items-center py-2 px-3.5 text-sm shadow-sm font-medium text-gray-900 focus:outline-none bg-white leading-4 rounded-md border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        >
                          <ShareIcon className="w-4 h-4" />
                        </button>
                      </HStack>
                    </div>
                  </Box>
                </Flex>
              </Flex>
              <div className="divide-y divide-gray-100 border-gray-100 border-y py-4">
                <HStack className="align-middle text-sm font-medium py-2 text-gray-800">
                  <CheckBadgeIcon className="w-8 h-8 inline-block text-orange-500" />
                  <span>
                    Verified expert in:{' '}
                    {profile?.tracks && profile.tracks.length > 0
                      ? profile?.tracks
                          .filter(track => track.role === 'mentor')
                          .map(track => track.track.title)
                          .join(', ')
                      : 'No skills added yet'}
                  </span>
                </HStack>
              </div>
              <Flex flexDirection={'column'} mt={8}>
                <Box pt={4}>
                  <h2 className="text-[#2d3748] font-semibold leading-tight tracking-tight text-left mb-4">
                    About
                  </h2>
                  <p className="text-gray-800 leading-normal text-base">
                    {mentor.pitch}
                  </p>
                  {mentor.fun_fact ? (
                    <p className="text-gray-700 pt-4 leading-normal text-base">
                      {mentor.fun_fact}
                    </p>
                  ) : null}
                </Box>
                <HStack spacing="2" textAlign="center" my="4">
                  {mentor.linkedin ? (
                    <a
                      href={mentor.linkedin}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon as={FaLinkedinIn} />
                    </a>
                  ) : null}
                  {mentor.twitter ? (
                    <a
                      href={
                        mentor.twitter.startsWith('http')
                          ? mentor.twitter
                          : `https://twitter.com/${mentor.twitter}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon as={FaTwitter} />
                    </a>
                  ) : null}
                  {mentor.website ? (
                    <a
                      href={mentor.website}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon as={FaLink} />
                    </a>
                  ) : null}
                </HStack>

                <Flex flexDirection={'column'} mt={4}>
                  <Flex rounded={'lg'} flexDirection={'column'}>
                    <h2 className="text-[#2d3748] font-semibold leading-tight tracking-tight text-left mb-4">
                      Featured skills
                    </h2>
                    <Flex>
                      {mentor?.skills && mentor.skills.length > 0 ? (
                        <SkillCloud
                          skills={mentor?.skills.map(skill => skill.name)}
                          tagSize="md"
                          max={10}
                        />
                      ) : (
                        <Text fontSize="sm" textColor={'gray.600'}>
                          No skills added yet
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDirection={'column'} mt={8}>
                  <Flex rounded={'lg'} flexDirection={'column'}>
                    <h2 className="text-[#2d3748] font-semibold leading-tight tracking-tight text-left mb-4">
                      Mentorship topics
                    </h2>
                    <Flex>
                      {mentor?.topics && mentor.topics.length > 0 ? (
                        <SkillCloud
                          skills={mentor?.topics}
                          max={10}
                          tagSize="md"
                        />
                      ) : (
                        <Text fontSize="sm" textColor={'gray.600'}>
                          No topics added yet
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>

                <Flex flexDirection={'column'} my={8}>
                  <Flex rounded={'lg'} flexDirection={'column'}>
                    <h2 className="text-[#2d3748] font-semibold leading-tight tracking-tight text-left mb-4">
                      Featured artifacts
                    </h2>
                    <SimpleGrid columns={[1, 2, 2, 2]}>
                      {profile?.featured_resources &&
                      profile.featured_resources.length > 0 ? (
                        profile.featured_resources.map((resource, index) => (
                          <LinkBox
                            key={index}
                            className="p-4 ring-1 ring-gray-300/20 ring-inset shadow-sm hover:shadow-md hover:ring-gray-300 rounded-lg"
                          >
                            <LinkOverlay href={resource.link} isExternal>
                              <Text
                                fontSize="md"
                                className="text-orange-500 font-medium hover:underline"
                                key={index}
                              >
                                {resource.title}
                              </Text>
                            </LinkOverlay>
                            <Text
                              className="mt-2 text-sm text-gray-600"
                              key={index}
                            >
                              {resource.summary}
                            </Text>
                          </LinkBox>
                        ))
                      ) : (
                        <Text fontSize="sm" textColor={'gray.600'}>
                          No artifacts added yet
                        </Text>
                      )}
                    </SimpleGrid>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div className="w-full xl:w-4/12 xl:pl-4">
            <Flex
              rounded={'lg'}
              className="shadow-lg shadow-black/5 ring-1 ring-slate-700/10 xl:fixed xl:top-28 "
              flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
              direction={['column']}
            >
              <Tabs width={'100%'} align="center" isFitted colorScheme="orange">
                <TabList className="text-gray-700">
                  <Tab className="focus:outline-none hover:bg-gray-50 font-medium">
                    Membership plans
                  </Tab>
                  {/* <Tab className="focus:outline-none hover:bg-gray-50 font-medium">
                    Sessions
                  </Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <div className="w-full">
                      <Flex>
                        <div
                          className="inline-flex rounded-md shadow-sm mb-4"
                          role="group"
                        >
                          <button
                            type="button"
                            value={'lite'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[0]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Lite'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Lite plan
                          </button>
                          <button
                            type="button"
                            value={'pro'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[1]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 border-t border-b border-gray-200 hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Pro'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Pro plan
                          </button>
                          <button
                            type="button"
                            value={'team'}
                            onClick={() => {
                              setMentorshipPlan(mentorshipPlans[2]);
                            }}
                            className={`px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-orange-600 focus:outline-none ${
                              mentorshipPlan.name === 'Team'
                                ? 'bg-orange-50'
                                : 'bg-white'
                            }`}
                          >
                            Team plan
                          </button>
                        </div>
                      </Flex>
                      <Flex
                        align={'flex-start'}
                        flexDirection={'column'}
                        py={2}
                      >
                        <Text
                          fontSize={'3xl'}
                          fontWeight="bold"
                          textColor={'gray.700'}
                        >
                          {mentorshipPlan.price}{' '}
                          <span className="text-base">
                            {mentorshipPlan.price.includes('Custom')
                              ? ''
                              : '/mo'}
                          </span>
                        </Text>
                        <Text
                          fontSize={'md'}
                          pt={2}
                          fontWeight="medium"
                          textColor={'gray.700'}
                        >
                          Get mentored by {mentor.firstname}{' '}
                        </Text>
                      </Flex>
                      <Box textColor={'gray.700'} pb={10}>
                        {mentorshipPlan.features.map((feature, index) => (
                          <InclusionItem key={index} label={feature} />
                        ))}
                      </Box>

                      <Flex flexDirection={'column'} width={'100%'}>
                        <Button
                          onClick={() =>
                            goToCheckout(
                              mentorshipPlan.price_id,
                              'subscription'
                            )
                          }
                          width={'100%'}
                          bgColor={'brand.darkBlue'}
                          textColor={'white'}
                          _hover={{ shadow: 'md' }}
                          _active={{
                            shadow: 'md',
                            outlineColor: 'brand.darkBlue',
                          }}
                          isLoading={checkoutLoading}
                          _focus={{ outlineColor: 'brand.darkBlue' }}
                          spinner={<BeatLoader size={8} color={'white'} />}
                          rounded={'full'}
                        >
                          Get started with 14 days free
                        </Button>
                        <HStack fontSize={'sm'} py="2" textColor={'gray.600'}>
                          <FireIcon className="w-4 h-4" />
                          <span className="font-medium">
                            5 spots left this month
                          </span>
                        </HStack>
                      </Flex>
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Intro video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {embedVideo && embedVideo.type === 'video' && (
              <div
                className="rounded-lg ring-1 ring-gray-300 overflow-hidden mb-4"
                dangerouslySetInnerHTML={{ __html: embedVideo.html }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <ShareModal
        modalTitle={'Share profile'}
        title={'Hey there!'}
        body={
          "I'm mentoring techies on Techstarta. Check out my profile and send me a request!"
        }
        isShareModalOpen={isShareModalOpen}
        closeShareModal={closeShareModal}
        link={profileLink}
      />
      <QuickMessageModal
        modalTitle={`Send message`}
        isQuickSendModalOpen={isQuickSendModalOpen}
        closeQuickSendModal={closeQuickSendModal}
        onSendMessage={onSendQuickMessage}
        messageTo={mentor}
      />
      <RequestCallModal
        modalTitle={'Request a call'}
        isOpen={isRequestCallModalOpen}
        onClose={closeRequestCallModal}
      />
    </>
  );
};

export const MentorProfileSkeleton = () => {
  return (
    <>
      <Flex
        p={'4'}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Skeleton
            w="24"
            h="24"
            mb="25px"
            rounded="50%"
            shadow="sm"
          ></Skeleton>
          <Skeleton height="20px"></Skeleton>
        </Box>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Skeleton mb="10px" height="30px"></Skeleton>
            <Skeleton height="15px"></Skeleton>
          </Box>
          <Box>
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="100px"></Skeleton>
          </Box>
        </Flex>

        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 32%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <Box>
            <Skeleton mb="25px" height="50px"></Skeleton>
          </Box>
          <Box>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
          </Box>
          <Skeleton my="25px" height="50px"></Skeleton>

          <Skeleton height="50px"></Skeleton>
        </Flex>
      </Flex>
    </>
  );
};
