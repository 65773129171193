import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
} from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import RoadmapGridSections from '../components/RoadMapSections.js';
import { SectionHeader } from '../components/RoadMapSections.js';
import choice from '../images/choice.png';
import profileService from '../services/profile-service.js';
import { SkillWithProgress } from '../components/Skill/SkillWithProgress.js';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import TrackService from '../services/track-service.js';
import { ArrowUpGraphIncrease } from '@vectopus/atlas-icons-react';

const Skills = () => {
  const currrentUser = JSON.parse(localStorage.getItem('user'));
  const [userTracks, setUserTracks] = useState([]);
  const [username, setUsername] = useState(currrentUser?.username);
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await profileService.getProfile(username);
        setUserTracks(response.data.profile?.tracks);
      }
      setLoading(false);
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    async function fetchTracks() {
      const result = await TrackService.fetchAllTracks();
      setTracks(result.tracks);
    }
    fetchTracks();
  }, []);

  return (
    <LayoutWithSidebar
      title={'Roadmap: Free resources for learning tech skills | Techstarta'}
      url={'https://techstarta.com/skills'}
      pageTitle={'Roadmap'}
      description={
        'Learn Product Management, Product Design, Software Development, AI, and Business.'
      }
    >
      <Skeleton isLoaded={!loading} rounded={'lg'}>
        {userTracks?.length > 0 ? (
          <div>
            <SectionHeader
              title="Your Progress"
              icon={
                <ArrowUpGraphIncrease className="w-6 h-6 text-orange-500" />
              }
              marginBottom="10px"
            />
            <h2 className="text-md font-medium text-gray-500">
              Level up your skills with hands-on projects and curated resources.
            </h2>

            <SimpleGrid mt={8} columns={[1, 2, 2, 4]} spacing={4} mb={'40px'}>
              {userTracks.map((track, index) => (
                <SkillWithProgress key={index} track={track} />
              ))}
            </SimpleGrid>
          </div>
        ) : (
          <Box
            border="1px solid"
            borderColor="gray.200"
            rounded="lg"
            p="32px 40px 48px"
            style={{ background: 'rgb(251, 251, 249)' }}
            mb={'4'}
            shadow="sm"
          >
            <Flex justifyContent={'space-between'}>
              <Box>
                <h1 className="text-3xl md:text-4xl text-gray-800 font-bold leading-tight tracking-tight text-left mb-4">
                  Roadmaps for learning tech skills
                </h1>
                <h2 className="text-xl font-medium text-gray-700">
                  Not sure where to begin? Take a 2-minute quiz to find out
                  which path fits you best.
                </h2>
                <Box mt="4">
                  <Link _hover={{ shadow: 'md' }} href="/quiz">
                    <Button
                      _hover={{ shadow: 'md' }}
                      rounded={'full'}
                      bgColor={'brand.darkBlue'}
                      fontSize="sm"
                      textColor="white"
                    >
                      Take the quiz
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box>
                <Image src={choice} alt="Find your tech career fit" w={'20'} />
              </Box>
            </Flex>
          </Box>
        )}
      </Skeleton>

      <RoadmapGridSections tracks={tracks} />
    </LayoutWithSidebar>
  );
};

export default withRouter(Skills);
