import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, Flex, HStack } from '@chakra-ui/react';
import { Submission, SubmissionSkeleton } from '../components/Submission.js';
import withRouter from '../utils/withRouter.js';

import SubmissionService from '../services/submissions-service';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LayoutWithSidebar from '../components/Layout/LayoutWithSidebar.js';
import ProjectReference from '../components/Submission/ProjectReference.jsx';
import { FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmissionDetail = () => {
  const { submissionId } = useParams();
  const submissionLink = `/submissions/${submissionId}`;

  const { isPending, data } = useQuery({
    queryKey: ['submission', submissionId],
    queryFn: () => SubmissionService.getSubmission(submissionId),
  });

  const queryClient = useQueryClient();

  const submissionVoteMutation = useMutation({
    mutationFn: submissionId =>
      SubmissionService.upvoteSubmission(submissionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['submission', submissionId] });
    },
  });

  const onGiveAward = async event => {
    event.preventDefault();
    const payload = {
      submissionId: id,
      gradeComment,
      grade: gradeValue,
    };

    try {
      const response = await SubmissionService.submitGrading(payload);
      if (response.status === 200) {
        toast({
          title: 'Success!',
          description: 'Grade submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setGradeComment(response.data.submission.grade_comment);
        setGradeValue(response.data.submission.grade);
        setStatus(response.data.submission.status);
      }
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error.data.submission.detail,
        isClosable: true,
        status: 'error',
        colorScheme: 'red',
        variant: 'left-accent',
        position: 'bottom-left',
        duration: 3000,
      });
    }
    onClose();
  };

  if (isPending) {
    return (
      <LayoutWithSidebar>
        <SubmissionSkeleton />
      </LayoutWithSidebar>
    );
  }
  const submission = data.submission;

  return (
    <LayoutWithSidebar title={'Project submission'}>
      <div className="mx-auto w-full">
        <Flex>
          <a
            className="rounded-full inline-flex items-center bg-gray-100 justify-center text-gray-700 text-sm font-semibold hover:bg-gray-200 py-1.5 px-4"
            href="/showcase/submissions"
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span className="font-medium">Back to all submissions</span>
          </a>
        </Flex>
        <div className="flex flex-wrap mt-4">
          <div className="w-full md:w-8/12 mb-12 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
              <div className="rounded-t mb-0 border-0">
                <Submission
                  submission={submission}
                  submissionVoteMutation={submissionVoteMutation}
                />
              </div>
            </div>
          </div>
          <div className="w-full xl:w-4/12">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
              <div className="flex flex-wrap items-center text-xs md:px-4">
                <Flex flexDirection={'column'} width="100%" className="sticky">
                  <Flex
                    border="1px solid"
                    borderColor="gray.100"
                    shadow={'sm'}
                    borderRadius={'lg'}
                    p={4}
                    bgColor="white"
                    flexDirection={'column'}
                  >
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Flex alignItems={'center'}>
                        <Avatar
                          mr={2}
                          name={submission.submitter.fullname}
                          src={submission.submitter.image}
                        />{' '}
                        <Box>
                          <a
                            href={`/profile/${submission.submitter.username}`}
                            className="text-orange-600 font-semibold text-base pr-2 hover:text-orange-500"
                          >
                            {submission.submitter.fullname}
                          </a>
                          <p className="text-xs text-gray-700">
                            {submission.submitter.short_bio}
                          </p>
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex flexDirection="column" className="mt-10">
                      <h3 className="text-base mb-4 font-bold">
                        Project Brief
                      </h3>
                      <ProjectReference project={submission.challenge} />
                    </Flex>
                    <Flex className="mt-10" flexDirection="column">
                      <h3 className="text-base mb-4 font-bold">Share</h3>
                      <Flex>
                        <HStack spacing={2} color={'gray.600'}>
                          <LinkedinShareButton
                            title={'Hi there! Check out Techstarta'}
                            summary={
                              "I'm building my tech career with Techstarta. You should too!"
                            }
                            url={submissionLink}
                            source={submissionLink}
                          >
                            <FaLinkedin
                              className="hover:text-orange-500"
                              size={20}
                            />
                          </LinkedinShareButton>
                          <TwitterShareButton
                            title={
                              "Hey there! I'm building my tech career with Techstarta. You should too!"
                            }
                            url={submissionLink}
                            via="techstarta"
                            hashtags={['LearnByDoing', 'TechstartaProjects']}
                          >
                            <FontAwesomeIcon
                              icon={faXTwitter}
                              className="hover:text-orange-500 h-4 w-4"
                            />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            title={
                              "Hey there! I'm building my tech career with Techstarta. You should too!"
                            }
                            url={submissionLink}
                          >
                            <FaWhatsapp
                              className="hover:text-orange-500"
                              size={20}
                            />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            subject={`Hey there! I'm building my tech career with Techstarta. You should too!`}
                            url={submissionLink}
                          >
                            <FaFacebook
                              className="hover:text-orange-500"
                              size={20}
                            />
                          </FacebookShareButton>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default withRouter(SubmissionDetail);
