import {
  API_BASE_URL,
  AUTH_TOKEN_KEY,
  connection,
  MEDIA_BASE_URL,
} from './index';

connection.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

const login = async payload => {
  const { data } = await connection.post(API_BASE_URL + `users/login`, payload);
  if (data.user) {
    setAuthToken(data.user.token);
  }
  return data;
};

const register = async payload => {
  const { data } = await connection.post(API_BASE_URL + `users`, payload);
  if (data.user) {
    setAuthToken(data.user.token);
  }
  return data;
};

const setAuthToken = token => {
  return localStorage.setItem(AUTH_TOKEN_KEY, token);
};

const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

const setCurrentUser = currentUser => {
  return localStorage.setItem('user', JSON.stringify(currentUser));
};

const getCurrentUser = () => {
  return localStorage.getItem('user');
};

const fetchCurrentUser = async () => {
  const { data } = await connection.get(API_BASE_URL + `user`, {
    headers: {
      Authorization: `token ${getAuthToken()}`,
    },
  });
  if (data.user) {
    setCurrentUser(data.user);
  }

  return data.user;
};

const logout = () => {
  localStorage.clear();
};

const isAuthenticated = () => {
  return getAuthToken();
};

const updateUserProfile = async userProfile => {
  const response = await connection.put(API_BASE_URL + 'user', userProfile, {
    headers: {
      Authorization: `token ${getAuthToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  if (response) {
    await fetchCurrentUser();
    return response.status;
  }
};

const createEmailVerification = async payload => {
  const response = await connection.post(
    API_BASE_URL + 'users/send_verification',
    { user: payload },
    {
      headers: {
        Authorization: `token ${getAuthToken()}`,
      },
    }
  );
  return response.status;
};

const confirmEmailVerification = async payload => {
  return await connection.put(
    API_BASE_URL + 'users/verify_email',
    {
      user: payload,
    },
    {
      headers: {
        Authorization: `token ${getAuthToken()}`,
      },
    }
  );
};

const requestUserPasswordReset = async payload => {
  const response = await connection.post(API_BASE_URL + 'password_resets', {
    password_reset: payload,
  });
  return response.status;
};

const updateUserPassword = async payload => {
  const response = await connection.post(API_BASE_URL + 'set_password', {
    password_reset: payload,
  });
  return response.status;
};

const getLoggedInProfile = async username => {
  const token = getAuthToken();
  const response = await connection.get(API_BASE_URL + `profiles/${username}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });

  return response.data.profile;
};

const getProfile = async username => {
  const response = await connection.get(API_BASE_URL + `profiles/${username}`);

  return response.data.profile;
};

const buildImageUrl = path => {
  if (path && path.startsWith('https://')) {
    return path;
  }
  return MEDIA_BASE_URL + path;
};

const authWithGoogle = async authCode => {
  const response = await connection.post(API_BASE_URL + 'auth/google', {
    code: authCode,
  });
  if (response.data.user) {
    setAuthToken(response.data.user.token);
  }
  return response.data;
};

export default {
  login,
  register,
  getAuthToken,
  getCurrentUser,
  getLoggedInProfile,
  fetchCurrentUser,
  isAuthenticated,
  logout,
  updateUserProfile,
  createEmailVerification,
  confirmEmailVerification,
  requestUserPasswordReset,
  updateUserPassword,
  buildImageUrl,
  authWithGoogle,
  getProfile,
};
