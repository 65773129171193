import React, { useRef, useState } from 'react';
import moment from 'moment';
import {
  Avatar,
  Box,
  Flex,
  Alert,
  AlertIcon,
  Skeleton,
  Button,
  useToast,
  Link,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Tooltip,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

import RichEditor from './Editor';
import submissionsService from '../services/submissions-service';
import AuthService from '../services/auth-service';
import validator from '../utils/validator';
import MarkdownToHtml from './ReactMarkdownItem';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import discussionService from '../services/discussion-service';
import CommentBox from './Common/CommentBox';
import {
  ChatBubbleOvalLeftIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/solid';
import AwardMedal from './Submission/AwardMedal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

export const Submission = ({ submission, submissionVoteMutation }) => {
  const [editorValue, setEditorValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [comments, setComments] = useState(submission.comments);
  const toast = useToast();
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const { id, submitter, created_at, challenge } = submission;
  const [gradeValue, setGradeValue] = useState(submission.grade);
  const [gradeComment, setGradeComment] = useState(
    submission.grade_comment || ''
  );
  const [status, setStatus] = useState(submission.status);

  const submitComment = async () => {
    const body = editorValue;
    if (!validator.isValueValid(editorValue)) {
      setIsInputValid(false);
      return;
    }
    const payload = {
      text: body,
      object_id: id,
      content_type: 'submission',
    };
    try {
      setIsLoading(true);

      const response = await discussionService.submitComment(payload);
      setComments([response.data, ...comments]);
      if (response === 201) {
        toast({
          title: 'Success!',
          description: 'Comment submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setEditorValue('');
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to submit comment, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const upvoteComment = async commentId => {
    try {
      const response = await discussionService.upvoteComment(commentId);
      const updatedComments = comments.map(comment => {
        if (comment.id === commentId) {
          return response.data.comment;
        }
        return comment;
      });
    } catch (err) {}
  };

  const commentsBtnRef = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onGiveAward = async event => {
    event.preventDefault();
    const payload = {
      submissionId: id,
      gradeComment,
      grade: gradeValue,
    };

    try {
      const response = await submissionsService.submitGrading(payload);
      if (response.status === 200) {
        toast({
          title: 'Success!',
          description: 'Grade submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setGradeComment(response.data.submission.grade_comment);
        setGradeValue(response.data.submission.grade);
        setStatus(response.data.submission.status);
      }
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error.data.submission.detail,
        isClosable: true,
        status: 'error',
        colorScheme: 'red',
        variant: 'left-accent',
        position: 'bottom-left',
        duration: 3000,
      });
    }
    onClose();
  };

  return (
    <>
      <Flex
        p={4}
        border="1px solid"
        borderColor="gray.100"
        shadow="sm"
        rounded="lg"
        flexDirection="column"
      >
        <h1 className="text-gray-800 font-bold leading-tight text-3xl md:text-4xl">
          {challenge.title}
        </h1>
        <div className="flex flex-wrap justify-between pt-2">
          <div className="flex gap-2 items-center">
            <Box>
              <Avatar
                mr={2}
                size={'xs'}
                name={submitter.fullname}
                src={submitter.image}
              />
              <a
                href={`/profile/${submitter.username}`}
                className="text-orange-600 font-semibold text-sm"
              >
                {submitter.fullname}
              </a>
            </Box>
            <span className="text-gray-500 text-sm font-medium leading-relaxed">
              posted {moment(created_at).fromNow()}
              {submission.views > 0 ? (
                <span>
                  <span className="px-1">&middot;</span>
                  {submission.views} views
                </span>
              ) : null}
            </span>
          </div>
          <div className="inline-flex justify-end gap-3" role="group">
            <Tooltip label="Upvote" rounded={'lg'} bg={'gray.800'}>
              <button
                type="button"
                onClick={event => {
                  event.preventDefault();
                  submissionVoteMutation.mutate(id);
                }}
                disabled={submissionVoteMutation.isPending}
                className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-neutral-300  text-neutral-600 bg-white hover:bg-neutral-100 hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none"
              >
                <HandThumbUpIcon className="h-4 w-4" />
                {submission.upvotes > 0 ? (
                  <span className="ml-1">{submission.upvotes}</span>
                ) : null}
              </button>
            </Tooltip>
            <Tooltip label={'Comment'} rounded={'lg'} bg={'gray.800'}>
              <button
                type="button"
                onClick={() =>
                  commentsBtnRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
                className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-neutral-300 text-neutral-600 bg-white hover:bg-neutral-100  hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none"
              >
                <ChatBubbleOvalLeftIcon className="h-4 w-4" />
                {submission.comments.length > 0 ? (
                  <span className="ml-1">{submission.comments.length}</span>
                ) : null}
              </button>
            </Tooltip>

            {submission.grade !== null ? (
              <Tooltip label={`${submission.grade} medal`} rounded={'lg'}>
                <div>
                  <AwardMedal grade={submission.grade} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip label="Award submission" rounded={'lg'}>
                <button
                  type="button"
                  onClick={onOpen}
                  className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-neutral-300 text-neutral-600 bg-white hover:bg-neutral-100 hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none"
                >
                  <FontAwesomeIcon icon={faMedal} className=" w-4 h-4" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        <Flex py={4} rounded="lg" flexDirection={'column'}>
          <MarkdownToHtml
            input={submission.additional_information}
            classname={'text-gray-700'}
          />
        </Flex>
        <HStack py={4}>
          <Link
            fontSize={'sm'}
            href={submission.work_link}
            target="_blank"
            isExternal
          >
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              View project
              <span className="ml-2" aria-hidden="true">
                <ExternalLinkIcon />
              </span>
            </button>
          </Link>
          {submission.documentation_link ? (
            <Link
              fontSize={'sm'}
              href={submission.documentation_link}
              target="_blank"
              isExternal
            >
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-gray-700 bg-gray-50 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Additional link
                <span className="ml-2" aria-hidden="true">
                  <ExternalLinkIcon />
                </span>
              </button>
            </Link>
          ) : null}
        </HStack>

        <Flex
          flexDirection={['column', 'column']}
          p={['5px', '5px', '5px', '10px']}
        >
          <Flex marginTop="20px">
            <Box pr="5px" className="hidden md:block">
              <Avatar
                src={
                  currentUser
                    ? AuthService.buildImageUrl(currentUser.image)
                    : null
                }
                name={
                  currentUser
                    ? currentUser.firstname + ' ' + currentUser.lastname
                    : ''
                }
              />
            </Box>
            <Flex ref={commentsBtnRef} w="100%" flexDirection={'column'}>
              <RichEditor
                editorValue={editorValue}
                setEditorValue={setEditorValue}
                editorPlaceholder="Comment here. Be constructive and helpful."
              />
              <Alert
                status="warning"
                fontSize="sm"
                style={{
                  display: isInputValid ? 'none' : 'block',
                }}
              >
                <AlertIcon />
                Comment can not be empty
              </Alert>
              <Box pt={4}>
                <Button
                  _focus={{ outline: 'none' }}
                  bg="brand.darkBlue"
                  _hover={{ shadow: 'md' }}
                  width={'28'}
                  onClick={submitComment}
                  colorScheme="whiteAlpha"
                  rounded={'full'}
                  isLoading={isLoading}
                  loadingText="Submitting"
                >
                  Post
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Box marginTop="20px">
          {comments.map(comment => (
            <CommentBox
              key={comment?.id}
              comment={comment}
              upvoteFn={upvoteComment}
            />
          ))}
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Award this project submission!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ul className="grid w-full gap-6 grid-cols-3 pb-6">
              <li>
                <input
                  type="radio"
                  id="award-gold"
                  name="award"
                  onChange={event => setGradeValue(event.target.value)}
                  value={5}
                  className="hidden peer"
                  required
                />
                <label
                  for="award-gold"
                  className="inline-flex items-center justify-center w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-xl cursor-pointer peer-checked:border-orange-600 peer-checked:text-orange-600 hover:text-gray-600 hover:bg-gray-100"
                >
                  <FontAwesomeIcon
                    icon={faMedal}
                    className="h-8 w-8 text-yellow-500"
                  />
                </label>
              </li>
              <li className="items-center justify-center">
                <input
                  type="radio"
                  id="award-silver"
                  name="award"
                  value={4}
                  onChange={event => setGradeValue(event.target.value)}
                  className="hidden peer"
                  required
                />
                <label
                  for="award-silver"
                  className="inline-flex items-center justify-center w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-xl cursor-pointer peer-checked:border-orange-600 peer-checked:text-orange-600 hover:text-gray-600 hover:bg-gray-100"
                >
                  <FontAwesomeIcon
                    icon={faMedal}
                    className="h-8 w-8 text-gray-500"
                  />
                </label>
              </li>
              <li className="items-center justify-center">
                <input
                  type="radio"
                  id="award-bronze"
                  name="award"
                  onChange={event => setGradeValue(event.target.value)}
                  value={3}
                  className="hidden peer"
                  required
                />
                <label
                  for="award-bronze"
                  className="inline-flex items-center justify-center w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-orange-600 peer-checked:text-orange-600 hover:text-gray-600 hover:bg-gray-100"
                >
                  <FontAwesomeIcon
                    icon={faMedal}
                    className="h-8 w-8 text-orange-400"
                  />{' '}
                </label>
              </li>
            </ul>
            <textarea
              name={'gradeComment'}
              onChange={event => {
                setGradeComment(event.target.value);
              }}
              placeholder={'Highlight something that stood out for you.'}
              maxLength="1000"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 h-"
              value={gradeComment}
              required={false}
            />
          </ModalBody>
          <ModalFooter>
            <div className="flex w-full">
              <button
                onClick={onGiveAward}
                type="button"
                className="w-full items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Give Award
              </button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const SubmissionSkeleton = () => {
  return (
    <>
      <Box color="#2d3748" mt="30px">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={6} spacing="4" skeletonHeight="2" />
      </Box>
      <Box mt="40px">
        <Box color="#718096">
          <Skeleton h="50px"> </Skeleton>
          <Box>
            <Skeleton mt="20px" h="30px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
