import React from 'react';
import { Tag } from '@chakra-ui/react';

import {
  CheckBadgeIcon,
  ArrowPathRoundedSquareIcon,
} from '@heroicons/react/24/outline';
import { ArrowsDotsRotate, XmarkCircle } from '@vectopus/atlas-icons-react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const SubmissionStatus = ({ status }) => {
  switch (status) {
    case 'open_for_review':
      return (
        <Tag
          variant={'subtle'}
          fontSize={'xs'}
          width={'max-content'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'blue'}
        >
          <CheckCircleIcon className="mr-1 w-4 h-4" />
          Completed
        </Tag>
      );
    case 'graded':
      return (
        <Tag
          variant={'subtle'}
          fontSize={'xs'}
          width={'max-content'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'purple'}
        >
          <CheckBadgeIcon className="mr-1 w-4 h-4" /> Mentor Graded
        </Tag>
      );
    case 'work_in_progress':
      return (
        <Tag
          fontSize={'xs'}
          width={'max-content'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'green'}
          variant={'subtle'}
        >
          <ArrowsDotsRotate className="mr-1 w-4 h-4" /> In progress
        </Tag>
      );
    case 'rejected':
      return (
        <Tag
          fontSize={'xs'}
          width={'max-content'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'red'}
        >
          <XmarkCircle className="mr-1 w-4 h-4" /> Rejected
        </Tag>
      );
  }
};

export default SubmissionStatus;
