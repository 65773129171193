import React from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useClipboard,
} from '@chakra-ui/react';

import { FaFacebook, FaLink, FaTwitter } from 'react-icons/fa';
import { PaperAirplane } from '@vectopus/atlas-icons-react';

export const ShareDropdownButton = ({
  contentTitle,
  boxTitle,
  contentType,
}) => {
  const { hasCopied, onCopy } = useClipboard(window.location.href);

  const tweetThis = event => {
    event.preventDefault();
    const tweetedLink = window.location.href;
    window.open(
      'http://twitter.com/intent/tweet?url=' +
        tweetedLink +
        '&text=Check out this ' +
        contentType +
        ': ' +
        contentTitle +
        '. &via=techstarta&',
      'twitterwindow',
      'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
      '_blank'
    );
  };

  const shareThis = event => {
    event.preventDefault();
    const sharedLink = window.location.href;
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + sharedLink,
      '_blank'
    );
  };

  return (
    <>
      <Menu>
        <MenuButton
          _focus={{ outlineColor: 'orange.500' }}
          variant={'outline'}
          rounded={'full'}
          as={Button}
        >
          <PaperAirplane className="h-5 w-5 text-gray-600" />
        </MenuButton>
        <MenuList>
          <MenuItem
            _hover={{
              bgColor: 'gray.100',
              outline: 'none',
            }}
            fontSize={'sm'}
            onClick={tweetThis}
            _focus={{ outline: 'none' }}
            _active={{ outline: 'none' }}
            icon={<FaTwitter />}
          >
            Share on Twitter
          </MenuItem>
          <MenuItem
            fontSize={'sm'}
            _hover={{ bgColor: 'gray.100', outline: 'none' }}
            _focus={{ outline: 'none' }}
            _active={{ outline: 'none' }}
            onClick={shareThis}
            icon={<FaFacebook />}
          >
            Share on Facebook
          </MenuItem>
          <MenuItem
            fontSize={'sm'}
            onClick={onCopy}
            _hover={{
              bgColor: 'gray.100',
              outline: 'none',
            }}
            _focus={{ outline: 'none' }}
            _active={{ outline: 'none' }}
            icon={<FaLink />}
          >
            {hasCopied ? 'Copied' : 'Copy'}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
