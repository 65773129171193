import React from 'react';
import moment from 'moment';
import { Avatar, Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import MarkdownToHtml from '../ReactMarkdownItem';
import { HandThumbUpIcon } from '@heroicons/react/24/solid';

const CommentBox = ({ comment, upvoteFn }) => {
  return (
    <>
      <Flex p={['10px', '10px', '10px', '20px']}>
        <Flex>
          <Avatar
            size="sm"
            name={comment.author.fullname}
            src={comment.author.image}
          />
          <div>
            <Box
              className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10"
              marginX="10px"
              padding="10px"
              rounded="lg"
              width={['90%', '95%', '95%', '100%']}
            >
              <div className="inline-flex text-sm gap-2 items-center leading-relaxed">
                <Text className="font-semibold" fontSize="sm" color="gray.600">
                  {comment.author.fullname}
                </Text>
                <span className="font-medium text-gray-600">&middot;</span>
                <span className="font-medium text-gray-600">
                  {moment(comment.created_at).fromNow()}
                </span>
              </div>
              <div className="text-base leading-snug md:leading-normal pt-5">
                <MarkdownToHtml input={comment.text} />
              </div>
            </Box>
            <HStack mx="10px" my="1">
              <Button
                onClick={event => {
                  event.preventDefault();
                  upvoteFn(comment.id);
                }}
                colorScheme="gray"
                leftIcon={<HandThumbUpIcon className="w-4 h-4 text-gray-500" />}
                size="sm"
                rounded="full"
                variant="ghost"
              >
                {comment.num_vote_up}
              </Button>
            </HStack>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default CommentBox;
