import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Box, Stack, Button } from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import SEO from '../components/Common/SEO.js';
import Navbar from '../components/Navbar.js';
import {
  BriefcaseIcon,
  CheckBadgeIcon,
  ChevronRightIcon,
  FaceSmileIcon,
  LightBulbIcon,
  SparklesIcon,
  SquaresPlusIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import authService from '../services/auth-service.js';
import {
  DollarSuitcase,
  HandshakeHandGesture,
  Users,
} from '@vectopus/atlas-icons-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader.js';

const Onboarding = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(authService.getCurrentUser());
  const MAX_MOTIVATIONS = 2;

  const motivationSchema = Yup.object().shape({
    motivation: Yup.array()
      .min(1, 'Select at least one motivation')
      .max(2, 'Select at most two motivations')
      .required('Select your top 2 motivation'),
  });

  const handleNext = async payload => {
    const result = await authService.updateUserProfile(payload);
    const hasMentorMotivation = payload.motivation.includes('mentor-others');
    if (hasMentorMotivation) {
      navigate('/onboarding/mentor');
    } else {
      navigate('/onboarding/learner');
    }
  };

  return (
    <>
      <SEO
        title="Onboarding | Techstarta"
        url="https://techstarta.com/onboarding"
        description={'Welcome to Techstarta. Setup your account'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        backgroundColor="white"
      >
        <Navbar
          transparent={false}
          currentUser={currentUser}
          showMenu={false}
        />
        <div className="flex items-center justify-between setup w-full">
          <div className="flex w-full md:w-2/3 items-center justify-center setup-buttons onboarding-glass-bg">
            <React.Fragment>
              <Flex
                p={[2, 16, 16, '12']}
                flexDirection="column"
                className="width-80"
              >
                <Box pb={4} textColor={'gray.700'}>
                  <Text fontSize={'4xl'} fontWeight="bold" pb={2}>
                    Hi {currentUser.firstname}! 👋
                  </Text>
                  <Text fontSize={'2xl'} fontWeight="medium" pb={4}>
                    We're glad you are here! What brought you to Techstarta?
                  </Text>
                </Box>
                <Formik
                  initialValues={{ motivation: [] }}
                  validationSchema={motivationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    await handleNext(values);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <Form>
                      <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">
                        Select up to 2 reasons why you joined so we can tailor
                        your experience
                      </h3>
                      <ul className="grid w-full gap-6 md:grid-cols-2">
                        <li>
                          <input
                            type="checkbox"
                            id="build-portfolio"
                            value="build-portfolio"
                            name="motivation"
                            className="hidden peer"
                            onChange={handleChange}
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('build-portfolio')
                            }
                            required=""
                            onBlur={handleBlur}
                          />
                          <label
                            htmlFor="build-portfolio"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <BriefcaseIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                To build your portfolio
                              </div>
                            </div>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="mentor-others"
                            name="motivation"
                            value="mentor-others"
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('mentor-others')
                            }
                            className="hidden peer"
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="mentor-others"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <StarIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                To help mentor others
                              </div>
                            </div>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="build-side-projects"
                            name="motivation"
                            value="build-side-projects"
                            className="hidden peer"
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('build-side-projects')
                            }
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="build-side-projects"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <LightBulbIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                To build side projects
                              </div>
                            </div>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="bridge-skill-gap"
                            name="motivation"
                            value="bridge-skill-gap"
                            className="hidden peer"
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('bridge-skill-gap')
                            }
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="bridge-skill-gap"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <SquaresPlusIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                To bridge a skill gap
                              </div>
                            </div>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="get-mentorship"
                            name="motivation"
                            value="get-mentorship"
                            className="hidden peer"
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('get-mentorship')
                            }
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="get-mentorship"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <SparklesIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                To get 1-on-1 mentorship
                              </div>
                            </div>
                          </label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            id="just-curious"
                            name="motivation"
                            value="just-curious"
                            className="hidden peer"
                            disabled={
                              values.motivation.length >= MAX_MOTIVATIONS &&
                              !values.motivation.includes('just-curious')
                            }
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="just-curious"
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-orange-500 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                          >
                            <div className="inline-flex items-center gap-2">
                              <FaceSmileIcon className="w-6 h-6" />
                              <div className="w-full text-lg font-medium">
                                Just curious
                              </div>
                            </div>
                          </label>
                        </li>
                      </ul>
                      <Text
                        borderRadius="md"
                        fontSize="sm"
                        mt="2"
                        textColor="red.500"
                      >
                        {errors.motivation && touched.motivation
                          ? errors.motivation
                          : null}
                      </Text>
                      <div className="flex justify-end w-full mt-6">
                        <Button
                          rounded={'full'}
                          variant={'solid'}
                          colorScheme={'gray'}
                          size="sm"
                          type="submit"
                          isLoading={isSubmitting}
                          spinner={
                            <BeatLoader size={8} className="text-gray-900" />
                          }
                          rightIcon={<ChevronRightIcon className="w-4 h-4" />}
                        >
                          Continue
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Flex>
            </React.Fragment>
          </div>
          <div className="hidden sm:block w-full xl:w-1/3">
            <Flex
              height="100%"
              align={'center'}
              flexDirection="column"
              justifyContent={'center'}
            >
              <Text className="text-left text-lg font-semibold pt-28">
                Why join Techstarta?
              </Text>
              <Stack spacing={'4'} p={'12'}>
                <Stack
                  bg={'white'}
                  key={1}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <Users className="w-6 h-6" />
                  <Text fontWeight={600}>Join 1000+ techies</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Join a community of techies from all over the world and get
                    support from our mentors.
                  </Text>
                </Stack>
                <Stack
                  key={2}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Gain hands-on experience</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Solve real-world problems to build your portfolio and boost
                    your confidence.
                  </Text>
                </Stack>
                <Stack
                  key={3}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <HandshakeHandGesture className="w-6 h-6" />

                  <Text fontWeight={600}>Mentorship</Text>
                  <Text color={'gray.600'} className="text-sm">
                    We believe mentorship is a two-way street. You can learn
                    from both newbies and experts.
                  </Text>
                </Stack>

                <Stack
                  key={4}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <DollarSuitcase className="w-6 h-6" />

                  <Text fontWeight={600}>
                    Monetize your skills and knowledge
                  </Text>
                  <Text color={'gray.600'} className="text-sm">
                    Get paid for mentoring others, contributing projects, and
                    solving bounty challenges.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default withRouter(Onboarding);
