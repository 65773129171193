export const trackData = [
  {
    name: 'Android Development',
    slug: 'android-development',
  },
  {
    name: 'Backend Development',
    slug: 'backend-development',
  },
  {
    name: 'Frontend Development',
    slug: 'frontend-development',
  },
  {
    name: 'Product Design',
    slug: 'ui-ux-design',
  },
  {
    name: 'Product Management',
    slug: 'product-management',
  },
  {
    name: 'Data Analytics',
    slug: 'data-analytics',
  },
  {
    name: 'Content Marketing',
    slug: 'content-marketing',
  },
  {
    name: 'Not sure - just checking things out',
    slug: 'dont-know',
  },
];
