import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const listGuilds = async () => {
  return await connection.get(API_BASE_URL + 'guilds');
};

const listDiscussions = async topic => {
  const path = topic ? 'discussions?topic=' + topic : 'discussions';
  return await connection.get(API_BASE_URL + path);
};

const getDiscussion = async slug => {
  return await connection.get(API_BASE_URL + 'discussions/' + slug);
};

const postDiscussion = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'discussions',
    { discussion: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getComments = async (contentType, objectId) => {
  const response = await connection.get(
    API_BASE_URL +
      'comments?content_type=' +
      contentType +
      '&object_id=' +
      objectId
  );
  return response.data;
};

const submitComment = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(API_BASE_URL + 'comments', payload, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const submitReply = async payload => {
  const token = AuthService.getAuthToken();
  return await connection.post(API_BASE_URL + 'replies', payload, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const upvoteDiscussion = async slug => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'discussions/' + slug + '/vote',
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const upvoteComment = async commentId => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'comments/' + commentId + '/vote',
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const upvoteReply = async replyId => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + 'replies/' + replyId + '/vote',
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getConverstions = async () => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + 'conversations', {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const getMessages = async conversationId => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(
    API_BASE_URL + 'conversations/' + conversationId + '/messages',
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

export async function sendMessage(payload) {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + 'messages',
    {
      message: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
}

export default {
  listDiscussions,
  getDiscussion,
  submitComment,
  submitReply,
  upvoteComment,
  upvoteDiscussion,
  upvoteReply,
  postDiscussion,
  listGuilds,
  getComments,
  getConverstions,
  getMessages,
  sendMessage,
};
