import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Text,
  Box,
  HStack,
  Skeleton,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import ChallengesGrid from '../components/ChallengesGrid.js';
import TrackService from '../services/track-service.js';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import { PRIMARY_FILTER_ALL } from '../constants/index.js';
import withRouter from '../utils/withRouter.js';
import authService from '../services/auth-service.js';
import {
  FolderOpenIcon,
  MagnifyingGlassCircleIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline';
import submissionsService from '../services/submissions-service.js';
import { ChevronRightIcon } from '@chakra-ui/icons';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';
import TrackLabel from '../components/Track/TrackLabel.js';
import PrimaryButtonFilter from '../components/Track/PrimaryButtonFilter.jsx';
import splitbee from '@splitbee/web';

const Projects = () => {
  Mixpanel.track('Viewed Projects Page');
  splitbee.track('Viewed Projects Page');
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const currentUser = JSON.parse(authService.getCurrentUser());
  const [primaryFilter, setPrimaryFilter] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [tracks, setTracks] = useState([]);

  const handleFilterChange = async filterValue => {
    setPrimaryFilter(filterValue);
    setIsProjectsLoading(true);
    if (filterValue === null) {
      const response = await TrackService.fetchAllTracks();
      setTracks(response.tracks);
    } else {
      const response = await TrackService.fetchAllChallenges(filterValue);
      setProjects(response.challenges);
      Mixpanel.track('Changed Challenges Filter', {
        filter: filterValue,
      });
    }
    setIsProjectsLoading(false);
  };

  useEffect(() => {
    async function fetchSubmissions() {
      let defaultFilter = null;
      if (currentUser) {
        setIsLoading(true);
        const response = await submissionsService.listSubmissions(
          currentUser.username
        );
        setSubmissions(response.submissions);
        defaultFilter = response.submissions[0]?.challenge.track_slug;
        setPrimaryFilter(defaultFilter);
      }
      await handleFilterChange(defaultFilter);
      setIsLoading(false);
    }
    fetchSubmissions();
  }, [currentUser?.username]);

  return (
    <>
      <SEO
        title="Hands-on projects to grow your skills | Techstarta"
        url="http://techstarta.com/projects"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white overflow-hidden">
        <DashboardNavbar title="Projects" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="md:px-10 mx-auto w-full pt-8 overflow-hidden">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    w="auto"
                    flexDirection={['column', 'column', 'row', 'row']}
                    mx={['4', '4']}
                    mb="10px"
                    rounded="lg"
                    justifyContent={['center', 'center', 'space-between']}
                  >
                    <Flex
                      flexDirection={'column'}
                      verticalAlign={'center'}
                      width={['100%', '100%', '100%', '70%']}
                    >
                      <h1 className="text-3xl pb-4 font-semibold text-gray-800">
                        Hands-on projects to grow your skills
                      </h1>
                      <h2 className="text-lg pb-4 font-medium text-gray-600">
                        Grow your skills by solving real-world problems. Join
                        our community to discuss projects, review solutions, and
                        get feedback from mentors and peers.
                      </h2>
                    </Flex>
                    <Flex
                      justifyContent={['flex-start', 'flex-start', 'flex-end']}
                      width={['100%', '100%', '100%', '30%']}
                    >
                      <Box pb={'4'}>
                        <PuzzlePieceIcon className="h-28 w-28 text-orange-500" />
                      </Box>
                    </Flex>
                  </Flex>
                  {/* My Challenges */}
                  {currentUser && submissions.length > 0 && (
                    <Skeleton mx="4" isLoaded={!isLoading} rounded={'lg'}>
                      <Flex py={8} flexDirection={'column'}>
                        <HStack pb={6}>
                          <FolderOpenIcon className="h-6 w-6 text-orange-500" />
                          <Heading
                            color="#2d3748"
                            size="md"
                            justifyContent="space-between"
                          >
                            My Projects
                          </Heading>
                        </HStack>

                        {submissions.length > 0 && (
                          <SimpleGrid columns={[1, 2, 2, 2]} spacing={4}>
                            {submissions.map(submission => (
                              <LinkBox
                                w="100%"
                                key={submission.id}
                                justifyContent={'space-between'}
                                alignItems="center"
                              >
                                <Flex
                                  key={submission.id}
                                  justifyContent={'space-between'}
                                  alignItems="center"
                                  p="10px"
                                  mt="10px"
                                  rounded="lg"
                                  cursor={'pointer'}
                                  border={'1px'}
                                  borderColor={'gray.200'}
                                  _hover={{
                                    shadow: 'md',
                                  }}
                                >
                                  <Flex alignItems="center">
                                    <Flex
                                      flexDirection="column"
                                      ml="10px"
                                      justifyContent="center"
                                    >
                                      <LinkOverlay
                                        href={`/projects/${submission.challenge.slug}`}
                                      >
                                        <Text
                                          fontSize="sm"
                                          fontWeight="bold"
                                          color="#2d3748"
                                        >
                                          {submission.challenge.title}
                                        </Text>
                                      </LinkOverlay>
                                      <HStack pt={2} spacing={2}>
                                        <TrackLabel
                                          track={submission.challenge.track}
                                        />
                                        <SubmissionStatus
                                          status={submission.status}
                                        />
                                      </HStack>
                                    </Flex>
                                  </Flex>

                                  <Flex alignItems={'center'}>
                                    <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                  </Flex>
                                </Flex>
                              </LinkBox>
                            ))}
                          </SimpleGrid>
                        )}
                      </Flex>
                    </Skeleton>
                  )}
                  <Flex mx="4" mt="8" flexDirection="column">
                    <HStack alignItems="center">
                      <MagnifyingGlassCircleIcon className="h-6 w-6 text-orange-500" />
                      <Heading
                        color="#2d3748"
                        size="md"
                        as={'h2'}
                        justifyContent="space-between"
                      >
                        Browse Projects
                      </Heading>
                    </HStack>
                    <Flex
                      pt={4}
                      h="12"
                      justifyContent={['flex-start', 'space-between']}
                      overflowX="scroll"
                    >
                      <HStack spacing={2}>
                        <PrimaryButtonFilter
                          children={'All'}
                          value={PRIMARY_FILTER_ALL}
                          isActive={primaryFilter === null}
                          onClick={() => handleFilterChange(null)}
                        />
                        <PrimaryButtonFilter
                          children={'Backend Development'}
                          value={'backend-development'}
                          isActive={primaryFilter === 'backend-development'}
                          onClick={() =>
                            handleFilterChange('backend-development')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'Frontend Development'}
                          value={'frontend-development'}
                          isActive={primaryFilter === 'frontend-development'}
                          onClick={() =>
                            handleFilterChange('frontend-development')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'Product Management'}
                          value={'product-management'}
                          isActive={primaryFilter === 'product-management'}
                          onClick={() =>
                            handleFilterChange('product-management')
                          }
                        />
                        <PrimaryButtonFilter
                          children={'UI/UX Design'}
                          value={'User Interface and Experience Design'}
                          isActive={primaryFilter === 'ui-ux-design'}
                          onClick={() => handleFilterChange('ui-ux-design')}
                        />
                      </HStack>
                    </Flex>
                    {primaryFilter === null ? (
                      <div className="mt-4">
                        {tracks.map(
                          (track, index) =>
                            track.featured_challenges.length >= 3 && (
                              <div key={index} className="mt-10">
                                <div className="flex justify-between items-center">
                                  <h2 className="text-xl font-semibold text-gray-700">
                                    {track.title} Projects
                                  </h2>
                                  <a
                                    className="text-gray-600 hover:text-orange-700 hover:underline inline-flex font-medium items-center"
                                    href={`/skills/${track.slug}?ref=projects`}
                                  >
                                    Explore more
                                    <ChevronRightIcon className="h-4 w-4 ml-2" />
                                  </a>
                                </div>
                                <ChallengesGrid
                                  projects={track.featured_challenges}
                                  isLoading={isProjectsLoading}
                                />
                              </div>
                            )
                        )}
                      </div>
                    ) : (
                      <ChallengesGrid
                        projects={projects}
                        isLoading={isProjectsLoading}
                      />
                    )}
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Projects);
