import React from 'react';
import { SimpleGrid, Box, Text, Flex } from '@chakra-ui/react';
import { ChallengeCard, ChallengeCardSkeleton } from './ChallengeCard';

const ChallengesGrid = ({ isLoading, projects }) => {
  return (
    <div>
      <SimpleGrid
        columns={[1, 2, 2, 3]}
        justifyContent="space-evenly"
        spacing="6"
        mb="20px"
        w="100%"
      >
        {isLoading && (
          <>
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
          </>
        )}
      </SimpleGrid>

      {!isLoading && projects && (
        <SimpleGrid
          columns={[1, 2, 2, 3]}
          justifyContent="space-evenly"
          spacing="8"
          w="100%"
        >
          {projects.map((challenge, index) => (
            <ChallengeCard challengeDetails={challenge} key={index} />
          ))}
        </SimpleGrid>
      )}

      {!isLoading && !projects && (
        <Box mx={4} borderRadius="5px" p="20px" w="100%">
          <Text fontWeight="semibold" color="#718096">
            We couldn't find any projects that match your search.
          </Text>
        </Box>
      )}
    </div>
  );
};

export default ChallengesGrid;
