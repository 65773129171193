import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

export async function submitMentorApplication(payload) {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + 'mentors',
    { mentor: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
}

const getMentorList = async (tracks = null) => {
  if (tracks) {
    return await connection.get(
      API_BASE_URL + `mentors?tracks=${tracks.join(',')}`
    );
  }
  return await connection.get(API_BASE_URL + 'mentors');
};

const getMentor = async (username, noAuth) => {
  return await connection.get(API_BASE_URL + `mentors/${username}`);
};

const updateMentorProfile = async (username, payload) => {
  const token = AuthService.getAuthToken();
  return await connection.put(
    API_BASE_URL + `mentors/${username}`,
    { mentor: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getMentorships = async () => {
  const token = AuthService.getAuthToken();

  const response = await connection.get(API_BASE_URL + `mentorships`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const getMentorshipRequests = async () => {
  const token = AuthService.getAuthToken();

  const response = await connection.get(
    API_BASE_URL + `mentor/mentorship-requests`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const getMentorshipApplications = async () => {
  const token = AuthService.getAuthToken();

  const response = await connection.get(API_BASE_URL + `mentee/applications`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const updateMentorshipRequest = async (id, action) => {
  const token = AuthService.getAuthToken();
  return await connection.patch(
    API_BASE_URL + `mentorships/${id}?action=${action}`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getMentorshipDetail = async id => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(API_BASE_URL + `mentorships/${id}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const createGoal = async payload => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `goals`,
    {
      goal: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const updateGoal = async (id, payload) => {
  const token = AuthService.getAuthToken();
  const response = await connection.put(
    API_BASE_URL + `goals/${id}`,
    {
      goal: payload,
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const sendMentorshipRequest = async payload => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + 'mentorship_requests',
    payload,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const deleteGoal = async (id, goalId) => {
  const token = AuthService.getAuthToken();
  return await connection.delete(API_BASE_URL + `goals/${goalId}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

export default {
  submitMentorApplication: submitMentorApplication,
  getMentorList,
  getMentor,
  updateMentorProfile,
  getMentorships,
  updateMentorshipRequest,
  getMentorshipApplications,
  getMentorshipRequests,
  getMentorshipDetail,
  createGoal,
  updateGoal,
  deleteGoal,
};
