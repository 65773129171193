import React, { useState } from 'react';
import AuthService from '../services/auth-service';
import { Link } from 'react-router-dom';
import {
  useToast,
  Box,
  Flex,
  Spinner,
  Text,
  Input,
  Button,
  HStack,
} from '@chakra-ui/react';

import { EnvelopePaperEmail } from '@vectopus/atlas-icons-react';
import { Formik } from 'formik';
import { BeatLoader } from 'react-spinners';
import authService from '../services/auth-service';

const AccountVerficationRequired = ({ user }) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const toast = useToast();

  const { email } = user;
  const sendEmailVerificationRequest = async event => {
    event.preventDefault();
    const payload = {
      email: email,
    };
    try {
      setLoading(true);
      const response = await AuthService.createEmailVerification(payload);
      toast({
        title: 'Email sent',
        description: 'Please check your email for your verification code.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleVerifyAccount = async values => {
    const payload = {
      verification_code: values.code,
    };
    try {
      const response = await AuthService.confirmEmailVerification(payload);
      if (response.status === 200) {
        toast({
          title: 'Account verified',
          description: 'You can now login to your account.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setTimeout(() => {
          authService.logout();
          window.location.href = '/login';
        }, 2000);
      }
    } catch (err) {
      toast({
        title: 'Account verification failed',
        description: 'Please check your verification code and try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="h-screen">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full md:w-8/12 px-4">
          <>
            {isLoading && (
              <Flex pt="10px" w="100%" h="100%" align="center" justify="center">
                <Box
                  rounded="lg"
                  w={['80%', '80%', '80%', '70%']}
                  h="50%"
                  bg="white"
                >
                  <Flex p="20px" align="center" justify="center">
                    <Spinner
                      size="xl"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="orange.500"
                    />
                  </Flex>
                  <Text textAlign="center" color="gray" p="10px" fontSize="sm">
                    {' '}
                    Sending your verification code to your email...{' '}
                  </Text>
                </Box>
              </Flex>
            )}
            {!isLoading && (
              <Box
                rounded="md"
                bg="white"
                p="20px"
                boxShadow="lg"
                textAlign={'center'}
              >
                <Flex
                  flexDirection={'column'}
                  p="20px"
                  align="center"
                  justify="center"
                >
                  <EnvelopePaperEmail className="text-orange-500 h-12 w-12" />
                  <Text
                    mt="4"
                    className="text-xl md:text-2xl text-gray-800 font-bold"
                  >
                    Verify your account
                  </Text>
                  <Text className="font-sm" mt="2">
                    Thank you for joining Techstarta. We've sent an email to
                    your email address.
                  </Text>
                  <Text className="font-sm" mt="2">
                    Please enter the verification code below to continue.
                  </Text>
                  <Formik
                    initialValues={{
                      code: '',
                    }}
                    onSubmit={async values => {
                      await handleVerifyAccount(values);
                    }}
                  >
                    {({
                      values,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      errors,
                      isSubmitting,
                    }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <HStack py={4}>
                          <Input
                            id="code"
                            focusBorderColor="gray.600"
                            type="text"
                            width={'36'}
                            name="code"
                            maxLength={20}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ transition: 'all .15s ease' }}
                            required
                          />
                          <Button
                            _hover={{ bg: 'gray.800' }}
                            bg={'gray.600'}
                            textColor={'white'}
                            width={'20'}
                            disabled={isSubmitting}
                            ringColor="gray.600"
                            type="submit"
                            spinner={<BeatLoader size={8} color="white" />}
                          >
                            Verify
                          </Button>
                        </HStack>
                        <Text fontSize="sm" mt="1" textColor="red.500">
                          {errors.code && touched.code ? errors.code : null}
                        </Text>
                      </form>
                    )}
                  </Formik>

                  <Text mt="5" mb="2">
                    Did not receive?{' '}
                    <span className="underline text-orange-500">
                      <Link to={'#'} onClick={sendEmailVerificationRequest}>
                        Resend verification code.
                      </Link>
                    </span>
                  </Text>
                </Flex>
              </Box>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default AccountVerficationRequired;
