import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Stack,
  Button,
  Heading,
  Text,
  Box,
  Image,
  Container,
  Center,
  Link,
  Wrap,
  WrapItem,
  SimpleGrid,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import { motion } from 'framer-motion';
import Footer from '../components/Footer.js';
import AuthService from '../services/auth-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { FaArrowRight } from 'react-icons/fa';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import projectsPage from '../images/projects-landing-page.png';
import communityImage from '../images/community-image.png';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Navbar from '../components/Navbar.js';
import Testimonials from '../components/Testimonials.jsx';
import {
  SparklesIcon,
  ServerStackIcon,
  SquaresPlusIcon,
  TvIcon,
  ChartPieIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon,
  LightBulbIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import mentors from '../data/mentors.json';
import { MentorItem } from '../components/MentorItem.js';
import Feature from '../components/Feature.js';
import {
  ChatsLines,
  GroupPeopleArrowUp,
  PuzzlePiece,
  StarBookmark,
  ThumbsUpLikeStar,
} from '@vectopus/atlas-icons-react';

const Landing = () => {
  Mixpanel.track('Landing Page Viewed');
  splitbee.track('Landing Page');

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);

  const careerTracks = [
    {
      name: 'Backend Development',
      description: 'Build behind the scenes of the web',
      href: '/skills/backend-development',
      icon: ServerStackIcon,
    },
    {
      name: 'Frontend Development',
      description: 'Build what you see on the web',
      href: '/skills/frontend-development',
      icon: TvIcon,
    },
    {
      name: 'Product Design',
      description: 'Design the look and feel of digital products',
      href: '/skills/ui-ux-design',
      icon: SparklesIcon,
    },
    {
      name: 'Product Management',
      description: 'Manage the lifecyle of digital products',
      href: '/skills/product-management',
      icon: SquaresPlusIcon,
    },
    {
      name: 'Data Analytics',
      description: 'Analyze data to make better decisions',
      href: '/skills/data-analytics',
      icon: ChartPieIcon,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    autoPlay: true,
    autoPlaySpeed: 10,
    speed: 500,
    padding: '0 20px',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(JSON.parse(user));
  }, []);

  const MotionHeading = motion(Heading);

  return (
    <>
      <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
      <Container
        px={['2', '20', '20', '20']}
        pt={['10', '20', '20', '20']}
        pb={['10', '20', '20', '20']}
        className="bg-no-repeat bg-cover bg-center overflow-x-hidden"
        maxW={'100%'}
      >
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#f97316] to-[#67e8f9] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          ></div>
        </div>
        <Flex
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 20 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Center textAlign={'center'} flexDirection={'column'} flex={1}>
            <MotionHeading
              as="h1"
              initial={{ opacity: 0.89 }}
              animate={{ opacity: 1 }}
              lineHeight={1.1}
              fontWeight={'bold'}
              width={['100%', '100%', '100%', '80%']}
              fontSize={{ base: '6xl', sm: '6xl', lg: '75' }}
              pb={6}
            >
              Level Up your tech career with{' '}
              <Text
                as={'span'}
                className="text-orange-500"
                position={'relative'}
              >
                real-world experience.
              </Text>
            </MotionHeading>
            <Text
              width={['100%', '100%', '100%', '70%']}
              align={'center'}
              pb={6}
              className="text-slate-800"
              as={'h2'}
              fontSize={['md', 'md', 'md', '2xl']}
            >
              Techstarta helps you improve your tech skills with real-world
              projects curated by industry experts. Start your first project and
              get pro insights on how to get better.
            </Text>
            {currentUser ? (
              <Stack
                spacing={{ base: 4, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
                alignItems={'center'}
              >
                <a
                  href="/home"
                  type="button"
                  className="bg-orange-600 rounded-full hover:shadow-md text-white text-lg font-semibold hover:text-gray-50 py-3 px-6 border border-orange-600 "
                >
                  {`Continue as ${currentUser.firstname}`}{' '}
                  <UserCircleIcon className="w-5 h-5 ml-2 inline-flex" />
                </a>
                <Link fontSize={'md'} href="/logout">
                  Login in as a different user
                </Link>
              </Stack>
            ) : (
              <Stack
                alignItems={'center'}
                justifyItems={'center'}
                spacing={{ base: 4, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
              >
                <a
                  href="/get-started"
                  type="button"
                  className="bg-[#152340] rounded-full hover:shadow-md text-white text-lg font-semibold hover:text-gray-50 py-3 px-6 border border-orange-600 "
                >
                  Get started now
                </a>
                <a
                  href="/projects"
                  type="button"
                  className="bg-white rounded-full hover:shadow-md text-gray-800 text-lg font-semibold hover:text-gray-600 py-3 px-6 border border-gray-400"
                >
                  Explore projects{' '}
                  <ArrowRightIcon className="w-4 h-4 ml-2 inline-flex" />
                </a>
              </Stack>
            )}
          </Center>
        </Flex>
        <Center width="100%">
          <p className="text-sm text-gray-600 text-center uppercase tracking-wider font-semibold">
            Join experts from top tech companies
          </p>
        </Center>
        <Center
          width={'100%'}
          pt="4"
          pb={4}
          alignItems="center"
          justifyItems={'center'}
        >
          <Wrap
            spacing="80px"
            width="100%"
            py={4}
            justify={'center'}
            align={'center'}
          >
            <WrapItem>
              <Image
                alt="GetYourGuide"
                height="40px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621080284/grayscale/image_wvguy5.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Delivery Hero"
                height="40px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621085669/grayscale/image_8_urb8y9.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Lieferando"
                height="40px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621085083/grayscale/image_7_xsojxd.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Cowrywise"
                height="40px"
                width="auto"
                src={'//logo.clearbit.com/cowrywise.com?size=80&greyscale=true'}
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="YellowCard"
                height="40px"
                width="auto"
                src={'//logo.clearbit.com/yellowcard.io?size=80&greyscale=true'}
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Sennder"
                height="40px"
                width="auto"
                src={'//logo.clearbit.com/sennder.com?size=80&greyscale=true'}
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="S&P Global"
                height="40px"
                width="auto"
                src={'//logo.clearbit.com/spglobal.com?size=128&greyscale=true'}
              ></Image>
            </WrapItem>
          </Wrap>
        </Center>
      </Container>

      <section className="relative py-10 bg-[#152340]">
        <div className="container mx-auto px-4">
          <Center pb={12} flexDirection="column">
            <Text className="uppercase text-xs md:text-sm font-semibold text-orange-500 tracking-wide py-2">
              Why Techstarta?
            </Text>
            <Heading
              as={'h2'}
              width={['100%', '80%', '80%', '60%']}
              className="text-white text-2xl text-center"
            >
              Crafted by industry experts
              <span className="text-gray-400">
                {' '}
                for techies who want to gain real-world experience
              </span>
            </Heading>
          </Center>
          <div className="flex flex-wrap">
            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div className="px-6 text-center">
                <div className="text-stone-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <BriefcaseIcon className="w-6 h-6" />
                </div>
              </div>
              <div className="pt-6 text-center">
                <h5 className="text-xl text-white font-bold">
                  Real-world projects
                </h5>
                <p className="text-gray-300">
                  Our projects help you gain real-world experience and build a
                  portfolio that showcases your skills to potential employers.
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div className="px-6 text-center">
                <div className="text-cyan-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <ChatBubbleLeftRightIcon className="w-6 h-6" />
                </div>
              </div>
              <div className="pt-6 text-center">
                <h5 className="text-xl font-bold text-white">
                  Community-driven
                </h5>
                <p className="text-gray-300">
                  Leverage the power of community to grow your skills and get
                  help when you are stuck.
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
              <div className="px-6 text-center">
                <div className="text-orange-700 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <LightBulbIcon className="w-6 h-6" />
                </div>
              </div>
              <div className="pt-6 text-center">
                <h5 className="text-xl font-bold text-white">
                  Expert Feedback
                </h5>
                <p className="text-gray-300">
                  Get well-structured feedback on your projects from industry
                  experts. Discover specific areas to improve and grow your
                  skills.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Box
        px={['4', '10', '10', '10']}
        alignItems={'center'}
        className="text-gray-700"
        py="20"
      >
        <Center pb={12} flexDirection="column">
          <Heading as="h2" className="text-center">
            How it works
          </Heading>
        </Center>
        <SimpleGrid px={8} columns={{ base: 1, md: 4 }} spacing={10} pb={12}>
          <Feature
            icon={<RocketLaunchIcon className="text-orange-500 h-8 w-8" />}
            title="Find and start a project"
            text="Find a project that aligns with your career goals & level of expertise and start working on it."
            textColor="text-gray-700"
          />

          <Feature
            icon={<LightBulbIcon className="text-orange-500 h-8 w-8" />}
            title={'Access project guides and resources'}
            text="Access curated resources and tools to help you complete your project."
            textColor="text-gray-700"
          />
          <Feature
            icon={
              <ChatBubbleOvalLeftEllipsisIcon className="text-orange-500 h-8 w-8" />
            }
            title={'Get help from the community'}
            text="Get help from the community and mentors when you are stuck."
            textColor="text-gray-700"
          />
          <Feature
            icon={<ThumbsUpLikeStar className="text-orange-500 h-8 w-8" />}
            title={'Submit your project and get expert feedback'}
            text="Get feedback on your projects from the community, grow your skills and build your portfolio."
            textColor="text-gray-700"
          />
        </SimpleGrid>
        <Center pt={4}>
          <a
            type="button"
            href="/get-started"
            className="bg-orange-600 rounded-full hover:shadow-md text-white text-lg font-semibold hover:text-gray-50 py-3 px-6 border border-orange-600 "
          >
            Sign up to get started
            <ChevronRightIcon className="w-4 h-4 ml-2 inline-flex" />
          </a>
        </Center>
      </Box>
      <Box
        style={{ background: 'rgb(251, 251, 249)' }}
        width="full"
        px={8}
        py="16"
        rounded={'xl'}
      >
        <Center pb={12} flexDirection="column">
          <Text className="uppercase text-sm font-semibold text-orange-500 tracking-wide py-2">
            ⚡️ 10X your career growth
          </Text>
          <h4
            className="text-3xl md:text-4xl text-gray-800 font-bold leading-tight tracking-tight text-center mb-4
          "
          >
            Go from beginner to expert
          </h4>
          <Text
            width={['100%', '80%', '80%', '50%']}
            className="text-gray-500 text-lg font-medium text-center"
          >
            Leverage our curated resources and hands-on projects <br />
            to grow your skills and build your portfolio.
          </Text>
        </Center>
        <Wrap spacing="8" width="100%" py={4} justify={'center'}>
          {careerTracks.map(item => (
            <WrapItem key={item.name}>
              <div className="group shadow-lg shadow-black/5 ring-1 ring-slate-700/10 hover:ring-orange-500 relative flex gap-x-6 rounded-lg p-4 bg-white">
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <item.icon
                    className="h-6 w-6 text-gray-600 group-hover:text-orange-500"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <a href={item.href} className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600 font-normal">
                    {item.description}
                  </p>
                </div>
              </div>
            </WrapItem>
          ))}
        </Wrap>
        <Center pt={4}>
          <Button
            className="text-xs font-semibold"
            onClick={() => navigate('/skills')}
            shadow="sm"
            size={'lg'}
            rounded="full"
            _hover={{ bg: 'brand.darkBlue', shadow: 'md' }}
            variant="solid"
            colorScheme="white"
            bgColor="brand.darkBlue"
            rightIcon={<FaArrowRight />}
          >
            Explore Roadmaps
          </Button>
        </Center>
      </Box>
      <section className="relative py-10 rounded-t-xl">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
              <img
                alt="Hands-on projects"
                className="max-w-full rounded-lg shadow-lg ring-1 ring-slate-700/10"
                src={projectsPage}
              />
            </div>
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold ">
                  Improve your problem-solving skills
                </h3>
                <p className="mt-4 text-md leading-relaxed text-gray-600 text-lg font-medium">
                  <a
                    className="underline "
                    href="https://cpduk.co.uk/news/what-is-the-learning-pyramid"
                  >
                    Research
                  </a>{' '}
                  shows that learning by doing has a 75% retention rate. Our
                  hands-on projects are designed to help you grow your skills
                  and build your portfolio.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="items-center flex">
                      <div className="flex">
                        <PuzzlePiece className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Put your skills to action
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div className="flex">
                        <ChatBubbleOvalLeftEllipsisIcon className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Get an industry-expert feedback
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div className="flex">
                        <CheckBadgeIcon className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">Build your portfolio</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-10 bg-white">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div className=" relative md:pr-12">
                <h3 className="text-3xl font-semibold">
                  Pick the brain of industry experts
                </h3>
                <p className="mt-4 text-md leading-relaxed text-gray-600 text-lg font-medium">
                  Get personalized guidance, feedback and grow your skills.
                  Discover best practices and learn form the best.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div className="flex">
                        <ChatsLines className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Get an industry expert feedback on your projects and
                          skills
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div className="flex">
                        <GroupPeopleArrowUp className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Leverage small group sessions to learn from peers and
                          mentors
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div className="flex">
                        <StarBookmark className="w-5 h-5 mr-2 text-orange-500" />
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Get exclusive access to job opportunities and
                          referrals
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative w-full md:w-6/12 ml-auto mr-auto px-4">
              <img
                alt="Hands-on projects"
                className="max-w-full rounded-lg shadow-lg ring-1 ring-slate-700/10"
                src={communityImage}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="relative py-10 bg-[#152340]">
        <Center pb={12} flexDirection="column">
          <Heading as={'h2'} className="text-white text-2xl text-center">
            <span className="text-orange-500">Learn from the best</span> in the
            industry
          </Heading>
          <Text
            align={'center'}
            className="font-[500] text-gray-200 md:text-lg text-md px-2"
            width={['100%', '50%']}
          >
            Our mentors are industry experts from top tech companies. <br />{' '}
            Gain the insights you need to grow your career.
          </Text>
        </Center>
        <div className="mx-auto px-8 py-4 overflow-x-hidden overflow-hidden items-center">
          <Slider {...settings}>
            {mentors.map(mentor => (
              <div key={mentor.user_id} className="p-2">
                <MentorItem mentor={mentor} showBio={true} showSkills={false} />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <Box
        style={{ background: 'rgb(251, 251, 249)' }}
        width="full"
        px={['2', '20', '20', '20']}
        py="12"
      >
        <Testimonials />
      </Box>

      <Container
        py={8}
        maxW={'100%'}
        px={['2', '20', '20', '20']}
        className="bg-no-repeat bg-cover bg-center overflow-x-hidden"
      >
        <div className="flex flex-col py-20 shadow-xl shadow-black/5 ring-1 ring-slate-700/10 bg-gradient-to-r from-orange-400 to-orange-500 items-center align-middle justify-center gap-4 rounded-lg">
          <h2 className="text-3xl font-bold tracking-tight sm:text-5xl">
            Ready to <span className="text-white">Level Up</span>?
          </h2>
          <div className="flex justify-center w-full sm:w-5/12" pt={2}>
            <p className="text-white text-lg leading-6 text-center font-medium">
              Get out of the tutorial hell and start working on hands-on
              projects that align with your career goals.
            </p>
          </div>
          <Stack
            alignItems={'center'}
            justifyItems={'center'}
            spacing={{ base: 4, sm: 4 }}
            direction={{ base: 'column', sm: 'row' }}
          >
            <Button
              onClick={() => navigate('/get-started')}
              shadow="sm"
              _hover={{ shadow: 'md' }}
              rounded="full"
              size={'lg'}
              variant="solid"
              colorScheme="white"
              bgColor="brand.darkBlue"
              rightIcon={<ArrowRightIcon className="w-4 h-4" />}
            >
              Get started now
            </Button>
            <Button
              onClick={() => navigate('/quiz')}
              bgColor="white"
              shadow="sm"
              size={'lg'}
              rounded="full"
              variant="outline"
              colorScheme="gray"
              textColor={'gray.700'}
            >
              Take career quiz
            </Button>
          </Stack>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default withRouter(Landing);
