import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LogSnagProvider } from '@logsnag/react';

import Login from './pages/Login';
import Register from './pages/Register';
import Skills from './pages/Skills';
import Mentors from './pages/Mentors';
import Mentor from './pages/Mentor';
import AuthGate from './components/AuthGate';
import AlreadyAuthorized from './components/AlreadyAuthorized';
import Challenge from './pages/Challenge';
import SubmissionDetail from './pages/SubmissionDetail';
import Landing from './pages/Landing';
import Onboarding from './pages/Onboarding';
import LearnerOnboarding from './pages/LearnerOnboarding';
import AccountVerficationRequired from './pages/AccountVerificationRequired';
import MentorOnboarding from './pages/MentorOnboarding';
import SkillDetail from './pages/SkillDetail';
import ProfileSwitchHandler from './pages/ProfileSwitchHandler';
import Logout from './pages/Logout';
import Projects from './pages/Projects';
import EditChallengeSubmission from './pages/EditChallengeSubmission';
import Employers from './pages/Employers';
import Submissions from './pages/Submissions';

const theme = extendTheme({
  colors: {
    brand: {
      darkBlue: '#152340',
      lightBlue: '#51def5',
      orange: '#da8f67',
      gray: '#2d3748',
      darkOrange: '#2d3748',
    },
  },
  fonts: {
    heading:
      "'Avenir', Arial, Helvetica,sans-serif, 'Open Sans',Baskerville,'Times New Roman','Helvetica Neue'",
    body: '"Avenir", sans-serif',
  },
  components: {
    Steps,
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#152340',
        },
      },
    },
  },
});

const queryClient = new QueryClient();
const renderLoader = () => <p>Loading...</p>;

const Jobs = lazy(() => import('./pages/Jobs'));
const Badges = lazy(() => import('./pages/Badges'));
const Bounties = lazy(() => import('./pages/Bounties'));
const Mentorships = lazy(() => import('./pages/Mentorships'));
const MentorshipDetail = lazy(() => import('./pages/MentorshipDetail'));
const Pathfinder = lazy(() => import('./pages/Pathfinder'));
const Notifications = lazy(() => import('./pages/Notifications'));
const ContributeProject = lazy(() => import('./pages/ContributeProject'));
const Perks = lazy(() => import('./pages/Perks'));
const Messages = lazy(() => import('./pages/Messages'));
const EditBounty = lazy(() => import('./pages/EditBounty'));
const CheckoutSuccess = lazy(() => import('./pages/CheckoutSuccess'));
const CheckoutCancelled = lazy(() => import('./pages/CheckoutCancelled'));
const Profile = lazy(() => import('./pages/Profile'));
const PageNotFound = lazy(() => import('./pages/404'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const MentorsDashboard = lazy(() => import('./pages/MentorsDashboard'));
const EditUserProfile = lazy(() => import('./pages/EditUserProfile'));
const SetNewPassword = lazy(() => import('./pages/SetNewPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Bounty = lazy(() => import('./pages/Bounty'));
const CreateBounty = lazy(() => import('./pages/CreateBounty'));
const Discussions = lazy(() => import('./pages/Discussions'));
const DiscussionDetail = lazy(() => import('./pages/DiscussionDetail'));
const Stories = lazy(() => import('./pages/Stories'));
const StoryDetail = lazy(() => import('./pages/StoryDetail'));
const MentorLanding = lazy(() => import('./pages/MentorLanding'));
const Membership = lazy(() => import('./pages/Membership'));

const ThemedApp = () => (
  <LogSnagProvider
    token="7541851604a57c8cfc20ffdca7a9cd2f"
    project="techstarta"
  >
    <GoogleOAuthProvider clientId="198094606033-5set0i1gu8iu584k96e0e9lvm373v8io.apps.googleusercontent.com">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route
                exact
                path="/login"
                element={<AlreadyAuthorized Component={Login} />}
              />
              <Route
                exact
                path="/get-started"
                element={<AlreadyAuthorized Component={Register} />}
              />
              <Route
                exact
                path="/forgot-password"
                element={<AlreadyAuthorized Component={ForgotPassword} />}
              />
              <Route exact path="/set-password" element={<SetNewPassword />} />
              <Route
                exact
                path={'/home'}
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={Dashboard} />
                  </Suspense>
                }
              />
              <Route
                exact
                path={'/skills/:trackId/lessons'}
                element={<SkillDetail />}
              />
              <Route
                exact
                path={'/skills/:trackId'}
                element={<SkillDetail />}
              />
              <Route exact path={'/skills'} element={<Skills />} />
              <Route exaact path="/employers" element={<Employers />} />
              <Route
                exact
                path="/community"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Discussions />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/posts"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Discussions />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/posts/:slug"
                element={
                  <Suspense fallback={renderLoader()}>
                    <DiscussionDetail />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/stories"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Stories />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/stories/:slug"
                element={
                  <Suspense fallback={renderLoader()}>
                    <StoryDetail />
                  </Suspense>
                }
              />
              <Route exact path="/projects" element={<Projects />} />
              <Route
                exact
                path="/jobs"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Jobs />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/projects/contribute"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={ContributeProject} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/projects/:challengeId"
                element={<Challenge />}
              />
              <Route
                exact
                path="/challenges/:challengeId/submit"
                element={<EditChallengeSubmission />}
              />
              <Route exact path={'/mentors'} element={<Mentors />} />
              <Route exact path={'/mentor/:username'} element={<Mentor />} />
              <Route
                exaact
                path="/learning-paths"
                element={<Navigate replace to="/skills" />}
              />
              <Route
                exact
                path="/settings"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={EditUserProfile} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/notifications"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={Notifications} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/bounties"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Bounties />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/bounties/create"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={CreateBounty} />
                  </Suspense>
                }
              />
              <Route exact path="/bounties/:slug" element={<Bounty />} />
              <Route
                exact
                path="/bounties/:slug/edit"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={EditBounty} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/showcase/submissions"
                element={<AuthGate Component={Submissions} />}
              />
              <Route
                exact
                path="/showcase/submissions/:activeTab"
                element={<AuthGate Component={Submissions} />}
              />
              <Route
                exact
                path="/submissions/:submissionId"
                element={<AuthGate Component={SubmissionDetail} />}
              />
              <Route
                exact
                path="/verify_account"
                element={<AuthGate Component={AccountVerficationRequired} />}
              />
              <Route
                exact
                path="/onboarding"
                element={<AuthGate Component={Onboarding} />}
              />
              <Route
                exact
                path="/onboarding/learner"
                element={<AuthGate Component={LearnerOnboarding} />}
              />
              <Route
                exact
                path="/onboarding/mentor"
                element={<AuthGate Component={MentorOnboarding} />}
              />
              <Route
                exact
                path={'/profile/:username'}
                element={
                  <Suspense fallback={renderLoader()}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/quiz"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Pathfinder />
                  </Suspense>
                }
              />
              {/* <Route exact path="/about" element={<About />} /> */}

              <Route
                exact
                path="/perks"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Perks />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/membership"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Membership />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/messages"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Messages />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/mentor"
                element={
                  <Suspense fallback={renderLoader()}>
                    <MentorLanding />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/m/home"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={MentorsDashboard} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/mentorships"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={Mentorships} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/mentorships/:mentorshipId"
                element={<AuthGate Component={MentorshipDetail} />}
              />
              <Route
                exact
                path="/profiles/:profile/switch"
                element={<ProfileSwitchHandler />}
              />
              <Route exact path="/logout" element={<Logout />} />
              {/* Checkout */}
              <Route
                exact
                path="/checkout/success"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={CheckoutSuccess} />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/checkout/cancelled"
                element={
                  <Suspense fallback={renderLoader()}>
                    <AuthGate Component={CheckoutCancelled} />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/badges"
                element={
                  <Suspense fallback={renderLoader()}>
                    <Badges />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={renderLoader()}>
                    <PageNotFound />
                  </Suspense>
                }
              />
            </Routes>
          </BrowserRouter>
        </ChakraProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </LogSnagProvider>
);

export default ThemedApp;
