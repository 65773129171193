import React from 'react';

const TrackLabel = ({ track }) => {
  switch (track) {
    case 'Frontend Development':
      return (
        <span className="bg-blue-100 text-blue-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
          Frontend
        </span>
      );

    case 'Backend Development':
      return (
        <span className="bg-green-100 text-green-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
          Backend
        </span>
      );
    case 'Data Science':
      return (
        <span className="bg-red-100 text-red-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
          Data Science
        </span>
      );

    case 'Machine Learning':
      return (
        <span className="bg-yellow-100 text-yellow-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
          Machine Learning
        </span>
      );

    case 'Data Analytics':
      return (
        <span className="bg-blue-100 text-blue-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
          Data Analytics
        </span>
      );
    case 'User Interface and Experience Design':
      return (
        <span className="bg-purple-100 text-purple-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">
          UI/UX
        </span>
      );

    case 'Product Management':
      return (
        <span className="bg-orange-100 text-orange-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-orange-900 dark:text-orange-300">
          Product Mgmt
        </span>
      );

    case 'Product (UI/UX) Design':
      return (
        <span className="bg-purple-100 text-purple-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">
          Product Design
        </span>
      );

    case 'Technical Writing':
      return (
        <span className="bg-gray-100 text-gray-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
          Technical Writing
        </span>
      );

    default:
      return (
        <span className="bg-gray-100 text-gray-8000 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
          {track}
        </span>
      );
  }
};

export default TrackLabel;
