import React from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  LinkBox,
  Box,
  Text,
  LinkOverlay,
  HStack,
  Tag,
  Image,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';

export default function Resources({ resources }) {
  const extractDomain = url =>
    url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

  return (
    <Flex direction={'column'} boxShadow="sm" rounded={'lg'}>
      <VStack spacing={2} align="stretch">
        {resources.map((resource, index) => (
          <LinkBox h="auto" key={index}>
            <Flex
              border={'1px solid'}
              borderColor="gray.200"
              rounded="lg"
              justifyContent={['flex-start', 'space-between']}
              p={4}
              _hover={{ bgColor: 'gray.50' }}
            >
              <Box>
                <Image
                  src={`//logo.clearbit.com/${extractDomain(
                    resource.link
                  )}?size=128`}
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = `//logo.clearbit.com/techstarta.com?size=128`;
                  }}
                  alt={resource.title}
                  width={'40px'}
                  rounded={'lg'}
                  objectFit={'cover'}
                />
              </Box>
              <Box width={'70%'}>
                <LinkOverlay
                  href={resource.link}
                  _hover={{ color: 'orange.400' }}
                  isExternal
                >
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    textColor="gray.700"
                    noOfLines={1}
                  >
                    {resource.title}
                  </Text>
                  <Text noOfLines="1" fontSize="sm" textColor="gray.600">
                    {resource.summary}
                  </Text>
                </LinkOverlay>
              </Box>
              <HStack>
                <Tag
                  rounded="full"
                  bgColor="blue.50"
                  textColor="gray.600"
                  fontWeight="semibold"
                  textTransform="uppercase"
                  size="sm"
                >
                  {resource.resource_type}
                </Tag>
                <Tag
                  rounded="full"
                  bgColor="orange.50"
                  textColor="gray.600"
                  fontWeight="semibold"
                  textTransform="uppercase"
                  size="sm"
                >
                  {resource.cost_type}
                </Tag>
              </HStack>
              <HStack>
                <FontAwesomeIcon
                  className="text-gray-600"
                  icon={faExternalLinkSquare}
                ></FontAwesomeIcon>
              </HStack>
            </Flex>
          </LinkBox>
        ))}
      </VStack>
    </Flex>
  );
}
