import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { FingerPrintIcon } from '@heroicons/react/24/outline';
import { FireIcon } from '@heroicons/react/24/solid';

const AuthComponent = () => {
  return (
    <>
      <Flex direction={'row'}>
        <div className="hidden md:flex p-1 relative flex-wrap bg-white rounded-full">
          <a
            href="/login"
            className={
              'text-sm h-full block w-full whitespace-no-wrap rounded text-gray-700 font-semibold'
            }
          >
            <Button
              size={'md'}
              fontSize={'sm'}
              leftIcon={<FingerPrintIcon className="h-5 w-5 text-gray-600" />}
              variant={'outline'}
              rounded={'full'}
            >
              Login
            </Button>
          </a>
        </div>
        <div className="hidden md:flex p-1 relative flex-wrap bg-white rounded-full">
          <a
            href="/get-started"
            className={
              'text-sm h-full  block w-full whitespace-no-wrap rounded text-white font-semibold'
            }
          >
            <Button
              bgColor={'brand.darkBlue'}
              size={'md'}
              fontSize={'sm'}
              textColor={'white'}
              leftIcon={<FireIcon className="h-5 w-5 text-white" />}
              variant={'outline'}
              rounded={'full'}
              _hover={{ shadow: 'lg' }}
            >
              Get started free
            </Button>
          </a>
        </div>
      </Flex>
    </>
  );
};
export default AuthComponent;
