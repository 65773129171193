import React from 'react';
import { Text, Flex, HStack } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const InclusionItem = ({ label }) => (
  <Flex mb="2" alignItems="center" textColor={'gray.700'}>
    <HStack spacing={2}>
      <CheckCircleIcon className="w-5 h-5" />
      <Text fontSize="md">{label}</Text>
    </HStack>
  </Flex>
);

export default InclusionItem;
