import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

const AwardMedal = ({ grade }) => {
  switch (grade) {
    case 'Gold':
      return (
        <span className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-yellow-500 text-neutral-600 bg-white hover:bg-neutral-100 hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none">
          <FontAwesomeIcon icon={faMedal} className="text-yellow-500 w-4 h-4" />
        </span>
      );
    case 'Silver':
      return (
        <span className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-gray-400 text-neutral-600 bg-white hover:bg-neutral-100 hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none">
          <FontAwesomeIcon icon={faMedal} className="text-gray-400 w-4 h-4" />
        </span>
      );
    case 'Bronze':
      return (
        <span className="inline-flex items-center justify-center h-8 px-3 py-2 text-xs whitespace-nowrap font-medium border rounded-full transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed border-orange-400 text-neutral-600 bg-white hover:bg-neutral-100 hover:text-orange-500 active:bg-neutral-200 focus-visible:outline-brand-dark focus:ring-orange-500 flex-1 md:flex-none">
          <FontAwesomeIcon icon={faMedal} className="text-orange-400 w-4 h-4" />
        </span>
      );
    default:
      return null;
  }
};

export default AwardMedal;
