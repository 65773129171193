import { connection, API_BASE_URL } from './index';

const getProfile = async username => {
  return await connection.get(API_BASE_URL + `profiles/${username}`);
};

const fetchProfile = async username => {
  const response = await connection.get(API_BASE_URL + `profiles/${username}`);
  return response.data;
};

const getProfileBadges = async username => {
  const response = await connection.get(
    API_BASE_URL + `profiles/${username}/badges`
  );
  return response.data;
};

export default { getProfile, getProfileBadges, fetchProfile };
