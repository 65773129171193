import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const markTaskComplete = async id => {
  const token = AuthService.getAuthToken();
  return await connection.post(
    API_BASE_URL + `tasks/${id}/complete`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const undoTaskComplete = async id => {
  const token = AuthService.getAuthToken();
  return await connection.delete(API_BASE_URL + `tasks/${id}/complete`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
};

const getProjectLeaderboard = async id => {
  return await connection.get(API_BASE_URL + `challenges/${id}/submissions`);
};

export default {
  markTaskComplete,
  undoTaskComplete,
  getProjectLeaderboard,
};
