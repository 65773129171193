import axios from 'axios';

export const API_BASE_URL =
  'https://techstarta-api-yajc6.ondigitalocean.app/api/';
export const MEDIA_BASE_URL = 'https://storage.googleapis.com/talentmine/';
export const AUTH_TOKEN_KEY = 'auth_token';

const connection = axios.create({
  baseURL: API_BASE_URL,
  responseType: 'json',
});

connection.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      const pathname = window.location.pathname;
      localStorage.removeItem(AUTH_TOKEN_KEY);
      window.location.href = '/login?redirectTo=' + pathname;
    }
    return Promise.reject(error);
  }
);

export { connection };
