import React from 'react';
import {
  Box,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  LinkBox,
  LinkOverlay,
  Flex,
  Link,
} from '@chakra-ui/react';
import {
  CodeBracketIcon,
  Squares2X2Icon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

const SectionHeader = ({ icon, title, marginBottom = '24px' }) => (
  <HStack mb={marginBottom}>
    {icon}
    <Text fontSize="xl" fontWeight="bold" textColor={'gray.700'}>
      {title}
    </Text>
  </HStack>
);

const RoadmapSection = ({ tracks, category, icon, title }) => (
  <div>
    <SectionHeader icon={icon} title={title} />
    <SimpleGrid
      columns={{ sm: 1, md: 1, lg: 3 }}
      spacing={'24px'}
      marginBottom={'30px'}
    >
      {tracks
        .filter(track => track.category === category)
        .map(item => (
          <RoadmapCardItem item={item} key={item.title} />
        ))}
    </SimpleGrid>
  </div>
);

const RoadmapCardItem = ({ item }) => {
  const { title, description, slug, image } = item;

  return (
    <LinkBox
      mb="24px"
      flexDirection={'column'}
      className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300 flex flex-col p-4 rounded-lg"
      w="100%"
      justifyContent="space-between"
      cursor="pointer"
    >
      <div>
        <div style={{ marginBottom: '24px' }}>
          <img
            src={image}
            width="64px"
            height={'64px'}
            alt="android-development"
          />
        </div>
        <Flex flexDirection={'column'}>
          <LinkOverlay href={`/skills/${slug}`}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              mb="12px"
              lineHeight="32px"
              letterSpacing="0.01em"
              textColor={'gray.700'}
            >
              {' '}
              {title}{' '}
            </Text>
          </LinkOverlay>
          <Text
            fontSize="md"
            fontWeight="normal"
            color="gray.600"
            noOfLines={3}
          >
            {description}
          </Text>
        </Flex>
      </div>

      <Flex alignItems={'flex-end'} mt="24px">
        <Link href={`/skills/${slug}`}>
          <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            Start learning
            <ChevronRightIcon
              aria-hidden="true"
              className="hidden text-slate-200 w-4 h-4 sm:inline justify-center align-middle ml-2"
            />
          </button>
        </Link>
      </Flex>
    </LinkBox>
  );
};

const RoadmapGridSections = ({ tracks }) => {
  return (
    <div className="mt-10">
      {tracks.length > 0 ? (
        <>
          <RoadmapSection
            tracks={tracks}
            category="coding"
            icon={<CodeBracketIcon className="w-6 h-6 text-orange-500" />}
            title="Coding Roadmaps"
          />
          <RoadmapSection
            tracks={tracks}
            category="non-coding"
            icon={<Squares2X2Icon className="w-6 h-6 text-orange-500" />}
            title="Non-coding Roadmaps"
          />
        </>
      ) : (
        <SimpleGrid pt={6} columns={{ sm: 1, md: 1, lg: 3 }} spacing={8}>
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded-lg p-3"
            >
              <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:rounded-lg rounded">
                <div className="w-full justify-center">
                  <div className="cursor-pointer py-12 px-6 max-w-xl lg:w-2/3">
                    <h3 className="text-2xl text-gray-800">
                      <Skeleton height="30px" my="10px" />
                    </h3>
                    <div className="mt-4">
                      <Skeleton height="60px" my="10px" />
                    </div>
                  </div>
                </div>
                <div className="flex md:w-5/12 w-full justify-end items-center">
                  <Box h="45px" w="100px">
                    <Skeleton rounded="9999px" h="50px" w="100px" />
                  </Box>
                </div>
              </div>
            </div>
          ))}
        </SimpleGrid>
      )}
    </div>
  );
};
export default RoadmapGridSections;
export { SectionHeader };
