import React from 'react';
import { Avatar, Flex, Link, Stack, Text } from '@chakra-ui/react';

export const ContentAuthorCard = ({
  titlePrefix = 'Curated by',
  author,
  profileLink,
}) => {
  return (
    <>
      <Flex pb={4} rounded="md" width="100%" direction="column">
        <Stack direction={'row'} spacing={4} align={'center'}>
          <Avatar name={author.fullname} src={author.image} alt={'Author'} />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            {profileLink ? (
              <Link href={profileLink} fontWeight={600}>
                {author.fullname}
              </Link>
            ) : (
              <Text fontWeight={600}>{author.fullname}</Text>
            )}
            <p className="leading-tight text-ellipsis overflow-hidden truncate ...">
              {author.short_bio ?? author.bio}
            </p>
          </Stack>
        </Stack>
      </Flex>
    </>
  );
};
