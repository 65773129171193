import React from 'react';
import { Flex, Text, HStack, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FireIcon } from '@heroicons/react/24/solid';

export function SkillWithProgress({ track }) {
  return (
    <LinkBox
      className="shadow-sm shadow-black/5 ring-1 ring-slate-700/10 hover:ring-gray-300 flex flex-col gap-4 p-4 rounded-lg"
      key={track.track.slug}
      size="lg"
      direction={'column'}
      cursor={'pointer'}
    >
      <LinkOverlay href={`/skills/${track.track.slug}`}>
        <Text lineHeight={'4'} fontWeight="semibold" textColor={'gray.600'}>
          {track.track.title}
        </Text>
      </LinkOverlay>

      <Flex justifyContent="space-between">
        <HStack>
          <FireIcon className="h-5 w-5 text-orange-600" />
          <Text lineHeight={'4'} textColor={'gray.600'}>
            {track.total_earned_points} XP
          </Text>
        </HStack>
      </Flex>
    </LinkBox>
  );
}
