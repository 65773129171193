import {
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import authService from '../../services/auth-service';

export const QuickMessageModal = ({
  modalTitle,
  isQuickSendModalOpen,
  closeQuickSendModal,
  onSendMessage,
  messageTo,
}) => {
  const [content, setContent] = useState('');

  const handleSendMessage = event => {
    event.preventDefault();
    onSendMessage.mutate({
      content: content,
      recipient_id: messageTo.username,
    });
  };

  return (
    <Modal
      size={'xl'}
      isOpen={isQuickSendModalOpen}
      onClose={closeQuickSendModal}
      isCentered
    >
      <ModalOverlay />{' '}
      <ModalContent>
        <ModalHeader className="text-gray-700">{modalTitle}</ModalHeader>
        <ModalCloseButton _focus={{ outline: 'none' }} />
        <ModalBody>
          <div className="flex flex-wrap items-center mb-4">
            <div className="inline-block mr-2">
              <Avatar
                size={'lg'}
                src={authService.buildImageUrl(messageTo.image)}
                name={messageTo.firstname + ' ' + messageTo.lastname}
              />
            </div>
            <div className="flex flex-col">
              <div className="inline-flex items-center">
                <h4 className="text-gray-800 text-md font-bold leading-tight tracking-tight text-left mr-2">
                  {`${messageTo.firstname} ${messageTo.lastname}`}
                </h4>
                <span className="bg-gray-100 uppercase text-gray-600 text-xs font-semibold me-2 px-1 py-0.5 rounded ">
                  Mentor
                </span>
              </div>
              <span className="mt-1 font-medium text-sm text-gray-600">
                {messageTo.current_role + ' at ' + messageTo.company}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <textarea
              rows="3"
              name="content"
              value={content}
              onChange={e => setContent(e.target.value)}
              className="w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-300 p-2 focus:ring-2 focus:ring-orange-500"
              maxLength={1300}
              placeholder="Write a message..."
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={closeQuickSendModal}
            className="py-2 px-3.5 mr-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={!content}
            onClick={handleSendMessage}
            className="items-center justify-center px-3.5 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
          >
            Send Message
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
